/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {TechprocessCalculationTaskTypeEnum} from './TechprocessCalculationTaskTypeEnum';
import {
  TechprocessCalculationTaskTypeEnumFromJSON,
  TechprocessCalculationTaskTypeEnumFromJSONTyped,
  TechprocessCalculationTaskTypeEnumToJSON
} from './TechprocessCalculationTaskTypeEnum';

/**
 *
 * @export
 * @interface TechprocessCalculationTaskUpdate
 */
export interface TechprocessCalculationTaskUpdate {
  /**
   *
   * @type {TechprocessCalculationTaskTypeEnum}
   * @memberof TechprocessCalculationTaskUpdate
   */
  type?: TechprocessCalculationTaskTypeEnum | null;
  /**
   *
   * @type {string}
   * @memberof TechprocessCalculationTaskUpdate
   */
  description?: string | null;
}

/**
 * Check if a given object implements the TechprocessCalculationTaskUpdate interface.
 */
export function instanceOfTechprocessCalculationTaskUpdate(
  value: object
): value is TechprocessCalculationTaskUpdate {
  return true;
}

export function TechprocessCalculationTaskUpdateFromJSON(
  json: any
): TechprocessCalculationTaskUpdate {
  return TechprocessCalculationTaskUpdateFromJSONTyped(json, false);
}

export function TechprocessCalculationTaskUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechprocessCalculationTaskUpdate {
  if (json == null) {
    return json;
  }
  return {
    type:
      json['type'] == null ? undefined : TechprocessCalculationTaskTypeEnumFromJSON(json['type']),
    description: json['description'] == null ? undefined : json['description']
  };
}

export function TechprocessCalculationTaskUpdateToJSON(
  value?: TechprocessCalculationTaskUpdate | null
): any {
  if (value == null) {
    return value;
  }
  return {
    type: TechprocessCalculationTaskTypeEnumToJSON(value['type']),
    description: value['description']
  };
}
