/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ConnectInfo} from './ConnectInfo';
import {ConnectInfoFromJSON, ConnectInfoFromJSONTyped, ConnectInfoToJSON} from './ConnectInfo';

/**
 *
 * @export
 * @interface BodyTechProcessAddConnection
 */
export interface BodyTechProcessAddConnection {
  /**
   *
   * @type {ConnectInfo}
   * @memberof BodyTechProcessAddConnection
   */
  targetInfo: ConnectInfo;
  /**
   *
   * @type {ConnectInfo}
   * @memberof BodyTechProcessAddConnection
   */
  sourceInfo: ConnectInfo;
}

/**
 * Check if a given object implements the BodyTechProcessAddConnection interface.
 */
export function instanceOfBodyTechProcessAddConnection(
  value: object
): value is BodyTechProcessAddConnection {
  if (!('targetInfo' in value) || value['targetInfo'] === undefined) return false;
  if (!('sourceInfo' in value) || value['sourceInfo'] === undefined) return false;
  return true;
}

export function BodyTechProcessAddConnectionFromJSON(json: any): BodyTechProcessAddConnection {
  return BodyTechProcessAddConnectionFromJSONTyped(json, false);
}

export function BodyTechProcessAddConnectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BodyTechProcessAddConnection {
  if (json == null) {
    return json;
  }
  return {
    targetInfo: ConnectInfoFromJSON(json['target_info']),
    sourceInfo: ConnectInfoFromJSON(json['source_info'])
  };
}

export function BodyTechProcessAddConnectionToJSON(
  value?: BodyTechProcessAddConnection | null
): any {
  if (value == null) {
    return value;
  }
  return {
    target_info: ConnectInfoToJSON(value['targetInfo']),
    source_info: ConnectInfoToJSON(value['sourceInfo'])
  };
}
