import {Instance, SnapshotIn, types} from 'mobx-state-tree';
import {RJSFSchema, UiSchema} from '@rjsf/utils';

import {ResetModel} from 'core/models/base/ResetModel/ResetModel';

const JsonSchema = types
  .compose(
    ResetModel,
    types.model('JsonSchema', {
      id: types.string,
      name: types.string,
      uiSchema: types.optional(types.frozen<UiSchema>(), {}),
      schema: types.optional(types.frozen<RJSFSchema>(), {}),
      formData: types.optional(types.frozen<unknown>(), {})
    })
  )
  .actions((self) => ({
    updateFormData(formData: unknown): void {
      self.formData = formData;
    }
  }));

export type TJsonSchemaModel = Instance<typeof JsonSchema>;
export type TJsonSchemaSnapshotIn = SnapshotIn<typeof JsonSchema>;

export {JsonSchema};
