/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface FieldValue
 */
export interface FieldValue {}

/**
 * Check if a given object implements the FieldValue interface.
 */
export function instanceOfFieldValue(value: object): value is FieldValue {
  return true;
}

export function FieldValueFromJSON(json: any): FieldValue {
  return FieldValueFromJSONTyped(json, false);
}

export function FieldValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldValue {
  return json;
}

export function FieldValueToJSON(value?: FieldValue | null): any {
  return value;
}
