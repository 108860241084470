import {flow, Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EntityDetails} from 'core/models';
import {OilApi, RJSFSchemas} from 'api';

const BlendDetails = types
  .compose(EntityDetails, types.model('BlendDetails', {}))
  .actions((self) => ({
    loadJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string) {
      if (self.jsonSchemasRequest.isPending) {
        return;
      }

      const response: {[key: string]: RJSFSchemas} = yield self.jsonSchemasRequest.send(
        OilApi.oilGetBlend.bind(OilApi),
        {
          blendUuid: self.uuid,
          projectUuid: projectUuid,
          checkpointUuid: checkpointUuid
        }
      );

      self.setJsonSchemas(response);
    })
  }));

export type TBlendDetailsModel = Instance<typeof BlendDetails>;
export type TBlendDetailsSnapshotIn = SnapshotIn<typeof BlendDetails>;

export {BlendDetails};
