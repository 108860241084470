/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {Data1} from './Data1';
import {Data1FromJSON, Data1FromJSONTyped, Data1ToJSON} from './Data1';

/**
 *
 * @export
 * @interface TechProcessActionResult
 */
export interface TechProcessActionResult {
  /**
   *
   * @type {boolean}
   * @memberof TechProcessActionResult
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TechProcessActionResult
   */
  errorMsg?: string | null;
  /**
   *
   * @type {Data1}
   * @memberof TechProcessActionResult
   */
  data: Data1;
}

/**
 * Check if a given object implements the TechProcessActionResult interface.
 */
export function instanceOfTechProcessActionResult(value: object): value is TechProcessActionResult {
  if (!('success' in value) || value['success'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  return true;
}

export function TechProcessActionResultFromJSON(json: any): TechProcessActionResult {
  return TechProcessActionResultFromJSONTyped(json, false);
}

export function TechProcessActionResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechProcessActionResult {
  if (json == null) {
    return json;
  }
  return {
    success: json['success'],
    errorMsg: json['error_msg'] == null ? undefined : json['error_msg'],
    data: Data1FromJSON(json['data'])
  };
}

export function TechProcessActionResultToJSON(value?: TechProcessActionResult | null): any {
  if (value == null) {
    return value;
  }
  return {
    success: value['success'],
    error_msg: value['errorMsg'],
    data: Data1ToJSON(value['data'])
  };
}
