import {v4 as uuidv4} from 'uuid';

import {TJsonSchemaSnapshotIn} from 'core/models';

export const SCHEMAS: TJsonSchemaSnapshotIn[] = [
  {
    id: uuidv4(),
    name: 'Базовые',
    schema: {
      type: 'object',
      properties: {
        basic_group_divider: {
          type: 'null',
          title: 'Базовые свойства'
        },
        name: {
          type: 'string',
          title: 'Имя'
        },
        element_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'MaterialFlowElement',
              title: 'Материальный поток'
            }
          ],
          title: 'Тип элемента',
          readOnly: true
        }
      }
    },
    uiSchema: {
      basic_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      name: {
        'ui:field': '/schemas/jsf-input'
      },
      element_type: {
        'ui:field': '/schemas/jsf-select'
      }
    },
    formData: {
      basic_group_divider: null,
      name: 'Пенг-Робинсон-1',
      element_type: 'MaterialFlowElement'
    }
  }
];
