import {EElement} from '@progress-fe/core';

import {TElementDetailsSnapshotIn} from 'core/models';

export const ELEMENTS_SAMPLE_ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000000',
    projectName: 'Элементы (моковый проект)',
    items: [
      {
        uuid: '196C89E0-1111-0000-0000-000000000006',
        type: EElement.HeatExchangerElement,
        name: 'HEAT-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000007',
        type: EElement.MixerElement,
        name: 'MIX-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000008',
        type: EElement.SplitterElement,
        name: 'SPR-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000009',
        type: EElement.DetanderElement,
        name: 'DET-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000010',
        type: EElement.PumpElement,
        name: 'PUMP-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000011',
        type: EElement.HeaterElement,
        name: 'H-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      }
    ]
  }
];
