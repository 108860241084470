import {cast, flow, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {TProjectsMenu} from 'core/types';
import {EProjectSortType, EProjectSortField} from 'core/enums';
import {PROJECT_LIST} from 'core/mocks/projects/projects.mocks';
import {Paginator, RequestModel, ResetModel} from 'core/models';
import {ProjectsGetProjectsRequest} from 'api/generated';
import {
  ProjectsApi,
  ProjectTypeEnum,
  ProjectOut,
  PageProjectOut,
  ResponseStatusProjectOut
} from 'api';

import {ProjectsFilter, ProjectsSorts} from './models';

const TypeFilters: Record<TProjectsMenu, ProjectsGetProjectsRequest> = {
  TechProcess: {type: ProjectTypeEnum.TechProcess},
  Mix: {type: ProjectTypeEnum.SamplesAndOilBlend},
  Bin: {deleted: true},
  Favorites: {favorites: true},
  Shared: {},
  All: {}
};

const MyProjectsStore = types
  .compose(
    ResetModel,
    types.model('MyProjectsStore', {
      projects: types.optional(types.array(types.frozen<ProjectOut>()), []),
      paginator: types.optional(Paginator, {size: 100, page: 1, total: 0}),
      filters: types.optional(ProjectsFilter, {}),
      sorts: types.optional(ProjectsSorts, {}),
      projectsRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    loadProjects: flow(function* () {
      //TODO: until favorites api will not be ready will set mock projects there
      if (self.filters.activeType === 'Favorites') {
        self.projects = cast(PROJECT_LIST);
        self.paginator.total = PROJECT_LIST.length;
        return;
      }

      const projects: PageProjectOut = yield self.projectsRequest.send(
        ProjectsApi.projectsGetProjects.bind(ProjectsApi),
        {
          name: self.filters.query,
          page: self.paginator.page,
          size: self.paginator.size,
          orderBy: self.sorts.orderBy,
          orderByField: self.sorts.orderByField,
          ...TypeFilters[self.filters.activeType as TProjectsMenu]
        }
      );

      self.projects = cast(projects.items);
      self.paginator.total = projects.total || projects.items.length;
    }),
    createProject: flow(function* (type: ProjectTypeEnum) {
      const uuid = uuidv4();
      const response: ResponseStatusProjectOut = yield self.actionRequest.send(
        ProjectsApi.projectsCreateProject.bind(ProjectsApi),
        {
          idempotencyKey: uuid,
          projectInCreate: {type}
        }
      );

      if (response.data) {
        self.projects.push(response.data);
      }

      return response.data;
    })
  }))
  .actions((self) => ({
    setActiveType: (type: TProjectsMenu) => {
      self.filters.activeType = type;
      self.paginator.resetModel();
      self.loadProjects();
    },
    setActiveSort: (orderBy: EProjectSortType, orderByField: EProjectSortField) => {
      self.sorts.orderBy = orderBy;
      self.sorts.orderByField = orderByField;
      self.paginator.resetModel();
      self.loadProjects();
    },
    setPage: (page: number) => {
      self.paginator.page = page;
      self.loadProjects();
    },
    setQuery: (query: string) => {
      self.filters.query = query;
      self.paginator.resetModel();
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.projectsRequest.isPending;
    },
    get searchText(): string {
      return self.filters.query;
    },
    get activeMenu(): TProjectsMenu {
      return self.filters.activeType as TProjectsMenu;
    }
  }));

export {MyProjectsStore};
