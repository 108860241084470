import {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FlexDivider} from '@progress-fe/ui-kit';
import {EStructureItem, useT} from '@progress-fe/core';

import {useStore} from 'core';

import {BlendsList, ResultsList, SamplesList, Settings} from './components';

const ProjectStructureFC: FC = () => {
  const {samplesAndBlendsStore} = useStore();
  const {uiState, projectSamples, projectBlends, selectEntityByType} = samplesAndBlendsStore;
  const {entityId, entityType} = uiState;

  const {t} = useT();

  return (
    <Grid gridTemplateRows={'32px 8px 2fr 8px 2fr 8px 2fr 8px 2fr'} h="var(--height-layout)">
      {/* PROJECT SETTINGS */}
      <Settings
        isSelected={entityType === EStructureItem.Settings}
        onSelect={() => selectEntityByType(EStructureItem.Settings)}
      />

      <FlexDivider withDots />

      {/* PROJECT SAMPLES */}
      <SamplesList
        isLoading={projectSamples.isLoading}
        isCreating={projectSamples.isCreating}
        samples={projectSamples.samples}
        selectedId={entityType === EStructureItem.Sample ? entityId : null}
        onSelect={(uuid) => selectEntityByType(EStructureItem.Sample, uuid)}
        onCreate={samplesAndBlendsStore.createSample}
        onDelete={samplesAndBlendsStore.deleteSample}
      />

      <FlexDivider withDots />

      {/* PROJECT SAMPLES RESULTS */}
      <ResultsList
        isLoading={false}
        title={t('samples.results')}
        results={[]}
        selectedId={null}
        noResultsMsg={t('common.noResults')}
      />

      <FlexDivider withDots />

      {/* PROJECT BLENDS */}
      <BlendsList
        isLoading={projectBlends.isLoading}
        isCreating={projectBlends.isCreating}
        blends={projectBlends.blends}
        selectedId={entityType === EStructureItem.Blend ? entityId : null}
        onSelect={(uuid) => selectEntityByType(EStructureItem.Blend, uuid)}
        onCreate={samplesAndBlendsStore.createBlend}
        onDelete={samplesAndBlendsStore.deleteBlend}
      />

      <FlexDivider withDots />

      {/* PROJECT BLENDS RESULTS */}
      <ResultsList
        isLoading={false}
        title={t('blends.results')}
        results={[]}
        selectedId={null}
        noResultsMsg={t('common.noResults')}
      />
    </Grid>
  );
};

export const ProjectStructure = observer(ProjectStructureFC);
