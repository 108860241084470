/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  PageProjectOut,
  ProfilerMode,
  ProjectInCreate,
  ProjectInUpdate,
  ProjectOut,
  ProjectOutEnum,
  ProjectTypeEnum,
  ResponseStatusProjectOut
} from '../models/index';
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  PageProjectOutFromJSON,
  PageProjectOutToJSON,
  ProfilerModeFromJSON,
  ProfilerModeToJSON,
  ProjectInCreateFromJSON,
  ProjectInCreateToJSON,
  ProjectInUpdateFromJSON,
  ProjectInUpdateToJSON,
  ProjectOutFromJSON,
  ProjectOutToJSON,
  ProjectOutEnumFromJSON,
  ProjectOutEnumToJSON,
  ProjectTypeEnumFromJSON,
  ProjectTypeEnumToJSON,
  ResponseStatusProjectOutFromJSON,
  ResponseStatusProjectOutToJSON
} from '../models/index';

export interface ProjectsCreateProjectRequest {
  idempotencyKey: string;
  projectInCreate: ProjectInCreate;
  xProfilerMode?: ProfilerMode;
}

export interface ProjectsGetProjectRequest {
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface ProjectsGetProjectsRequest {
  type?: ProjectTypeEnum | null;
  favorites?: boolean | null;
  deleted?: boolean | null;
  name?: string | null;
  orderByField?: ProjectOutEnum | null;
  orderBy?: ProjectsGetProjectsOrderByEnum;
  page?: number;
  size?: number;
  xProfilerMode?: ProfilerMode;
}

export interface ProjectsUpdateProjectRequest {
  projectUuid: string;
  idempotencyKey: string;
  projectInUpdate: ProjectInUpdate;
  xProfilerMode?: ProfilerMode;
}

/**
 *
 */
export class ProjectsApi extends runtime.BaseAPI {
  /**
   * Create Project
   */
  async projectsCreateProjectRaw(
    requestParameters: ProjectsCreateProjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResponseStatusProjectOut>> {
    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling projectsCreateProject().'
      );
    }

    if (requestParameters['projectInCreate'] == null) {
      throw new runtime.RequiredError(
        'projectInCreate',
        'Required parameter "projectInCreate" was null or undefined when calling projectsCreateProject().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProjectInCreateToJSON(requestParameters['projectInCreate'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResponseStatusProjectOutFromJSON(jsonValue)
    );
  }

  /**
   * Create Project
   */
  async projectsCreateProject(
    requestParameters: ProjectsCreateProjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResponseStatusProjectOut> {
    const response = await this.projectsCreateProjectRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Project
   */
  async projectsGetProjectRaw(
    requestParameters: ProjectsGetProjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProjectOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling projectsGetProject().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProjectOutFromJSON(jsonValue));
  }

  /**
   * Get Project
   */
  async projectsGetProject(
    requestParameters: ProjectsGetProjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProjectOut> {
    const response = await this.projectsGetProjectRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Projects
   */
  async projectsGetProjectsRaw(
    requestParameters: ProjectsGetProjectsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PageProjectOut>> {
    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type'] = requestParameters['type'];
    }

    if (requestParameters['favorites'] != null) {
      queryParameters['favorites'] = requestParameters['favorites'];
    }

    if (requestParameters['deleted'] != null) {
      queryParameters['deleted'] = requestParameters['deleted'];
    }

    if (requestParameters['name'] != null) {
      queryParameters['name'] = requestParameters['name'];
    }

    if (requestParameters['orderByField'] != null) {
      queryParameters['orderByField'] = requestParameters['orderByField'];
    }

    if (requestParameters['orderBy'] != null) {
      queryParameters['orderBy'] = requestParameters['orderBy'];
    }

    if (requestParameters['page'] != null) {
      queryParameters['page'] = requestParameters['page'];
    }

    if (requestParameters['size'] != null) {
      queryParameters['size'] = requestParameters['size'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PageProjectOutFromJSON(jsonValue));
  }

  /**
   * Get Projects
   */
  async projectsGetProjects(
    requestParameters: ProjectsGetProjectsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PageProjectOut> {
    const response = await this.projectsGetProjectsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update Project
   */
  async projectsUpdateProjectRaw(
    requestParameters: ProjectsUpdateProjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResponseStatusProjectOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling projectsUpdateProject().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling projectsUpdateProject().'
      );
    }

    if (requestParameters['projectInUpdate'] == null) {
      throw new runtime.RequiredError(
        'projectInUpdate',
        'Required parameter "projectInUpdate" was null or undefined when calling projectsUpdateProject().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: ProjectInUpdateToJSON(requestParameters['projectInUpdate'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResponseStatusProjectOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Project
   */
  async projectsUpdateProject(
    requestParameters: ProjectsUpdateProjectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResponseStatusProjectOut> {
    const response = await this.projectsUpdateProjectRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ProjectsGetProjectsOrderByEnum = {
  Asc: 'asc',
  Desc: 'desc',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type ProjectsGetProjectsOrderByEnum =
  (typeof ProjectsGetProjectsOrderByEnum)[keyof typeof ProjectsGetProjectsOrderByEnum];
