import {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, IconButton} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';

import {EComputeStatusCode} from 'core/enums';

interface IProps {
  runStatus: EComputeStatusCode | null;
  isDisabled: boolean;
  onClick?: () => void;
}

const StatusIconButtonFC: FC<IProps> = ({runStatus, isDisabled, onClick}) => {
  const variant: string = useMemo(() => {
    switch (runStatus) {
      case EComputeStatusCode.Success:
        return 'success'; // success
      case EComputeStatusCode.PartialSuccess:
        return 'warning'; // warning
      case EComputeStatusCode.Failure:
        return 'danger'; // danger
      default:
        return 'ghost';
    }
  }, [runStatus]);

  const color: string = useMemo(() => {
    switch (runStatus) {
      case EComputeStatusCode.Success:
        return 'white';
      case EComputeStatusCode.PartialSuccess:
        return 'white';
      case EComputeStatusCode.Failure:
        return 'white';
      default:
        return 'black';
    }
  }, [runStatus]);

  return (
    <Box>
      <IconButton
        size="sm"
        aria-label=""
        isDisabled={isDisabled}
        variant={variant}
        onClick={onClick}
        icon={
          <Box color={color}>
            <Svg name="Error" />
          </Box>
        }
      />
    </Box>
  );
};

export const StatusIconButton = observer(StatusIconButtonFC);
