import {Instance, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';
import {EProjectSortType, EProjectSortField} from 'core/enums';

const ProjectsSorts = types.compose(
  ResetModel,
  types.model('ProjectsSorts', {
    orderBy: types.optional(
      types.enumeration(Object.values(EProjectSortType)),
      EProjectSortType.Desc
    ),
    orderByField: types.optional(
      types.enumeration(Object.values(EProjectSortField)),
      EProjectSortField.UpdatedAt
    )
  })
);

export type TProjectsSortsModel = Instance<typeof ProjectsSorts>;

export {ProjectsSorts};
