/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {LogicalElementOut} from './LogicalElementOut';
import {
  LogicalElementOutFromJSON,
  LogicalElementOutFromJSONTyped,
  LogicalElementOutToJSON
} from './LogicalElementOut';

/**
 *
 * @export
 * @interface LogicalElementsOut
 */
export interface LogicalElementsOut {
  /**
   *
   * @type {Array<LogicalElementOut>}
   * @memberof LogicalElementsOut
   */
  logicalElements: Array<LogicalElementOut>;
}

/**
 * Check if a given object implements the LogicalElementsOut interface.
 */
export function instanceOfLogicalElementsOut(value: object): value is LogicalElementsOut {
  if (!('logicalElements' in value) || value['logicalElements'] === undefined) return false;
  return true;
}

export function LogicalElementsOutFromJSON(json: any): LogicalElementsOut {
  return LogicalElementsOutFromJSONTyped(json, false);
}

export function LogicalElementsOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogicalElementsOut {
  if (json == null) {
    return json;
  }
  return {
    logicalElements: (json['logical_elements'] as Array<any>).map(LogicalElementOutFromJSON)
  };
}

export function LogicalElementsOutToJSON(value?: LogicalElementsOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    logical_elements: (value['logicalElements'] as Array<any>).map(LogicalElementOutToJSON)
  };
}
