import {cast, Instance, types} from 'mobx-state-tree';

import {RESULTS_LIST} from 'core/mocks/projects/Separators/results.mocks';
import {ResultDetails, ResetModel} from 'core/models';

const ProjectResults = types
  .compose(
    ResetModel,
    types.model('ProjectResults', {
      results: types.optional(types.array(ResultDetails), [])
    })
  )
  .actions((self) => ({
    fetch(projectId: string): void {
      const results = RESULTS_LIST.find((m) => m.projectId === projectId)?.items || [];
      self.results = cast(results);
    },
    hasResult(resultId: string) {
      return self.results.some((r) => r.uuid === resultId);
    }
  }))
  .views(() => ({}));

export type TProjectResultsModel = Instance<typeof ProjectResults>;

export {ProjectResults};
