/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {LogicalElementType} from './LogicalElementType';
import {
  LogicalElementTypeFromJSON,
  LogicalElementTypeFromJSONTyped,
  LogicalElementTypeToJSON
} from './LogicalElementType';

/**
 *
 * @export
 * @interface LogicalElementOut
 */
export interface LogicalElementOut {
  /**
   *
   * @type {LogicalElementType}
   * @memberof LogicalElementOut
   */
  type: LogicalElementType;
  /**
   *
   * @type {string}
   * @memberof LogicalElementOut
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof LogicalElementOut
   */
  name: string;
}

/**
 * Check if a given object implements the LogicalElementOut interface.
 */
export function instanceOfLogicalElementOut(value: object): value is LogicalElementOut {
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  return true;
}

export function LogicalElementOutFromJSON(json: any): LogicalElementOut {
  return LogicalElementOutFromJSONTyped(json, false);
}

export function LogicalElementOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogicalElementOut {
  if (json == null) {
    return json;
  }
  return {
    type: LogicalElementTypeFromJSON(json['type']),
    uuid: json['uuid'],
    name: json['name']
  };
}

export function LogicalElementOutToJSON(value?: LogicalElementOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    type: LogicalElementTypeToJSON(value['type']),
    uuid: value['uuid'],
    name: value['name']
  };
}
