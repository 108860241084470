/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {SampleOut} from './SampleOut';
import {SampleOutFromJSON, SampleOutFromJSONTyped, SampleOutToJSON} from './SampleOut';

/**
 *
 * @export
 * @interface SamplesOut
 */
export interface SamplesOut {
  /**
   *
   * @type {Array<SampleOut>}
   * @memberof SamplesOut
   */
  samples: Array<SampleOut>;
}

/**
 * Check if a given object implements the SamplesOut interface.
 */
export function instanceOfSamplesOut(value: object): value is SamplesOut {
  if (!('samples' in value) || value['samples'] === undefined) return false;
  return true;
}

export function SamplesOutFromJSON(json: any): SamplesOut {
  return SamplesOutFromJSONTyped(json, false);
}

export function SamplesOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): SamplesOut {
  if (json == null) {
    return json;
  }
  return {
    samples: (json['samples'] as Array<any>).map(SampleOutFromJSON)
  };
}

export function SamplesOutToJSON(value?: SamplesOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    samples: (value['samples'] as Array<any>).map(SampleOutToJSON)
  };
}
