/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {BlendOut} from './BlendOut';
import {BlendOutFromJSON, BlendOutFromJSONTyped, BlendOutToJSON} from './BlendOut';

/**
 *
 * @export
 * @interface BlendsOut
 */
export interface BlendsOut {
  /**
   *
   * @type {Array<BlendOut>}
   * @memberof BlendsOut
   */
  blends: Array<BlendOut>;
}

/**
 * Check if a given object implements the BlendsOut interface.
 */
export function instanceOfBlendsOut(value: object): value is BlendsOut {
  if (!('blends' in value) || value['blends'] === undefined) return false;
  return true;
}

export function BlendsOutFromJSON(json: any): BlendsOut {
  return BlendsOutFromJSONTyped(json, false);
}

export function BlendsOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlendsOut {
  if (json == null) {
    return json;
  }
  return {
    blends: (json['blends'] as Array<any>).map(BlendOutFromJSON)
  };
}

export function BlendsOutToJSON(value?: BlendsOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    blends: (value['blends'] as Array<any>).map(BlendOutToJSON)
  };
}
