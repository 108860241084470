import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {BaseColumnResult} from 'core/models/BaseColumnResult';

/** @deprecated **/
const CalculationTableResult = types
  .model('CalculationTableResult', {
    columns: types.optional(types.array(BaseColumnResult), [])
  })
  .actions(() => ({}))
  .views(() => ({}));

export type TCalculationTableResultModel = Instance<typeof CalculationTableResult>;

export type TCalculationTableResultSnapshotIn = SnapshotIn<typeof CalculationTableResult>;

export {CalculationTableResult};
