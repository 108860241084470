import {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {FlexDivider} from '@progress-fe/ui-kit';
import {EStructureItem} from '@progress-fe/core';

import {useStore} from 'core/hooks';

import {ElementList, ModelsList, ResultsList, Settings, TasksList} from './components';

const ProjectStructureFC: FC = () => {
  const {techProcessStore, catalogsStore} = useStore();
  const {projectModels, projectElements, projectResults, projectTask} = techProcessStore;
  const {uiState, selectEntityByType} = techProcessStore;
  const {elementsCatalog, modelsCatalog} = catalogsStore;
  const {entityId, subEntityId, entityType} = uiState;

  return (
    <Grid gridTemplateRows={'32px 8px 2fr 8px 2fr 8px 2fr 8px 2fr'} h="var(--height-layout)">
      {/* PROJECT SETTINGS */}
      <Settings
        isSelected={entityType === EStructureItem.Settings}
        onSelect={() => selectEntityByType(EStructureItem.Settings)}
      />

      <FlexDivider />

      {/* PROJECT ELEMENTS */}
      <ElementList
        isLoading={projectElements.isLoading}
        elements={projectElements.elements}
        menuItems={elementsCatalog.svgMenuItems}
        selectedId={entityType === EStructureItem.Element ? entityId : null}
        selectedSubId={entityType === EStructureItem.Element ? subEntityId : null}
        onSelect={(uuid, subUuid) => selectEntityByType(EStructureItem.Element, uuid, subUuid)}
        onCreate={techProcessStore.createElement}
        onDelete={techProcessStore.deleteElement}
      />

      <FlexDivider withDots />

      {/* PROJECT ELEMENTS MODELS */}
      <ModelsList
        isLoading={projectModels.isLoading}
        models={projectModels.models}
        menuItems={modelsCatalog.svgMenuItems}
        selectedId={entityType === EStructureItem.Model ? entityId : null}
        onSelect={(uuid) => selectEntityByType(EStructureItem.Model, uuid)}
        onGetElementsByModel={projectModels.getElementsInUse}
        onCreate={techProcessStore.createModel}
        onDelete={techProcessStore.deleteModel}
      />

      <FlexDivider withDots />

      {/* PROJECT TASKS & LOGICAL ELEMENTS */}
      <TasksList
        isLoading={projectTask.isLogicalElementsLoading}
        taskType={projectTask.type}
        logicalElements={projectTask.logicalElements}
        menuItems={projectTask.logicalMenuItems}
        isTaskSelected={entityType === EStructureItem.Task && !entityId}
        selectedId={entityType === EStructureItem.Task ? entityId : null}
        onSelect={(uuid) => selectEntityByType(EStructureItem.Task, uuid)}
        onCreate={techProcessStore.createLogicalElement}
        onDelete={techProcessStore.deleteLogicalElement}
      />

      <FlexDivider withDots />

      {/* PROJECT ELEMENTS RESULTS */}
      <ResultsList
        results={projectResults.results}
        selectedId={entityType === EStructureItem.Result ? entityId : null}
        onSelect={(uuid) => selectEntityByType(EStructureItem.Result, uuid)}
      />
    </Grid>
  );
};

export const ProjectStructure = observer(ProjectStructureFC);
