import {Edge, Node} from '@xyflow/react';
import {v4 as uuidv4} from 'uuid';
import {ERFEdge, ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';
import {ELogicalElement} from '@progress-fe/core';

export const CAT_CRACKING_4B_SUB_WORK_ZONE: {
  projectUuid: string;
  elementUuid: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectUuid: '47CB2F0C-0000-0000-0000-000000000007',
    elementUuid: '196C89E0-0000-0000-0000-000000000000',
    nodes: [
      {
        id: 'SUB_GROUP',
        type: ERFElement.GroupDashed,
        data: {
          style: {width: 690, height: 690}
        },
        position: {x: 200, y: 80},
        connectable: false,
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-00000000SUB0',
        type: ERFElement.Mixer,
        data: {
          elementName: 'Смеситель',
          templateCode: 'default',
          isSubElement: true
        },
        position: {x: 22, y: 410},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        type: ERFElement.CatCrackingRRBlock,
        data: {
          elementName: 'Реакт.-реген. блок',
          templateCode: 'default',
          isSubElement: true
        },
        position: {x: 110, y: 220},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-00000000SUB3',
        type: ERFElement.Fractionator,
        data: {
          elementName: 'Фракционатор',
          templateCode: 'default',
          isSubElement: true
        },
        position: {x: 272, y: 118},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '00S1-M', // S1 - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'S1',
          isSubElement: false
        },
        position: {x: 105, y: 503},
        connectable: false,
        deletable: false
      },
      {
        id: '00S2-M', // S2 - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'S2',
          isSubElement: false
        },
        position: {x: 105, y: 563},
        connectable: false,
        deletable: false
      },
      {
        id: '0002-M', // 2 - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '2',
          isSubElement: false
        },
        position: {x: 935, y: 91},
        connectable: false,
        deletable: false
      },
      {
        id: '0003-M', // 3- Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '3',
          isSubElement: false
        },
        position: {x: 935, y: 150},
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-IN', // DOT 1 - IN
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: -6, y: 438},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Сырьё',
          isSubElement: true
        },
        position: {x: 102, y: 423},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Дым. газы',
          isSubElement: true
        },
        position: {x: 589, y: 11},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-1', // DOT 1 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 682, y: 26},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-2', // DOT 2 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 682, y: 85},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты из реактора
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Продукты из реактора',
          isSubElement: true
        },
        position: {x: 194, y: 132},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-СУХОЙ-ГАЗ', // FLOW - Сухой газ
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Сухой газ',
          isSubElement: true
        },
        position: {x: 589, y: 70},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ППФ', // FLOW - ППФ
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'ППФ',
          isSubElement: true
        },
        position: {x: 589, y: 128},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-3', // DOT 3 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 682, y: 143},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ББФ', // FLOW - ББФ
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'ББФ',
          isSubElement: true
        },
        position: {x: 589, y: 201},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-4', // DOT 4 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 682, y: 216},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ТЕХ-КОНФЕНСАТ', // FLOW - Технический конденсат
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Технический конденсат',
          isSubElement: true
        },
        position: {x: 589, y: 259},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-5', // DOT 5 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 682, y: 274},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-БЕНЗИН', // FLOW - Бензин
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Бензин',
          isSubElement: true
        },
        position: {x: 589, y: 318},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-6', // DOT 6 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 682, y: 333},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ЛЁГКИЙ-ГАЗОЙЛЬ', // FLOW - Лёгкий газойль
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Лёгкий газойль',
          isSubElement: true
        },
        position: {x: 586, y: 377},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-7', // DOT 7 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 682, y: 392},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ТЯЖЁЛЫЙ-ГАЗОЙЛЬ', // FLOW - Тяжёлый газойль
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Тяжёлый газойль',
          isSubElement: true
        },
        position: {x: 325, y: 520},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-00000000SUB4', // E-100
        type: ERFElement.Cooler,
        data: {
          elementName: 'E-100',
          templateCode: 'default'
        },
        position: {x: 375, y: 505},
        parentId: 'SUB_GROUP',
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-0000-0000-0000-00000000SUB5', // SPR-100
        type: ERFElement.Splitter,
        data: {
          elementName: 'SPR-100',
          templateCode: 'default'
        },
        position: {x: 519, y: 507},
        parentId: 'SUB_GROUP',
        connectable: true,
        deletable: true
      },
      {
        id: '0000-SUB-10', // 10
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '10',
          templateCode: 'default'
        },
        position: {x: 454, y: 520},
        parentId: 'SUB_GROUP',
        connectable: true,
        deletable: true
      },
      {
        id: '0000-SUB-11', // 11
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '11',
          templateCode: 'default',
          isRotate: true,
          logicalElements: [{type: ELogicalElement.Recycle, filled: true}]
        },
        position: {x: 454, y: 600},
        parentId: 'SUB_GROUP',
        connectable: true,
        deletable: true
      },
      {
        id: '0000-SUB-12', // 12
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '12',
          templateCode: 'default'
        },
        position: {x: 619, y: 480},
        parentId: 'SUB_GROUP',
        connectable: true,
        deletable: true
      },
      {
        id: '0000-SUB-E1', // E1
        type: ERFElement.FlowEnergy,
        data: {
          elementName: 'E1',
          templateCode: 'default'
        },
        position: {x: 505, y: 435},
        parentId: 'SUB_GROUP',
        connectable: true,
        deletable: true
      },
      {
        id: '0001-DOT-OUT-8', // DOT 8 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 682, y: 495},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      }
    ],
    edges: [
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '00S1-M', // S1 - Element from MAIN workzone
        target: '0001-DOT-IN', // DOT 1 - IN
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '00S2-M', // S2 - Element from MAIN workzone
        target: '0001-DOT-IN', // DOT 1 - IN
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-IN', // DOT 1 - IN
        target: '196C89E0-0000-0000-0000-00000000SUB0', // Смеситель
        targetHandle: 'target_material_flows',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB0', // Смеситель
        target: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        sourceHandle: 'source_material_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        target: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        targetHandle: 'target_material_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        target: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        sourceHandle: 'source_material_gas_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        target: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты из реактора
        sourceHandle: 'source_material_product_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-11', // 11
        target: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        targetHandle: 'target_material_recycling_flow',
        deletable: false
      },

      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        target: '0001-DOT-OUT-1', // DOT 1 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-OUT-1', // DOT 1 - OUT
        target: '0002-M', // 2 - Element from MAIN workzone
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты из реактора
        target: '196C89E0-0000-0000-0000-00000000SUB3', // Фракционатор
        targetHandle: 'target_material_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB3', // Фракционатор
        target: '0000-SUB-СУХОЙ-ГАЗ', // FLOW - Сухой газ
        sourceHandle: 'source_material_top_product',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-СУХОЙ-ГАЗ', // FLOW - Сухой газ
        target: '0001-DOT-OUT-2', // DOT 2 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-OUT-2', // DOT 2 - OUT
        target: '0003-M', // 3- Element from MAIN workzone
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB3', // Фракционатор
        target: '0000-SUB-ППФ', // FLOW - ППФ
        sourceHandle: 'source_material_intermediate_selections_flows',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ППФ', // FLOW - ППФ
        target: '0001-DOT-OUT-3', // DOT 3 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB3', // Фракционатор
        target: '0000-SUB-ББФ', // FLOW - ББФ
        sourceHandle: 'source_material_intermediate_selections_flows',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ББФ', // FLOW - ББФ
        target: '0001-DOT-OUT-4', // DOT 4 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB3', // Фракционатор
        target: '0000-SUB-ТЕХ-КОНФЕНСАТ', // FLOW - Технический конденсат
        sourceHandle: 'source_material_intermediate_selections_flows',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ТЕХ-КОНФЕНСАТ', // FLOW - Технический конденсат
        target: '0001-DOT-OUT-5', // DOT 5 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB3', // Фракционатор
        target: '0000-SUB-БЕНЗИН', // FLOW - Бензин
        sourceHandle: 'source_material_intermediate_selections_flows',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-БЕНЗИН', // FLOW - Бензин
        target: '0001-DOT-OUT-6', // DOT 6 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB3', // Фракционатор
        target: '0000-SUB-ЛЁГКИЙ-ГАЗОЙЛЬ', // FLOW - Лёгкий газойль
        sourceHandle: 'source_material_intermediate_selections_flows',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ЛЁГКИЙ-ГАЗОЙЛЬ', // FLOW - Лёгкий газойль
        target: '0001-DOT-OUT-7', // DOT 7 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB3', // Фракционатор
        target: '0000-SUB-ТЯЖЁЛЫЙ-ГАЗОЙЛЬ', // FLOW - Тяжёлый газойль
        sourceHandle: 'source_material_bottom_product',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ТЯЖЁЛЫЙ-ГАЗОЙЛЬ', // FLOW - Тяжёлый газойль
        target: '196C89E0-0000-0000-0000-00000000SUB4', // E-100
        targetHandle: 'target_material_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB4', // E-100
        target: '0000-SUB-10', // 10
        sourceHandle: 'source_material_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Energy,
        source: '196C89E0-0000-0000-0000-00000000SUB4', // E-100
        target: '0000-SUB-E1', // E1
        sourceHandle: 'source_energy_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-10', // 10
        target: '196C89E0-0000-0000-0000-00000000SUB5', // SPR-100
        targetHandle: 'target_material_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB5', // SPR-100
        target: '0000-SUB-11', // 11
        sourceHandle: 'source_material_flows',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB5', // SPR-100
        target: '0000-SUB-12', // 12
        sourceHandle: 'source_material_flows',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-12', // 12
        target: '0001-DOT-OUT-8', // DOT 8 - OUT
        deletable: false
      }
    ]
  }
];
