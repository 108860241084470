import {FC, useCallback, useEffect, useState} from 'react';
import {Grid, GridItem} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router';

import {useStore} from 'core/hooks';
import {LogViewer, ProjectLayout} from 'ui-kit';

import {ProjectStructure, ProjectForms, ProjectZones} from './components';

const SamplesAndBlendsSceneFC: FC = () => {
  const {samplesAndBlendsStore} = useStore();
  const {logger} = samplesAndBlendsStore;

  const [isExpanded, setIsExpanded] = useState(false);

  const {id, checkpointId} = useParams();

  useEffect(() => {
    if (!!id && !!checkpointId) {
      samplesAndBlendsStore.initProject(id, checkpointId).then();
    }
    return () => {
      samplesAndBlendsStore.uninitialize();
    };
  }, [id, checkpointId, samplesAndBlendsStore]);

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <ProjectLayout project={samplesAndBlendsStore} onRunProject={samplesAndBlendsStore.run}>
      <Grid
        position="relative"
        h="var(--height-layout)"
        gridTemplateColumns={isExpanded ? '1fr' : '280px 440px 1fr'}
      >
        {!isExpanded && (
          <>
            {/* PROJECT ITEMS: SETTINGS, SAMPLES, BLENDS ... */}
            <GridItem bg="bg" borderRight="1px" borderColor="border">
              <ProjectStructure />
            </GridItem>

            {/* SELECTED PROJECT ITEM */}
            <GridItem bg="bg" borderRight="1px" borderColor="border" overflowY="auto">
              <ProjectForms />
            </GridItem>
          </>
        )}

        {/* SHOW RESULTS: CHARTS, TABLES */}
        <GridItem>
          <ProjectZones isExpanded={isExpanded} toggleIsExpanded={toggleIsExpanded} />
          {logger.isOpen && <LogViewer logPoints={logger.points} onClose={logger.toggleOpen} />}
        </GridItem>
      </Grid>
    </ProjectLayout>
  );
};

export const SamplesAndBlendsScene = observer(SamplesAndBlendsSceneFC);
