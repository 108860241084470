import {UserManager as UserManagerOidc} from 'oidc-client-ts';

const UserManager = new UserManagerOidc({
  authority: window.ProgressConfig.APP_AUTHORITY,
  client_id: window.ProgressConfig.APP_CLIENT_ID,
  scope: window.ProgressConfig.APP_SCOPE,
  redirect_uri: `${window.location.origin}${window.location.pathname}`,
  post_logout_redirect_uri: window.location.origin,
  automaticSilentRenew: true
});

const hasAuthParams = (location = window.location): boolean => {
  // response_mode: query
  let searchParams = new URLSearchParams(location.search);
  if ((searchParams.get('code') || searchParams.get('error')) && searchParams.get('state')) {
    return true;
  }

  // response_mode: fragment
  searchParams = new URLSearchParams(location.hash.replace('#', '?'));
  if ((searchParams.get('code') || searchParams.get('error')) && searchParams.get('state')) {
    return true;
  }

  return false;
};

export {UserManager, hasAuthParams};
