/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {TechprocessCalculationTaskTypeEnum} from './TechprocessCalculationTaskTypeEnum';
import {
  TechprocessCalculationTaskTypeEnumFromJSON,
  TechprocessCalculationTaskTypeEnumFromJSONTyped,
  TechprocessCalculationTaskTypeEnumToJSON
} from './TechprocessCalculationTaskTypeEnum';
import type {CalculationStatusEnum} from './CalculationStatusEnum';
import {
  CalculationStatusEnumFromJSON,
  CalculationStatusEnumFromJSONTyped,
  CalculationStatusEnumToJSON
} from './CalculationStatusEnum';

/**
 *
 * @export
 * @interface TechprocessCalculationTaskOut
 */
export interface TechprocessCalculationTaskOut {
  /**
   *
   * @type {TechprocessCalculationTaskTypeEnum}
   * @memberof TechprocessCalculationTaskOut
   */
  type: TechprocessCalculationTaskTypeEnum;
  /**
   *
   * @type {string}
   * @memberof TechprocessCalculationTaskOut
   */
  description?: string | null;
  /**
   *
   * @type {CalculationStatusEnum}
   * @memberof TechprocessCalculationTaskOut
   */
  calcStatus: CalculationStatusEnum;
}

/**
 * Check if a given object implements the TechprocessCalculationTaskOut interface.
 */
export function instanceOfTechprocessCalculationTaskOut(
  value: object
): value is TechprocessCalculationTaskOut {
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('calcStatus' in value) || value['calcStatus'] === undefined) return false;
  return true;
}

export function TechprocessCalculationTaskOutFromJSON(json: any): TechprocessCalculationTaskOut {
  return TechprocessCalculationTaskOutFromJSONTyped(json, false);
}

export function TechprocessCalculationTaskOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechprocessCalculationTaskOut {
  if (json == null) {
    return json;
  }
  return {
    type: TechprocessCalculationTaskTypeEnumFromJSON(json['type']),
    description: json['description'] == null ? undefined : json['description'],
    calcStatus: CalculationStatusEnumFromJSON(json['calc_status'])
  };
}

export function TechprocessCalculationTaskOutToJSON(
  value?: TechprocessCalculationTaskOut | null
): any {
  if (value == null) {
    return value;
  }
  return {
    type: TechprocessCalculationTaskTypeEnumToJSON(value['type']),
    description: value['description'],
    calc_status: CalculationStatusEnumToJSON(value['calcStatus'])
  };
}
