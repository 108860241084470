import {v4 as uuidv4} from 'uuid';

import {TJsonSchemaSnapshotIn} from 'core/models';

export const SCHEMAS: TJsonSchemaSnapshotIn[] = [
  {
    id: uuidv4(),
    name: 'Базовые',
    schema: {
      type: 'object',
      properties: {
        basic_group_divider: {
          type: 'null',
          title: 'Базовые свойства'
        },
        name: {
          type: 'string',
          title: 'Имя'
        },
        element_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'FractionatorElement',
              title: 'Фракционатор'
            }
          ],
          title: 'Тип элемента',
          readOnly: true
        },
        model_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: '605e279b-0eae-45c0-adbd-3a45b3bf9029',
              title: 'Фракционатор'
            },
            {
              const: null
            }
          ],
          title: 'Тип модели',
          readOnly: true
        },
        model_id: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: '7d6f307e-bfd7-4d55-82df-2ca8715d9c96',
              title: 'Фракционатор-1'
            },
            {
              const: null
            }
          ],
          title: 'Модель'
        }
      }
    },
    uiSchema: {
      basic_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      name: {
        'ui:field': '/schemas/jsf-input'
      },
      element_type: {
        'ui:field': '/schemas/jsf-select'
      },
      model_type: {
        'ui:field': '/schemas/jsf-select'
      },
      model_id: {
        'ui:field': '/schemas/jsf-select-arrow'
      }
    },
    formData: {
      basic_group_divider: null,
      name: 'Фракционатор',
      element_type: 'FractionatorElement',
      model_type: '605e279b-0eae-45c0-adbd-3a45b3bf9029',
      model_id: '7d6f307e-bfd7-4d55-82df-2ca8715d9c96'
    }
  },
  {
    id: uuidv4(),
    name: 'Характеристики',
    schema: {
      type: 'object',
      properties: {
        characteristics_group_divider: {
          type: 'null',
          title: 'Характеристики'
        },
        comp_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'fraction_end_boiling_point_option',
              title: 'по температуре конца кипения фракции'
            },
            {
              const: 'fraction_output_option',
              title: 'по выходу фракции'
            },
            {
              const: 'fraction_true_boiling_point_option',
              title: 'по истинной температуре кипения фракции'
            }
          ],
          title: 'Тип расчёта'
        },
        fraction_basis: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'mass_option',
              title: 'массовый'
            },
            {
              const: 'molar_option',
              title: 'мольный'
            },
            {
              const: 'volumetric_option',
              title: 'объёмный'
            }
          ],
          title: 'Базис фракции'
        },
        flow_values_table: {
          type: 'object',
          title: 'Таблица значений по потокам'
        }
      }
    },
    uiSchema: {
      characteristics_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      comp_type: {
        'ui:field': '/schemas/jsf-select'
      },
      fraction_basis: {
        'ui:field': '/schemas/jsf-select'
      },
      flow_values_table: {
        'ui:field': '/schemas/jsf-table'
      }
    },
    formData: {
      characteristics_group_divider: null,
      comp_type: 'fraction_end_boiling_point_option',
      fraction_basis: 'mass_option',
      flow_values_table: {
        fieldConfig: {
          columns: [
            {
              name: 'Давление потока'
            },
            {
              name: 'Продуктовые потоки'
            },
            {
              name: 'Выход фракции'
            },
            {
              name: 'Истинная температура кипения фракции'
            },
            {
              name: 'Температура границы деления смеси'
            },
            {
              name: 'Эффективность разделения для верха аппарата'
            },
            {
              name: 'Эффективность разделения для низа аппарата'
            }
          ]
        },
        fieldValues: []
      }
    }
  }
];
