/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {LogicalElementType} from './LogicalElementType';
import {
  LogicalElementTypeFromJSON,
  LogicalElementTypeFromJSONTyped,
  LogicalElementTypeToJSON
} from './LogicalElementType';

/**
 *
 * @export
 * @interface LogicalElementIn
 */
export interface LogicalElementIn {
  /**
   *
   * @type {LogicalElementType}
   * @memberof LogicalElementIn
   */
  type: LogicalElementType;
}

/**
 * Check if a given object implements the LogicalElementIn interface.
 */
export function instanceOfLogicalElementIn(value: object): value is LogicalElementIn {
  if (!('type' in value) || value['type'] === undefined) return false;
  return true;
}

export function LogicalElementInFromJSON(json: any): LogicalElementIn {
  return LogicalElementInFromJSONTyped(json, false);
}

export function LogicalElementInFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogicalElementIn {
  if (json == null) {
    return json;
  }
  return {
    type: LogicalElementTypeFromJSON(json['type'])
  };
}

export function LogicalElementInToJSON(value?: LogicalElementIn | null): any {
  if (value == null) {
    return value;
  }
  return {
    type: LogicalElementTypeToJSON(value['type'])
  };
}
