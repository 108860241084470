import {cast, flow, Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EntityDetails} from 'core/models';
import {RJSFSchemas, TechProcessApi} from 'api';

import {ELEMENTS_LIST, PROJECT_LIST} from '../../mocks/projects/projects.mocks';

const ElementDetails = types
  .compose(
    EntityDetails,
    types.model('ElementDetails', {
      subElements: types.optional(types.array(EntityDetails), [])
    })
  )
  .actions((self) => ({
    loadJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string) {
      if (!self.jsonSchemasRequest.isPending) {
        // TODO: Temp. Removal
        if (!!PROJECT_LIST.find((p) => p.uuid === projectUuid)) {
          const mockElement = ELEMENTS_LIST.find((p) => p.projectId === projectUuid)?.items.find(
            (el) => el.uuid === self.uuid
          );
          if (!!mockElement) {
            self.jsonSchemas = cast(mockElement.jsonSchemas);
          }
          return;
        }

        const response: {[key: string]: RJSFSchemas} = yield self.jsonSchemasRequest.send(
          TechProcessApi.techProcessGetElementSchemas.bind(TechProcessApi),
          {
            elementUuid: self.uuid,
            projectUuid: projectUuid,
            checkpointUuid: checkpointUuid
          }
        );

        if (response) {
          self.setJsonSchemas(response);
        }
      }
    }),
    // TODO: BE Implementation
    loadSubJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string, uuid: string) {
      if (!self.jsonSchemasRequest.isPending) {
        const subElement = self.subElements.find((s) => s.uuid === uuid);
        if (!subElement) return;

        // TODO: Temp. Removal
        if (!!PROJECT_LIST.find((p) => p.uuid === projectUuid)) {
          const mockSubElement = ELEMENTS_LIST.find((p) => p.projectId === projectUuid)
            ?.items.find((el) => el.uuid === self.uuid)
            ?.subElements?.find((subEl) => subEl.uuid === uuid);

          if (!!subElement && !!mockSubElement) {
            subElement.jsonSchemas = cast(mockSubElement.jsonSchemas);
          }
          return;
        }

        const response: {[key: string]: RJSFSchemas} = yield subElement.jsonSchemasRequest.send(
          TechProcessApi.techProcessGetElementSchemas.bind(TechProcessApi),
          {
            elementUuid: uuid,
            projectUuid: projectUuid,
            checkpointUuid: checkpointUuid
          }
        );

        if (response) {
          subElement.setJsonSchemas(response);
        }
      }
    })
  }));

export type TElementDetailsModel = Instance<typeof ElementDetails>;
export type TElementDetailsSnapshotIn = SnapshotIn<typeof ElementDetails>;

export {ElementDetails};
