/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {HandlePosition} from './HandlePosition';
import {
  HandlePositionFromJSON,
  HandlePositionFromJSONTyped,
  HandlePositionToJSON
} from './HandlePosition';
import type {HandleType} from './HandleType';
import {HandleTypeFromJSON, HandleTypeFromJSONTyped, HandleTypeToJSON} from './HandleType';

/**
 *
 * @export
 * @interface RecycleHandle
 */
export interface RecycleHandle {
  /**
   *
   * @type {HandlePosition}
   * @memberof RecycleHandle
   */
  position: HandlePosition;
  /**
   *
   * @type {HandleType}
   * @memberof RecycleHandle
   */
  type: HandleType;
}

/**
 * Check if a given object implements the RecycleHandle interface.
 */
export function instanceOfRecycleHandle(value: object): value is RecycleHandle {
  if (!('position' in value) || value['position'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  return true;
}

export function RecycleHandleFromJSON(json: any): RecycleHandle {
  return RecycleHandleFromJSONTyped(json, false);
}

export function RecycleHandleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecycleHandle {
  if (json == null) {
    return json;
  }
  return {
    position: HandlePositionFromJSON(json['position']),
    type: HandleTypeFromJSON(json['type'])
  };
}

export function RecycleHandleToJSON(value?: RecycleHandle | null): any {
  if (value == null) {
    return value;
  }
  return {
    position: HandlePositionToJSON(value['position']),
    type: HandleTypeToJSON(value['type'])
  };
}
