/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Style
 */
export interface Style {
  /**
   *
   * @type {number}
   * @memberof Style
   */
  width: number;
  /**
   *
   * @type {number}
   * @memberof Style
   */
  height: number;
}

/**
 * Check if a given object implements the Style interface.
 */
export function instanceOfStyle(value: object): value is Style {
  if (!('width' in value) || value['width'] === undefined) return false;
  if (!('height' in value) || value['height'] === undefined) return false;
  return true;
}

export function StyleFromJSON(json: any): Style {
  return StyleFromJSONTyped(json, false);
}

export function StyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Style {
  if (json == null) {
    return json;
  }
  return {
    width: json['width'],
    height: json['height']
  };
}

export function StyleToJSON(value?: Style | null): any {
  if (value == null) {
    return value;
  }
  return {
    width: value['width'],
    height: value['height']
  };
}
