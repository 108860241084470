import {EElement} from '@progress-fe/core';

import {TElementDetailsSnapshotIn} from 'core/models';

export const SEPARATOR_ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами (моковый проект)',
    items: [
      {
        uuid: '196C89E0-0000-0000-0000-000000000001',
        type: EElement.TwoPhaseSeparatorElement,
        name: 'V-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-0000-0000-0000-000000000002',
        type: EElement.CompressorElement,
        name: 'K-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-0000-0000-0000-000000000003',
        type: EElement.CoolerElement,
        name: 'E-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-0000-0000-0000-000000000004',
        type: EElement.ValveElement,
        name: 'VLV-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-0000-0000-0000-000000000005',
        type: EElement.ThreePhaseSeparatorElement,
        name: 'V-101',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0100-Q',
        type: EElement.EnergyFlowElement,
        name: 'Q-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0101-Q',
        type: EElement.EnergyFlowElement,
        name: 'Q-101',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0001-M',
        type: EElement.MaterialFlowElement,
        name: '1',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0002-M',
        type: EElement.MaterialFlowElement,
        name: '2',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0003-M',
        type: EElement.MaterialFlowElement,
        name: '3',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0004-M',
        type: EElement.MaterialFlowElement,
        name: '4',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0005-M',
        type: EElement.MaterialFlowElement,
        name: '5',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0006-M',
        type: EElement.MaterialFlowElement,
        name: '6',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0007-M',
        type: EElement.MaterialFlowElement,
        name: '7',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0008-M',
        type: EElement.MaterialFlowElement,
        name: '8',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0009-M',
        type: EElement.MaterialFlowElement,
        name: '9',
        lastUpdated: new Date(),
        jsonSchemas: []
      }
    ]
  }
];
