import {Component, ErrorInfo, PropsWithChildren} from 'react';
import {Center, Text} from '@chakra-ui/react';
import {i18n} from '@progress-fe/core';

interface IProps extends PropsWithChildren {
  msg?: string;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends Component<IProps, IState> {
  public state: IState = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): IState {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Center h="100vh">
          <Text>{this.props.msg || i18n.t('common.appError')}</Text>
        </Center>
      );
    }

    return this.props.children;
  }
}

export {ErrorBoundary};
