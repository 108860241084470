import {Edge, Node} from '@xyflow/react';
import {ERFEdge, ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';
import {ELogicalElement} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

export const CAT_CRACKING_1_SUB_WORK_ZONE: {
  projectUuid: string;
  elementUuid: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectUuid: '47CB2F0C-0000-0000-0000-000000000002',
    elementUuid: '196C89E0-0000-0000-0000-000000000001',
    nodes: [
      {
        id: 'SUB_GROUP',
        type: ERFElement.GroupDashed,
        data: {
          style: {width: 390, height: 440}
        },
        position: {x: 200, y: 80},
        connectable: false,
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        type: ERFElement.CatCrackingRRBlock,
        data: {
          elementName: 'Реакт.-реген. блок',
          templateCode: 'default',
          isSubElement: true
        },
        position: {x: 110, y: 140},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0004-M', // 4 - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '4',
          isSubElement: false
        },
        position: {x: 105, y: 370},
        connectable: false,
        deletable: false
      },
      {
        id: '0004R-M', // 4R - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '4R',
          isSubElement: false,
          logicalElements: [{type: ELogicalElement.Recycle, filled: true}]
        },
        position: {x: 105, y: 435},
        connectable: false,
        deletable: false
      },
      {
        id: '0005-M', // 5 - Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '5',
          isSubElement: false
        },
        position: {x: 635, y: 91},
        connectable: false,
        deletable: false
      },
      {
        id: '0006-M', // 6- Element from MAIN workzone
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '6',
          isSubElement: false
        },
        position: {x: 635, y: 170},
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-IN', // DOT 1 - IN
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: -6, y: 305},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0002-DOT-IN', // DOT 2 - IN
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: -6, y: 370},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Сырьё',
          isSubElement: true
        },
        position: {x: 82, y: 290},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-РЕЦ-ПОТОК', // FLOW - РЕЦ-ПОТОК
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Рецирк. поток',
          isSubElement: true
        },
        position: {x: 82, y: 355},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Дым. газы',
          isSubElement: true
        },
        position: {x: 298, y: 11},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-1', // DOT 1 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 382, y: 26},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0001-DOT-OUT-2', // DOT 2 - OUT
        type: ERFElement.DotMaterial,
        data: {
          templateCode: 'single',
          isNotElement: true,
          isSubElement: true
        },
        position: {x: 382, y: 105},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      },
      {
        id: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты из реактора
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'Продукты из реактора',
          isSubElement: true
        },
        position: {x: 298, y: 90},
        parentId: 'SUB_GROUP',
        connectable: false,
        deletable: false
      }
    ],
    edges: [
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0004-M', // 4 - Element from MAIN workzone
        target: '0001-DOT-IN', // DOT 1 - IN
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0004R-M', // 4R - Element from MAIN workzone
        target: '0002-DOT-IN', // DOT 2 - IN
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-IN', // DOT 1 - IN
        target: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0002-DOT-IN', // DOT 2 - IN
        target: '0000-SUB-РЕЦ-ПОТОК', // FLOW - РЕЦ-ПОТОК
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-РЕЦ-ПОТОК', // FLOW - РЕЦ-ПОТОК
        target: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        targetHandle: 'target_material_recycling_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-СЫРЬЁ', // FLOW - СЫРЬЁ
        target: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        targetHandle: 'target_material_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        target: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        sourceHandle: 'source_material_gas_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '196C89E0-0000-0000-0000-00000000SUB9', // Реакт.-реген. блок
        target: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты из реактора
        sourceHandle: 'source_material_product_flow',
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ДЫМ-ГАЗЫ', // FLOW - Дымовые газы
        target: '0001-DOT-OUT-1', // DOT 1 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-OUT-1', // DOT 1 - OUT
        target: '0005-M', // 5 - Element from MAIN workzone
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0000-SUB-ПРОД-РЕАКТОРА', // FLOW - Продукты реактора
        target: '0001-DOT-OUT-2', // DOT 2 - OUT
        deletable: false
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        source: '0001-DOT-OUT-2', // DOT 2 - OUT
        target: '0006-M', // 6 - Element from MAIN workzone
        deletable: false
      }
    ]
  }
];
