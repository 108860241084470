import {generatePath, Navigate, Route, Routes} from 'react-router';
import {ReactElement} from 'react';

import {IRoute} from 'core/interfaces';
import {ProjectTypeEnum} from 'api';
import {ROUTES} from 'core/constants';

export const createRouteArray = (routes: IRoute[]): ReactElement[] => {
  return routes.map((route, idx) => {
    return <Route key={idx} path={route.path} element={route.element} />;
  });
};

export const createRoutesNode = (routes: IRoute[], defaultRedirect?: string): ReactElement => {
  return (
    <Routes>
      {createRouteArray(routes)}
      {defaultRedirect && <Route path="*" element={<Navigate to={defaultRedirect} replace />} />}
    </Routes>
  );
};

export const getProjectUrl = (type: ProjectTypeEnum, id: string, checkpointId: string) => {
  switch (type) {
    case ProjectTypeEnum.SamplesAndOilBlend: {
      return generatePath(ROUTES.SamplesAndBlends, {id, checkpointId});
    }
    case ProjectTypeEnum.TechProcess: {
      return generatePath(ROUTES.TechProcess, {id, checkpointId});
    }
    default:
      console.error(`There is no such type ${type}`);
      return ROUTES.MyProjects;
  }
};
