import {EElement} from '@progress-fe/core';

import {TElementDetailsSnapshotIn} from 'core/models';

export const CAT_CRACKING_1_ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000002',
    projectName: 'Кат. крекинг (моковый проект)',
    items: [
      {
        uuid: '196C89E0-0000-0000-0000-000000000001',
        type: EElement.CatCrackingElement,
        name: 'FCC-100 1',
        lastUpdated: new Date(),
        jsonSchemas: [],
        subElements: [
          {
            uuid: '196C89E0-0000-0000-0000-00000000SUB9',
            type: EElement.CatCrackingReactorRegeneratorBlockElement,
            name: 'Реакт.-реген. блок',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-СЫРЬЁ',
            type: EElement.MaterialFlowElement,
            name: 'Сырьё',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-РЕЦ-ПОТОК',
            type: EElement.MaterialFlowElement,
            name: 'Рецирк. поток',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ДЫМ-ГАЗЫ',
            type: EElement.MaterialFlowElement,
            name: 'Дымовые газы',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ПРОД-РЕАКТОРА',
            type: EElement.MaterialFlowElement,
            name: 'Продукты из реактора',
            lastUpdated: new Date(),
            jsonSchemas: []
          }
        ]
      },
      {
        uuid: '0004-M',
        type: EElement.MaterialFlowElement,
        name: '4',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0004R-M',
        type: EElement.MaterialFlowElement,
        name: '4R',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0005-M',
        type: EElement.MaterialFlowElement,
        name: '5',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0006-M',
        type: EElement.MaterialFlowElement,
        name: '6',
        lastUpdated: new Date(),
        jsonSchemas: []
      }
    ]
  }
];
