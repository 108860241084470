import {Edge, Node} from '@xyflow/react';
import {ERFEdge, ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';
import {ELogicalElement} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

export const CAT_CRACKING_1_WORKZONE_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000002',
    projectName: 'Кат. крекинг 1 (моковый проект)',
    nodes: [
      {
        id: '196C89E0-0000-0000-0000-000000000001', // FCC-100 1
        type: ERFElement.CatCracking,
        data: {
          elementName: 'FCC-100 1',
          templateCode: 'universal-template-1'
        },
        position: {x: 180, y: 110},
        connectable: true,
        deletable: true
      },
      {
        id: '0004-M', // 4
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '4'
        },
        position: {x: 140, y: 375},
        connectable: true,
        deletable: true
      },
      {
        id: '0004R-M', // 4R
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '4R',
          logicalElements: [{type: ELogicalElement.Recycle, filled: true}]
        },
        position: {x: 140, y: 315},
        connectable: true,
        deletable: true
      },
      {
        id: '0005-M', // 5
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '5'
        },
        position: {x: 320, y: 40},
        connectable: true,
        deletable: true
      },
      {
        id: '0006-M', // 6
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '6'
        },
        position: {x: 420, y: 114},
        connectable: true,
        deletable: true
      }
    ],
    edges: [
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '0004-M', // 4
        target: '196C89E0-0000-0000-0000-000000000001', // FCC-100 1
        targetHandle: 'target_material_flow',
        deletable: true
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '0004R-M', // 4R
        target: '196C89E0-0000-0000-0000-000000000001', // FCC-100 1
        targetHandle: 'target_material_recycle_flow',
        deletable: true
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000001', // FCC-100 1
        target: '0005-M', // 5
        sourceHandle: 'source_material_gas_flow',
        deletable: true
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000001', // FCC-100 1
        target: '0006-M', // 6
        sourceHandle: 'source_material_product_flow',
        deletable: true
      }
    ]
  }
];
