import {FC, DragEvent, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Flex} from '@chakra-ui/react';
import {
  ERFElement,
  IRFMenuItem,
  RF_DRAG_NODE_TYPE,
  RF_DRAG_TEMPLATE_CODE
} from '@progress-fe/rf-core';

import {RFMenuGroup, RFMenuItem} from './components';

interface IProps {
  menuItems: IRFMenuItem[];
  isDisabled?: boolean;
  height: number;
}

const RFMenuFC: FC<IProps> = ({height, menuItems, isDisabled}) => {
  const [isDragging, setIsDragging] = useState(false);

  const onDragStart = (event: DragEvent<HTMLDivElement>, type: ERFElement, template: string) => {
    if (!!event.dataTransfer) {
      event.dataTransfer.setData(RF_DRAG_NODE_TYPE, type);
      event.dataTransfer.setData(RF_DRAG_TEMPLATE_CODE, template);
      event.dataTransfer.effectAllowed = 'move';
    }
  };

  const formattedMenuItems = isDisabled
    ? menuItems.map((m) => ({...m, isDisabled: true}))
    : menuItems;

  return (
    <Flex
      bg="bg"
      p="4px 0"
      width="100%"
      height={height}
      boxShadow="shadow_4"
      position="relative"
      flexDirection="column"
      alignItems="center"
      zIndex={1}
      gap="4px"
    >
      {formattedMenuItems.map((item, i) => {
        return (
          <Box key={i}>
            {!!item.items ? (
              <RFMenuGroup
                item={item}
                isDragging={isDragging}
                updateIsDragging={setIsDragging}
                onDragStart={onDragStart}
              />
            ) : (
              <RFMenuItem
                item={item}
                isDragging={isDragging}
                updateIsDragging={setIsDragging}
                onDragStart={onDragStart}
              />
            )}
          </Box>
        );
      })}
    </Flex>
  );
};

export const RFMenu = observer(RFMenuFC);
