/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ComputeStatusCodeEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_unknown_default_open_api: 11184809
} as const;
export type ComputeStatusCodeEnum =
  (typeof ComputeStatusCodeEnum)[keyof typeof ComputeStatusCodeEnum];

export function instanceOfComputeStatusCodeEnum(value: any): boolean {
  for (const key in ComputeStatusCodeEnum) {
    if (Object.prototype.hasOwnProperty.call(ComputeStatusCodeEnum, key)) {
      if ((ComputeStatusCodeEnum as Record<string, ComputeStatusCodeEnum>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ComputeStatusCodeEnumFromJSON(json: any): ComputeStatusCodeEnum {
  return ComputeStatusCodeEnumFromJSONTyped(json, false);
}

export function ComputeStatusCodeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ComputeStatusCodeEnum {
  return json as ComputeStatusCodeEnum;
}

export function ComputeStatusCodeEnumToJSON(value?: ComputeStatusCodeEnum | null): any {
  return value as any;
}
