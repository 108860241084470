/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ComputeStatusCodeEnum} from './ComputeStatusCodeEnum';
import {
  ComputeStatusCodeEnumFromJSON,
  ComputeStatusCodeEnumFromJSONTyped,
  ComputeStatusCodeEnumToJSON
} from './ComputeStatusCodeEnum';
import type {CalculationTaskRunStatusErrorInfoInner} from './CalculationTaskRunStatusErrorInfoInner';
import {
  CalculationTaskRunStatusErrorInfoInnerFromJSON,
  CalculationTaskRunStatusErrorInfoInnerFromJSONTyped,
  CalculationTaskRunStatusErrorInfoInnerToJSON
} from './CalculationTaskRunStatusErrorInfoInner';

/**
 *
 * @export
 * @interface CalculationTaskRunStatus
 */
export interface CalculationTaskRunStatus {
  /**
   *
   * @type {ComputeStatusCodeEnum}
   * @memberof CalculationTaskRunStatus
   */
  statusCode: ComputeStatusCodeEnum;
  /**
   *
   * @type {Array<CalculationTaskRunStatusErrorInfoInner>}
   * @memberof CalculationTaskRunStatus
   */
  errorInfo: Array<CalculationTaskRunStatusErrorInfoInner>;
}

/**
 * Check if a given object implements the CalculationTaskRunStatus interface.
 */
export function instanceOfCalculationTaskRunStatus(
  value: object
): value is CalculationTaskRunStatus {
  if (!('statusCode' in value) || value['statusCode'] === undefined) return false;
  if (!('errorInfo' in value) || value['errorInfo'] === undefined) return false;
  return true;
}

export function CalculationTaskRunStatusFromJSON(json: any): CalculationTaskRunStatus {
  return CalculationTaskRunStatusFromJSONTyped(json, false);
}

export function CalculationTaskRunStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalculationTaskRunStatus {
  if (json == null) {
    return json;
  }
  return {
    statusCode: ComputeStatusCodeEnumFromJSON(json['status_code']),
    errorInfo: (json['error_info'] as Array<any>).map(
      CalculationTaskRunStatusErrorInfoInnerFromJSON
    )
  };
}

export function CalculationTaskRunStatusToJSON(value?: CalculationTaskRunStatus | null): any {
  if (value == null) {
    return value;
  }
  return {
    status_code: ComputeStatusCodeEnumToJSON(value['statusCode']),
    error_info: (value['errorInfo'] as Array<any>).map(CalculationTaskRunStatusErrorInfoInnerToJSON)
  };
}
