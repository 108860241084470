import {UserManager} from 'core/services/oidc.service';

import {
  Configuration,
  DefaultApi as GeneratedDefaultApi,
  OidcApi as GeneratedOidcApi,
  CatalogsApi as GeneratedCatalogsApi,
  CheckpointsApi as GeneratedCheckpointsApi,
  ProjectsApi as GeneratedProjectsApi,
  TechProcessApi as GeneratedTechProcessApi,
  OilApi as GeneratedOilApi,
  UsersApi as GeneratedUsersApi
} from './generated';

const AppConfiguration = new Configuration({
  basePath: '',
  accessToken: async (): Promise<string> => {
    const user = await UserManager.getUser();
    return user ? `Bearer ${user.access_token}` : '';
  }
});

const DefaultApi = new GeneratedDefaultApi(AppConfiguration);
const OidcApi = new GeneratedOidcApi(AppConfiguration);
const CatalogsApi = new GeneratedCatalogsApi(AppConfiguration);
const CheckpointsApi = new GeneratedCheckpointsApi(AppConfiguration);
const ProjectsApi = new GeneratedProjectsApi(AppConfiguration);
const TechProcessApi = new GeneratedTechProcessApi(AppConfiguration);
const OilApi = new GeneratedOilApi(AppConfiguration);
const UsersApi = new GeneratedUsersApi(AppConfiguration);

export {
  DefaultApi,
  OidcApi,
  CatalogsApi,
  CheckpointsApi,
  ProjectsApi,
  TechProcessApi,
  OilApi,
  UsersApi
};
