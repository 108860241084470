import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Grid} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';
import {SelectTabs, Textarea, JsFormName, JsFieldName} from '@progress-fe/ui-kit';

import {ETaskType, TaskTypeOptions} from 'core/enums';
import {TProjectTaskModel} from 'stores/TechProcessStore';

interface IProps {
  taskInstance: TProjectTaskModel;
}

const TaskFormFC: FC<IProps> = ({taskInstance}) => {
  const {t} = useT();

  return (
    <Box>
      <JsFormName name={t('common.task')} />

      <Grid templateColumns="80px 1fr" alignItems="center" p="0 0 16px 0" gap="4px">
        <JsFieldName name={t('fields.taskType')} />
        <SelectTabs
          value={taskInstance.type}
          options={TaskTypeOptions}
          onChange={(newValue) => taskInstance.setType(newValue as ETaskType)}
        />
      </Grid>

      <Grid templateColumns="80px 1fr" alignItems="flex-start" gap="4px">
        <JsFieldName name={t('fields.description')} />
        <Textarea
          isOnChangeOnlyOnBlur
          value={taskInstance.description}
          onChange={taskInstance.setDescription}
        />
      </Grid>
    </Box>
  );
};

export const TaskForm = observer(TaskFormFC);
