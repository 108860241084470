import {Edge, Node} from '@xyflow/react';
import {v4 as uuidv4} from 'uuid';
import {ERFEdge, ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';

export const CAT_CRACKING_4A_WORKZONE_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000006',
    projectName: 'Кат. крекинг 4A (моковый проект)',
    nodes: [
      {
        id: '196C89E0-0000-0000-0000-000000000000', // FCC-100 4A
        type: ERFElement.CatCracking,
        data: {
          elementName: 'FCC-100 4A',
          templateCode: 'universal-template-4A'
        },
        position: {x: 180, y: 510},
        connectable: true,
        deletable: true
      },
      {
        id: '00S1-M', // S1
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'S1'
        },
        position: {x: 140, y: 715},
        connectable: true,
        deletable: true
      },
      {
        id: '00S2-M', // S2
        type: ERFElement.FlowMaterial,
        data: {
          elementName: 'S2'
        },
        position: {x: 140, y: 775},
        connectable: true,
        deletable: true
      },
      {
        id: '0002-M', // 2
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '2'
        },
        position: {x: 320, y: 440},
        connectable: true,
        deletable: true
      },
      {
        id: '0003-M', // 3
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '3'
        },
        position: {x: 420, y: 514},
        connectable: true,
        deletable: true
      }
    ],
    edges: [
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '00S1-M', // S1
        target: '196C89E0-0000-0000-0000-000000000000', // FCC-100 4A
        targetHandle: 'target_material_flows',
        deletable: true
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '00S2-M', // S2
        target: '196C89E0-0000-0000-0000-000000000000', // FCC-100 4A
        targetHandle: 'target_material_flows',
        deletable: true
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000000', // FCC-100 4A
        target: '0002-M', // 2
        sourceHandle: 'source_material_gas_flow',
        deletable: true
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000000', // FCC-100 4A
        target: '0003-M', // 3
        sourceHandle: 'source_material_top_product',
        deletable: true
      }
    ]
  }
];
