import {EElement} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

import {TJsonSchemaSnapshotIn} from 'core/models';

export const SCHEMAS: TJsonSchemaSnapshotIn[] = [
  {
    id: uuidv4(),
    name: 'Базовые',
    schema: {
      type: 'object',
      properties: {
        basic: {
          title: 'Базовые свойства',
          type: 'object',
          required: ['name', 'elementType', 'modelId'],
          properties: {
            name: {
              title: 'Имя',
              type: 'string',
              minLength: 1
            },
            elementType: {
              title: 'Тип элемента',
              type: 'string',
              oneOf: [{const: EElement.MaterialFlowElement, title: 'Материальный поток'}],
              readOnly: true
            },
            modelId: {
              title: 'Модель',
              type: 'string',
              oneOf: [
                {const: '031CE246-0000-0000-0000-000000000001', title: 'Пенг-Робинсон-1'},
                {
                  const: '031CE246-0000-0000-0000-000000000002',
                  title: 'Пенг-Робинсон-2',
                  readOnly: true
                }
              ]
            }
          }
        },
        connections: {
          title: 'Связи',
          type: 'object',
          properties: {
            source: {
              title: 'Источник',
              type: ['string', 'null'],
              oneOf: [
                {const: null},
                {const: '196C89E0-0000-0000-0000-000000000001', title: 'V-100'}
              ]
            },
            consumer: {
              title: 'Потребитель',
              type: ['string', 'null'],
              oneOf: [
                {const: null},
                {const: '196C89E0-0000-0000-0000-000000000001', title: 'V-100'}
              ]
            }
          }
        }
      }
    },
    uiSchema: {
      basic: {
        name: {
          'ui:field': '/schemas/jsf-input'
        },
        elementType: {
          'ui:field': '/schemas/jsf-select'
        },
        modelId: {
          'ui:field': '/schemas/jsf-select-arrow'
        }
      },
      connections: {
        source: {
          'ui:field': '/schemas/jsf-select-pick-arrow'
        },
        consumer: {
          'ui:field': '/schemas/jsf-select-pick-arrow'
        }
      }
    },
    formData: {
      basic: {
        name: 'Технический конденсат',
        elementType: EElement.MaterialFlowElement,
        modelId: '031CE246-0000-0000-0000-000000000001'
      },
      connections: {
        source: null,
        consumer: null
      }
    }
  },
  {
    id: uuidv4(),
    name: 'Характеристики',
    schema: {
      type: 'object',
      properties: {
        characteristics: {
          title: 'Характеристики',
          type: 'object',
          required: ['feedstock_temperature', 'feedstock_pressure', 'feedstock_mass_flow_rate'],
          properties: {
            feedstock_temperature: {
              title: 'Температура',
              type: ['number', 'null'],
              minimum: 350,
              maximum: 550,
              $comment: '°C'
            },
            feedstock_pressure: {
              title: 'Давление',
              type: ['number', 'null'],
              exclusiveMinimum: 0,
              maximum: 100000,
              $comment: 'кПа (абс.)'
            },
            feedstock_mass_flow_rate: {
              title: 'Массовый расход',
              type: ['number', 'null'],
              exclusiveMinimum: 0,
              $comment: 'т/ч'
            }
          }
        }
      }
    },
    uiSchema: {
      characteristics: {
        feedstock_temperature: {
          'ui:field': '/schemas/jsf-input'
        },
        feedstock_pressure: {
          'ui:field': '/schemas/jsf-input'
        },
        feedstock_mass_flow_rate: {
          'ui:field': '/schemas/jsf-input'
        }
      }
    },
    formData: {
      characteristics: {
        feedstock_temperature: null,
        feedstock_pressure: null,
        feedstock_mass_flow_rate: null
      }
    }
  }
];
