/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface CheckpointInUpdate
 */
export interface CheckpointInUpdate {
  /**
   *
   * @type {string}
   * @memberof CheckpointInUpdate
   */
  name: string | null;
}

/**
 * Check if a given object implements the CheckpointInUpdate interface.
 */
export function instanceOfCheckpointInUpdate(value: object): value is CheckpointInUpdate {
  if (!('name' in value) || value['name'] === undefined) return false;
  return true;
}

export function CheckpointInUpdateFromJSON(json: any): CheckpointInUpdate {
  return CheckpointInUpdateFromJSONTyped(json, false);
}

export function CheckpointInUpdateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CheckpointInUpdate {
  if (json == null) {
    return json;
  }
  return {
    name: json['name']
  };
}

export function CheckpointInUpdateToJSON(value?: CheckpointInUpdate | null): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name']
  };
}
