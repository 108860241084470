/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ResponseStatusListUUID
 */
export interface ResponseStatusListUUID {
  /**
   *
   * @type {string}
   * @memberof ResponseStatusListUUID
   */
  status: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ResponseStatusListUUID
   */
  data?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof ResponseStatusListUUID
   */
  errorMsg?: string | null;
}

/**
 * Check if a given object implements the ResponseStatusListUUID interface.
 */
export function instanceOfResponseStatusListUUID(value: object): value is ResponseStatusListUUID {
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function ResponseStatusListUUIDFromJSON(json: any): ResponseStatusListUUID {
  return ResponseStatusListUUIDFromJSONTyped(json, false);
}

export function ResponseStatusListUUIDFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResponseStatusListUUID {
  if (json == null) {
    return json;
  }
  return {
    status: json['status'],
    data: json['data'] == null ? undefined : json['data'],
    errorMsg: json['error_msg'] == null ? undefined : json['error_msg']
  };
}

export function ResponseStatusListUUIDToJSON(value?: ResponseStatusListUUID | null): any {
  if (value == null) {
    return value;
  }
  return {
    status: value['status'],
    data: value['data'],
    error_msg: value['errorMsg']
  };
}
