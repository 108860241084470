import {flow, Instance, types} from 'mobx-state-tree';

import {ReactionDetails, ResetModel, TReactionDetailsModel} from 'core/models';

const ProjectReactions = types
  .compose(
    ResetModel,
    types.model('ProjectReactions', {
      projectUuid: '',
      checkpointUuid: '',
      reactions: types.optional(types.array(ReactionDetails), [])
    })
  )
  .actions((self) => ({
    _loadReactions: flow(function* () {
      self.reactions.push(
        ReactionDetails.create({uuid: '1', name: 'Реакция 1', lastUpdated: new Date()})
      );
      self.reactions.push(
        ReactionDetails.create({uuid: '2', name: 'Реакция 2', lastUpdated: new Date()})
      );
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;

      self._loadReactions();
    })
  }))
  .views((self) => ({
    get dictionary(): Record<string, TReactionDetailsModel> {
      const dict = self.reactions.reduce(
        (acc: Record<string, TReactionDetailsModel>, cur: TReactionDetailsModel) => {
          acc[cur.uuid] = cur;
          return acc;
        },
        {}
      );

      return dict;
    }
  }));

export type TProjectReactionsModel = Instance<typeof ProjectReactions>;

export {ProjectReactions};
