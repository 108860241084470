/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {JournalLog} from './JournalLog';
import {JournalLogFromJSON, JournalLogFromJSONTyped, JournalLogToJSON} from './JournalLog';

/**
 *
 * @export
 * @interface TechProcessJournalTaskRunPoint
 */
export interface TechProcessJournalTaskRunPoint {
  /**
   *
   * @type {Date}
   * @memberof TechProcessJournalTaskRunPoint
   */
  runAt: Date;
  /**
   *
   * @type {number}
   * @memberof TechProcessJournalTaskRunPoint
   */
  calculationNumber: number;
  /**
   *
   * @type {Array<JournalLog>}
   * @memberof TechProcessJournalTaskRunPoint
   */
  items: Array<JournalLog>;
}

/**
 * Check if a given object implements the TechProcessJournalTaskRunPoint interface.
 */
export function instanceOfTechProcessJournalTaskRunPoint(
  value: object
): value is TechProcessJournalTaskRunPoint {
  if (!('runAt' in value) || value['runAt'] === undefined) return false;
  if (!('calculationNumber' in value) || value['calculationNumber'] === undefined) return false;
  if (!('items' in value) || value['items'] === undefined) return false;
  return true;
}

export function TechProcessJournalTaskRunPointFromJSON(json: any): TechProcessJournalTaskRunPoint {
  return TechProcessJournalTaskRunPointFromJSONTyped(json, false);
}

export function TechProcessJournalTaskRunPointFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechProcessJournalTaskRunPoint {
  if (json == null) {
    return json;
  }
  return {
    runAt: new Date(json['run_at']),
    calculationNumber: json['calculation_number'],
    items: (json['items'] as Array<any>).map(JournalLogFromJSON)
  };
}

export function TechProcessJournalTaskRunPointToJSON(
  value?: TechProcessJournalTaskRunPoint | null
): any {
  if (value == null) {
    return value;
  }
  return {
    run_at: value['runAt'].toISOString(),
    calculation_number: value['calculationNumber'],
    items: (value['items'] as Array<any>).map(JournalLogToJSON)
  };
}
