import {EElement} from '@progress-fe/core';

import {EElementResultType} from 'core/enums';
import {TResultDetailsSnapshotIn} from 'core/models';
import * as V_100 from 'core/mocks/projects/Separators/results/2_phase_separator/V_100_basic';
import * as K_100 from 'core/mocks/projects/Separators/results/compressor/K_100_basic';

export const RESULTS_LIST: {
  projectId: string;
  projectName: string;
  items: TResultDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами (моковый проект)',
    items: [
      {
        uuid: 'C36FFC58-0000-0000-0000-000000000001',
        name: 'V-100',
        elementUuid: '196C89E0-0000-0000-0000-000000000001',
        type: EElement.TwoPhaseSeparatorElement,
        resultType: EElementResultType.Separator_2ph_Threads,
        icon: 'Table',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: '0425B81E-0000-0000-0000-000000000001',
            name: 'Базовые',
            uiSchema: V_100.UI_SCHEMA,
            schema: V_100.SCHEMA,
            formData: V_100.FORM_DATA
          }
        ]
      },
      {
        uuid: 'C36FFC58-0000-0000-0000-000000000002',
        name: 'K-100',
        elementUuid: '196C89E0-0000-0000-0000-000000000002',
        type: EElement.CompressorElement,
        resultType: EElementResultType.Compressor_Threads,
        icon: 'Table',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: '0425B81E-0000-0000-0000-000000000002',
            name: 'Базовые',
            uiSchema: K_100.UI_SCHEMA,
            schema: K_100.SCHEMA,
            formData: K_100.FORM_DATA
          }
        ]
      }
    ]
  }
];
