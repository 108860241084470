import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {SliderPointItem} from './models';

/** @deprecated **/
const CalculationSliderResult = types
  .model('CalculationSliderResult', {
    points: types.optional(types.array(SliderPointItem), [])
  })
  .actions(() => ({}))
  .views(() => ({}));

export type TCalculationSliderResultModel = Instance<typeof CalculationSliderResult>;

export type TCalculationSliderResultSnapshotIn = SnapshotIn<typeof CalculationSliderResult>;

export {CalculationSliderResult};
