import {Edge, Node} from '@xyflow/react';
import {v4 as uuidv4} from 'uuid';
import {ERFEdge, ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';

export const CAT_CRACKING_2A_WORKZONE_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000003',
    projectName: 'Кат. крекинг 2A (моковый проект)',
    nodes: [
      /* FCC-100 2A */
      {
        id: '196C89E0-0000-0000-0000-000000000000', // FCC-100 2A
        type: ERFElement.CatCracking,
        data: {
          elementName: 'FCC-100 2A',
          templateCode: 'universal-template-2A'
        },
        position: {x: 180, y: 510},
        connectable: true,
        deletable: true
      },
      {
        id: '0001-M', // 1
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '1'
        },
        position: {x: 140, y: 715},
        connectable: true,
        deletable: true
      },
      {
        id: '0002-M', // 2
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '2'
        },
        position: {x: 320, y: 440},
        connectable: true,
        deletable: true
      },
      {
        id: '0003-M', // 3
        type: ERFElement.FlowMaterial,
        data: {
          elementName: '3'
        },
        position: {x: 420, y: 514},
        connectable: true,
        deletable: true
      }
    ],
    edges: [
      /* FCC-100 2A */
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '0001-M', // 1
        target: '196C89E0-0000-0000-0000-000000000000', // FCC-100 2A
        targetHandle: 'target_material_flow',
        deletable: true
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000000', // FCC-100 2A
        target: '0002-M', // 2
        sourceHandle: 'source_material_gas_flow',
        deletable: true
      },
      {
        id: uuidv4(),
        type: ERFEdge.Material,
        reconnectable: true,
        source: '196C89E0-0000-0000-0000-000000000000', // FCC-100 2A
        target: '0003-M', // 3
        sourceHandle: 'source_material_top_product',
        deletable: true
      }
    ]
  }
];
