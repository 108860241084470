import {cast, flow, Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {AppProjectsOilSchemasSchemasUpdateOut, OilApi, SampleOut, SamplesOut} from 'api';
import {RequestModel, ResetModel, SampleDetails, TSampleDetailsModel} from 'core/models';

const ProjectSamples = types
  .compose(
    ResetModel,
    types.model('ProjectSamples', {
      projectUuid: '',
      checkpointUuid: '',
      samples: types.optional(types.array(SampleDetails), []),
      formDataRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      creationRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    clearJsonSchemas() {
      self.samples.forEach((sample) => {
        sample.clearJsonSchemas();
      });
    },
    async loadJsonSchemasByUuid(uuid: string) {
      const sample = self.samples.find((e) => e.uuid === uuid);
      await sample?.loadJsonSchemas(self.projectUuid, self.checkpointUuid);
    }
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      self.samples = cast([]);

      const response: SamplesOut = yield self.fetchRequest.send(OilApi.oilGetSamples.bind(OilApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid
      });

      if (!!response?.samples) {
        self.samples = cast(
          response.samples.map((m) => ({
            uuid: m.uuid,
            name: m.name,
            lastUpdated: new Date()
          }))
        );
      }
    })
  }))
  .actions((self) => ({
    addSample: flow(function* () {
      const response: SampleOut = yield self.creationRequest.send(
        OilApi.oilCreateSample.bind(OilApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4()
        }
      );

      return self.creationRequest.isDone && !!response
        ? SampleDetails.create({
            uuid: response.uuid,
            name: response.name,
            lastUpdated: new Date()
          })
        : null;
    }),
    removeSample: flow(function* (uuid: string) {
      yield self.actionRequest.send(OilApi.oilDeleteSample.bind(OilApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        sampleUuid: uuid
      });

      return self.actionRequest.isDone;
    }),
    _updateSampleName(uuid: string, name: string) {
      const sample = self.samples.find((n) => n.uuid === uuid);
      sample?.setName(name);
    }
  }))
  .actions((self) => ({
    updateSampleFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const sample = self.samples.find((el) => el.uuid === uuid);
      const jsonSchema = sample?.jsonSchemas.find((js) => js.id === schemaId);

      if (!!sample && !!jsonSchema) {
        jsonSchema.updateFormData(data);

        const response: AppProjectsOilSchemasSchemasUpdateOut = yield self.formDataRequest.send(
          OilApi.oilUpdateSample.bind(OilApi),
          {
            sampleUuid: uuid,
            body: data as object,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid
          }
        );

        if (self.formDataRequest.isDone) {
          if (!!response.name) {
            console.info('Sample name was changed.');
            self._updateSampleName(uuid, response.name);
          }
          if (!!response.schemas) {
            console.info('Sample schemas was changed.');
            sample.setJsonSchemas(response.schemas);
          }
        }
      }
    })
  }))
  .actions((self) => ({
    hasSample(uuid: string): boolean {
      return self.samples.some((e) => e.uuid === uuid);
    },
    findSample(uuid: string): TSampleDetailsModel | undefined {
      return self.samples.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isCreating(): boolean {
      return self.creationRequest.isPending;
    },
    get isFormDataUpdating(): boolean {
      return self.formDataRequest.isPending;
    }
  }));

export type TProjectSamplesModel = Instance<typeof ProjectSamples>;

export {ProjectSamples};
