import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center} from '@chakra-ui/react';
import {ELogicalElement, useT} from '@progress-fe/core';
import {ISvgMenuItem, ItemListHeader, Loader, useDialog} from '@progress-fe/ui-kit';
import {LogicalElementIcon} from '@progress-fe/rf-core';

import {StructureItem} from 'ui-kit';
import {EActionType, ETaskType} from 'core/enums';
import {TLogicalElementDetailsModel} from 'core/models';

interface IProps {
  isLoading: boolean;
  taskType: ETaskType;
  isTaskSelected: boolean;
  selectedId: string | null;
  logicalElements: TLogicalElementDetailsModel[];
  menuItems: ISvgMenuItem<ELogicalElement>[];
  onSelect: (uuid?: string) => void;
  onCreate: (type: ELogicalElement) => Promise<void>;
  onDelete: (uuid: string) => Promise<void>;
}

const TasksListFC: FC<IProps> = ({
  isLoading,
  taskType,
  menuItems,
  isTaskSelected,
  selectedId,
  logicalElements,
  onSelect,
  onCreate,
  onDelete
}) => {
  const {t} = useT();

  const DeleteDialog = useDialog({title: t('elements.delete')});

  const logicalElementsMenuItems: ISvgMenuItem<EActionType>[] = [
    {id: EActionType.Download, name: t('actions.download'), isDisabled: true},
    {id: EActionType.Duplicate, name: t('actions.duplicate'), isDisabled: true},
    {id: EActionType.Delete, name: t('actions.delete')}
  ];

  const handleOnMenuAction = (uuid: string, action: EActionType) => {
    const element = logicalElements.find((el) => el.uuid === uuid);
    switch (action) {
      case EActionType.Delete:
        DeleteDialog.open(t('logicalElements.deleteSureMsg', {name: element?.name}), {
          apply: {title: t('actions.delete'), isDanger: true, onClick: () => onDelete(uuid)},
          close: {title: t('actions.cancel')}
        });
        break;
      default:
        break;
    }
  };

  return (
    <Box overflowY="hidden">
      <DeleteDialog.render />
      <ItemListHeader
        menuItems={menuItems}
        title={t('common.task')}
        onMenuSelect={(item) => onCreate(item as ELogicalElement)}
      />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center pb="40px" height="100%">
            <Loader />
          </Center>
        ) : (
          <>
            <StructureItem
              item={{
                uuid: '',
                icon: 'Target',
                isActive: isTaskSelected,
                name: t(`enum.taskType.${taskType}`)
              }}
              onClick={() => onSelect()}
            />

            {logicalElements.map((le) => (
              <StructureItem
                key={le.uuid}
                item={{
                  uuid: le.uuid,
                  name: le.name,
                  isActive: selectedId === le.uuid,
                  elementIcon: le.logicalType ? LogicalElementIcon[le.logicalType] : undefined
                }}
                menuItems={logicalElementsMenuItems}
                onMenuAction={(action) => handleOnMenuAction(le.uuid, action)}
                onClick={() => onSelect(le.uuid)}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export const TasksList = observer(TasksListFC);
