/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TechProcessJournalTaskRunPointEnum = {
  RunAt: 'run_at',
  CalculationNumber: 'calculation_number',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type TechProcessJournalTaskRunPointEnum =
  (typeof TechProcessJournalTaskRunPointEnum)[keyof typeof TechProcessJournalTaskRunPointEnum];

export function instanceOfTechProcessJournalTaskRunPointEnum(value: any): boolean {
  for (const key in TechProcessJournalTaskRunPointEnum) {
    if (Object.prototype.hasOwnProperty.call(TechProcessJournalTaskRunPointEnum, key)) {
      if (
        (TechProcessJournalTaskRunPointEnum as Record<string, TechProcessJournalTaskRunPointEnum>)[
          key
        ] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function TechProcessJournalTaskRunPointEnumFromJSON(
  json: any
): TechProcessJournalTaskRunPointEnum {
  return TechProcessJournalTaskRunPointEnumFromJSONTyped(json, false);
}

export function TechProcessJournalTaskRunPointEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechProcessJournalTaskRunPointEnum {
  return json as TechProcessJournalTaskRunPointEnum;
}

export function TechProcessJournalTaskRunPointEnumToJSON(
  value?: TechProcessJournalTaskRunPointEnum | null
): any {
  return value as any;
}
