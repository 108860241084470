/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AppProjectsTechprocessSchemasUpdateOut,
  BodyTechProcessAddConnection,
  BodyTechProcessRemoveConnection,
  CalculationTaskRunStatus,
  CallbackRunRequest,
  CallbackRunResponse,
  CreateElementActionResult,
  CreateLogicalElementActionResult,
  DeleteModelPossibility,
  ElementsOut,
  GraphZone,
  HTTPValidationError,
  LogicalElementIn,
  LogicalElementsOut,
  LogicalUpdateOut,
  ModelsOut,
  NewElement,
  NewModel,
  Position,
  ProfilerMode,
  RJSFSchemas,
  ResponseStatusListUUID,
  TechProcessActionResult,
  TechProcessApplyActionsRequestInner,
  TechProcessJournalTaskRunPoint,
  TechProcessJournalTaskRunPointEnum,
  TechprocessCalculationTaskOut,
  TechprocessCalculationTaskUpdate,
  WorkZone
} from '../models/index';
import {
  AppProjectsTechprocessSchemasUpdateOutFromJSON,
  AppProjectsTechprocessSchemasUpdateOutToJSON,
  BodyTechProcessAddConnectionFromJSON,
  BodyTechProcessAddConnectionToJSON,
  BodyTechProcessRemoveConnectionFromJSON,
  BodyTechProcessRemoveConnectionToJSON,
  CalculationTaskRunStatusFromJSON,
  CalculationTaskRunStatusToJSON,
  CallbackRunRequestFromJSON,
  CallbackRunRequestToJSON,
  CallbackRunResponseFromJSON,
  CallbackRunResponseToJSON,
  CreateElementActionResultFromJSON,
  CreateElementActionResultToJSON,
  CreateLogicalElementActionResultFromJSON,
  CreateLogicalElementActionResultToJSON,
  DeleteModelPossibilityFromJSON,
  DeleteModelPossibilityToJSON,
  ElementsOutFromJSON,
  ElementsOutToJSON,
  GraphZoneFromJSON,
  GraphZoneToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LogicalElementInFromJSON,
  LogicalElementInToJSON,
  LogicalElementsOutFromJSON,
  LogicalElementsOutToJSON,
  LogicalUpdateOutFromJSON,
  LogicalUpdateOutToJSON,
  ModelsOutFromJSON,
  ModelsOutToJSON,
  NewElementFromJSON,
  NewElementToJSON,
  NewModelFromJSON,
  NewModelToJSON,
  PositionFromJSON,
  PositionToJSON,
  ProfilerModeFromJSON,
  ProfilerModeToJSON,
  RJSFSchemasFromJSON,
  RJSFSchemasToJSON,
  ResponseStatusListUUIDFromJSON,
  ResponseStatusListUUIDToJSON,
  TechProcessActionResultFromJSON,
  TechProcessActionResultToJSON,
  TechProcessApplyActionsRequestInnerFromJSON,
  TechProcessApplyActionsRequestInnerToJSON,
  TechProcessJournalTaskRunPointFromJSON,
  TechProcessJournalTaskRunPointToJSON,
  TechProcessJournalTaskRunPointEnumFromJSON,
  TechProcessJournalTaskRunPointEnumToJSON,
  TechprocessCalculationTaskOutFromJSON,
  TechprocessCalculationTaskOutToJSON,
  TechprocessCalculationTaskUpdateFromJSON,
  TechprocessCalculationTaskUpdateToJSON,
  WorkZoneFromJSON,
  WorkZoneToJSON
} from '../models/index';

export interface TechProcessAddConnectionRequest {
  checkpointUuid: string;
  projectUuid: string;
  bodyTechProcessAddConnection: BodyTechProcessAddConnection;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessApplyActionsRequest {
  projectUuid: string;
  checkpointUuid: string;
  idempotencyKey: string;
  techProcessApplyActionsRequestInner: Array<TechProcessApplyActionsRequestInner>;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessCheckModelInstanceDeleteableRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessCreateElementRequest {
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  newElement: NewElement;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessCreateLogicalElementRequest {
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  logicalElementIn: LogicalElementIn;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessCreateModelRequest {
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  newModel: NewModel;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDeleteElementRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDeleteLogicalElementRequest {
  logicalElementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessDeleteModelInstanceRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetCompGraphRequest {
  projectUuid: string;
  checkpointUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetElementSchemasRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetElementsRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetFavoritePureComponentsRequest {
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetLogicalElementSchemasRequest {
  logicalElementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetLogicalElementsRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetModelSchemasRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetModelsRequest {
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetSubWorkZoneRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetTechprocessCalculationTasksRequest {
  projectUuid: string;
  checkpointUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetTechprocessJournalRequest {
  projectUuid: string;
  checkpointUuid: string;
  orderByField?: TechProcessJournalTaskRunPointEnum | null;
  orderBy?: TechProcessGetTechprocessJournalOrderByEnum;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessGetWorkZoneRequest {
  projectUuid: string;
  checkpointUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRemoveConnectionRequest {
  checkpointUuid: string;
  projectUuid: string;
  bodyTechProcessRemoveConnection: BodyTechProcessRemoveConnection;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRunElementCallbackRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  callbackRunRequest: CallbackRunRequest;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRunLogicalElementCallbackRequest {
  logicalElementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  callbackRunRequest: CallbackRunRequest;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRunModelCallbackRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  callbackRunRequest: CallbackRunRequest;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessRunTechprocessCalculationTaskRequest {
  projectUuid: string;
  checkpointUuid: string;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessSetFavoritePureComponentsRequest {
  projectUuid: string;
  requestBody: Array<string>;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessSetTechprocessCalculationTaskRequest {
  projectUuid: string;
  checkpointUuid: string;
  techprocessCalculationTaskUpdate: TechprocessCalculationTaskUpdate;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateElementInstanceRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateElementInstancePositionRequest {
  elementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  position: Position;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateLogicalElementInstanceRequest {
  logicalElementUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  xProfilerMode?: ProfilerMode;
}

export interface TechProcessUpdateModelInstanceRequest {
  modelInstanceUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  xProfilerMode?: ProfilerMode;
}

/**
 *
 */
export class TechProcessApi extends runtime.BaseAPI {
  /**
   * Add Connection
   */
  async techProcessAddConnectionRaw(
    requestParameters: TechProcessAddConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessAddConnection().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessAddConnection().'
      );
    }

    if (requestParameters['bodyTechProcessAddConnection'] == null) {
      throw new runtime.RequiredError(
        'bodyTechProcessAddConnection',
        'Required parameter "bodyTechProcessAddConnection" was null or undefined when calling techProcessAddConnection().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/connect`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BodyTechProcessAddConnectionToJSON(requestParameters['bodyTechProcessAddConnection'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Add Connection
   */
  async techProcessAddConnection(
    requestParameters: TechProcessAddConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessAddConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * Apply Actions
   */
  async techProcessApplyActionsRaw(
    requestParameters: TechProcessApplyActionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TechProcessActionResult>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessApplyActions().'
      );
    }

    if (requestParameters['techProcessApplyActionsRequestInner'] == null) {
      throw new runtime.RequiredError(
        'techProcessApplyActionsRequestInner',
        'Required parameter "techProcessApplyActionsRequestInner" was null or undefined when calling techProcessApplyActions().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/actions`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['techProcessApplyActionsRequestInner']!.map(
          TechProcessApplyActionsRequestInnerToJSON
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TechProcessActionResultFromJSON)
    );
  }

  /**
   * Apply Actions
   */
  async techProcessApplyActions(
    requestParameters: TechProcessApplyActionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TechProcessActionResult>> {
    const response = await this.techProcessApplyActionsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Check Model Instance Deleteable
   */
  async techProcessCheckModelInstanceDeleteableRaw(
    requestParameters: TechProcessCheckModelInstanceDeleteableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DeleteModelPossibility>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCheckModelInstanceDeleteable().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}/deleteable`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DeleteModelPossibilityFromJSON(jsonValue)
    );
  }

  /**
   * Check Model Instance Deleteable
   */
  async techProcessCheckModelInstanceDeleteable(
    requestParameters: TechProcessCheckModelInstanceDeleteableRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DeleteModelPossibility> {
    const response = await this.techProcessCheckModelInstanceDeleteableRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Element
   */
  async techProcessCreateElementRaw(
    requestParameters: TechProcessCreateElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateElementActionResult>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessCreateElement().'
      );
    }

    if (requestParameters['newElement'] == null) {
      throw new runtime.RequiredError(
        'newElement',
        'Required parameter "newElement" was null or undefined when calling techProcessCreateElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewElementToJSON(requestParameters['newElement'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateElementActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Create Element
   */
  async techProcessCreateElement(
    requestParameters: TechProcessCreateElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateElementActionResult> {
    const response = await this.techProcessCreateElementRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create Logical Element
   */
  async techProcessCreateLogicalElementRaw(
    requestParameters: TechProcessCreateLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CreateLogicalElementActionResult>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCreateLogicalElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCreateLogicalElement().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessCreateLogicalElement().'
      );
    }

    if (requestParameters['logicalElementIn'] == null) {
      throw new runtime.RequiredError(
        'logicalElementIn',
        'Required parameter "logicalElementIn" was null or undefined when calling techProcessCreateLogicalElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LogicalElementInToJSON(requestParameters['logicalElementIn'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreateLogicalElementActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Create Logical Element
   */
  async techProcessCreateLogicalElement(
    requestParameters: TechProcessCreateLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CreateLogicalElementActionResult> {
    const response = await this.techProcessCreateLogicalElementRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Model
   */
  async techProcessCreateModelRaw(
    requestParameters: TechProcessCreateModelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TechProcessActionResult>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling techProcessCreateModel().'
      );
    }

    if (requestParameters['newModel'] == null) {
      throw new runtime.RequiredError(
        'newModel',
        'Required parameter "newModel" was null or undefined when calling techProcessCreateModel().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewModelToJSON(requestParameters['newModel'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TechProcessActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Create Model
   */
  async techProcessCreateModel(
    requestParameters: TechProcessCreateModelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TechProcessActionResult> {
    const response = await this.techProcessCreateModelRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete Element
   */
  async techProcessDeleteElementRaw(
    requestParameters: TechProcessDeleteElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Element
   */
  async techProcessDeleteElement(
    requestParameters: TechProcessDeleteElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessDeleteElementRaw(requestParameters, initOverrides);
  }

  /**
   * Delete Logical Element
   */
  async techProcessDeleteLogicalElementRaw(
    requestParameters: TechProcessDeleteLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['logicalElementUuid'] == null) {
      throw new runtime.RequiredError(
        'logicalElementUuid',
        'Required parameter "logicalElementUuid" was null or undefined when calling techProcessDeleteLogicalElement().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteLogicalElement().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteLogicalElement().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{logical_element_uuid}`
          .replace(
            `{${'logical_element_uuid'}}`,
            encodeURIComponent(String(requestParameters['logicalElementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Logical Element
   */
  async techProcessDeleteLogicalElement(
    requestParameters: TechProcessDeleteLogicalElementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessDeleteLogicalElementRaw(requestParameters, initOverrides);
  }

  /**
   * Delete Model Instance
   */
  async techProcessDeleteModelInstanceRaw(
    requestParameters: TechProcessDeleteModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessDeleteModelInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<any>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   * Delete Model Instance
   */
  async techProcessDeleteModelInstance(
    requestParameters: TechProcessDeleteModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<any> {
    const response = await this.techProcessDeleteModelInstanceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Comp Graph
   */
  async techProcessGetCompGraphRaw(
    requestParameters: TechProcessGetCompGraphRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<GraphZone>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetCompGraph().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetCompGraph().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/comp_graph`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GraphZoneFromJSON(jsonValue));
  }

  /**
   * Get Comp Graph
   */
  async techProcessGetCompGraph(
    requestParameters: TechProcessGetCompGraphRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<GraphZone> {
    const response = await this.techProcessGetCompGraphRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Element Schemas
   */
  async techProcessGetElementSchemasRaw(
    requestParameters: TechProcessGetElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetElementSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Element Schemas
   */
  async techProcessGetElementSchemas(
    requestParameters: TechProcessGetElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessGetElementSchemasRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Elements
   */
  async techProcessGetElementsRaw(
    requestParameters: TechProcessGetElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ElementsOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetElements().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetElements().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ElementsOutFromJSON(jsonValue));
  }

  /**
   * Get Elements
   */
  async techProcessGetElements(
    requestParameters: TechProcessGetElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ElementsOut> {
    const response = await this.techProcessGetElementsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Получение списка избранных компонентов
   * Get Favorite Pure Components
   */
  async techProcessGetFavoritePureComponentsRaw(
    requestParameters: TechProcessGetFavoritePureComponentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetFavoritePureComponents().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/techprocess/favorite_pure_components`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Получение списка избранных компонентов
   * Get Favorite Pure Components
   */
  async techProcessGetFavoritePureComponents(
    requestParameters: TechProcessGetFavoritePureComponentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<string>> {
    const response = await this.techProcessGetFavoritePureComponentsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Logical Element Schemas
   */
  async techProcessGetLogicalElementSchemasRaw(
    requestParameters: TechProcessGetLogicalElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['logicalElementUuid'] == null) {
      throw new runtime.RequiredError(
        'logicalElementUuid',
        'Required parameter "logicalElementUuid" was null or undefined when calling techProcessGetLogicalElementSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetLogicalElementSchemas().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetLogicalElementSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{logical_element_uuid}`
          .replace(
            `{${'logical_element_uuid'}}`,
            encodeURIComponent(String(requestParameters['logicalElementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Logical Element Schemas
   */
  async techProcessGetLogicalElementSchemas(
    requestParameters: TechProcessGetLogicalElementSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessGetLogicalElementSchemasRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Logical Elements
   */
  async techProcessGetLogicalElementsRaw(
    requestParameters: TechProcessGetLogicalElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LogicalElementsOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetLogicalElements().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetLogicalElements().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogicalElementsOutFromJSON(jsonValue)
    );
  }

  /**
   * Get Logical Elements
   */
  async techProcessGetLogicalElements(
    requestParameters: TechProcessGetLogicalElementsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LogicalElementsOut> {
    const response = await this.techProcessGetLogicalElementsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Model Schemas
   */
  async techProcessGetModelSchemasRaw(
    requestParameters: TechProcessGetModelSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetModelSchemas().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Model Schemas
   */
  async techProcessGetModelSchemas(
    requestParameters: TechProcessGetModelSchemasRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.techProcessGetModelSchemasRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Models
   */
  async techProcessGetModelsRaw(
    requestParameters: TechProcessGetModelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ModelsOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetModels().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetModels().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ModelsOutFromJSON(jsonValue));
  }

  /**
   * Get Models
   */
  async techProcessGetModels(
    requestParameters: TechProcessGetModelsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ModelsOut> {
    const response = await this.techProcessGetModelsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Sub Work Zone
   */
  async techProcessGetSubWorkZoneRaw(
    requestParameters: TechProcessGetSubWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WorkZone>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessGetSubWorkZone().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetSubWorkZone().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetSubWorkZone().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}/work_zone`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WorkZoneFromJSON(jsonValue));
  }

  /**
   * Get Sub Work Zone
   */
  async techProcessGetSubWorkZone(
    requestParameters: TechProcessGetSubWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WorkZone> {
    const response = await this.techProcessGetSubWorkZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Получение заданий на расчёт
   * Get Techprocess Calculation Tasks
   */
  async techProcessGetTechprocessCalculationTasksRaw(
    requestParameters: TechProcessGetTechprocessCalculationTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TechprocessCalculationTaskOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetTechprocessCalculationTasks().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetTechprocessCalculationTasks().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_task`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TechprocessCalculationTaskOutFromJSON(jsonValue)
    );
  }

  /**
   * Получение заданий на расчёт
   * Get Techprocess Calculation Tasks
   */
  async techProcessGetTechprocessCalculationTasks(
    requestParameters: TechProcessGetTechprocessCalculationTasksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TechprocessCalculationTaskOut> {
    const response = await this.techProcessGetTechprocessCalculationTasksRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Получение журнала, сгруппированного по времени запуска заданий на расчёт
   * Get Techprocess Journal
   */
  async techProcessGetTechprocessJournalRaw(
    requestParameters: TechProcessGetTechprocessJournalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TechProcessJournalTaskRunPoint>>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetTechprocessJournal().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetTechprocessJournal().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['orderByField'] != null) {
      queryParameters['orderByField'] = requestParameters['orderByField'];
    }

    if (requestParameters['orderBy'] != null) {
      queryParameters['orderBy'] = requestParameters['orderBy'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/journal`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(TechProcessJournalTaskRunPointFromJSON)
    );
  }

  /**
   * Получение журнала, сгруппированного по времени запуска заданий на расчёт
   * Get Techprocess Journal
   */
  async techProcessGetTechprocessJournal(
    requestParameters: TechProcessGetTechprocessJournalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TechProcessJournalTaskRunPoint>> {
    const response = await this.techProcessGetTechprocessJournalRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Work Zone
   */
  async techProcessGetWorkZoneRaw(
    requestParameters: TechProcessGetWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WorkZone>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessGetWorkZone().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessGetWorkZone().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/work_zone`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => WorkZoneFromJSON(jsonValue));
  }

  /**
   * Get Work Zone
   */
  async techProcessGetWorkZone(
    requestParameters: TechProcessGetWorkZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WorkZone> {
    const response = await this.techProcessGetWorkZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Remove Connection
   */
  async techProcessRemoveConnectionRaw(
    requestParameters: TechProcessRemoveConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRemoveConnection().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRemoveConnection().'
      );
    }

    if (requestParameters['bodyTechProcessRemoveConnection'] == null) {
      throw new runtime.RequiredError(
        'bodyTechProcessRemoveConnection',
        'Required parameter "bodyTechProcessRemoveConnection" was null or undefined when calling techProcessRemoveConnection().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/connect`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: BodyTechProcessRemoveConnectionToJSON(
          requestParameters['bodyTechProcessRemoveConnection']
        )
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove Connection
   */
  async techProcessRemoveConnection(
    requestParameters: TechProcessRemoveConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessRemoveConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * Run Element Callback
   */
  async techProcessRunElementCallbackRaw(
    requestParameters: TechProcessRunElementCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CallbackRunResponse>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessRunElementCallback().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRunElementCallback().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRunElementCallback().'
      );
    }

    if (requestParameters['callbackRunRequest'] == null) {
      throw new runtime.RequiredError(
        'callbackRunRequest',
        'Required parameter "callbackRunRequest" was null or undefined when calling techProcessRunElementCallback().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}/run_callback`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CallbackRunRequestToJSON(requestParameters['callbackRunRequest'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CallbackRunResponseFromJSON(jsonValue)
    );
  }

  /**
   * Run Element Callback
   */
  async techProcessRunElementCallback(
    requestParameters: TechProcessRunElementCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CallbackRunResponse> {
    const response = await this.techProcessRunElementCallbackRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Run Logical Element Callback
   */
  async techProcessRunLogicalElementCallbackRaw(
    requestParameters: TechProcessRunLogicalElementCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CallbackRunResponse>> {
    if (requestParameters['logicalElementUuid'] == null) {
      throw new runtime.RequiredError(
        'logicalElementUuid',
        'Required parameter "logicalElementUuid" was null or undefined when calling techProcessRunLogicalElementCallback().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRunLogicalElementCallback().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRunLogicalElementCallback().'
      );
    }

    if (requestParameters['callbackRunRequest'] == null) {
      throw new runtime.RequiredError(
        'callbackRunRequest',
        'Required parameter "callbackRunRequest" was null or undefined when calling techProcessRunLogicalElementCallback().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{logical_element_uuid}/run_callback`
          .replace(
            `{${'logical_element_uuid'}}`,
            encodeURIComponent(String(requestParameters['logicalElementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CallbackRunRequestToJSON(requestParameters['callbackRunRequest'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CallbackRunResponseFromJSON(jsonValue)
    );
  }

  /**
   * Run Logical Element Callback
   */
  async techProcessRunLogicalElementCallback(
    requestParameters: TechProcessRunLogicalElementCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CallbackRunResponse> {
    const response = await this.techProcessRunLogicalElementCallbackRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Run Model Callback
   */
  async techProcessRunModelCallbackRaw(
    requestParameters: TechProcessRunModelCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CallbackRunResponse>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessRunModelCallback().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRunModelCallback().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRunModelCallback().'
      );
    }

    if (requestParameters['callbackRunRequest'] == null) {
      throw new runtime.RequiredError(
        'callbackRunRequest',
        'Required parameter "callbackRunRequest" was null or undefined when calling techProcessRunModelCallback().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}/run_callback`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CallbackRunRequestToJSON(requestParameters['callbackRunRequest'])
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CallbackRunResponseFromJSON(jsonValue)
    );
  }

  /**
   * Run Model Callback
   */
  async techProcessRunModelCallback(
    requestParameters: TechProcessRunModelCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CallbackRunResponse> {
    const response = await this.techProcessRunModelCallbackRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Запуск задания на расчёт
   * Run Techprocess Calculation Task
   */
  async techProcessRunTechprocessCalculationTaskRaw(
    requestParameters: TechProcessRunTechprocessCalculationTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CalculationTaskRunStatus>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessRunTechprocessCalculationTask().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessRunTechprocessCalculationTask().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_tasks/run`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CalculationTaskRunStatusFromJSON(jsonValue)
    );
  }

  /**
   * Запуск задания на расчёт
   * Run Techprocess Calculation Task
   */
  async techProcessRunTechprocessCalculationTask(
    requestParameters: TechProcessRunTechprocessCalculationTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CalculationTaskRunStatus> {
    const response = await this.techProcessRunTechprocessCalculationTaskRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Задание списка избранных компонентов
   * Set Favorite Pure Components
   */
  async techProcessSetFavoritePureComponentsRaw(
    requestParameters: TechProcessSetFavoritePureComponentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResponseStatusListUUID>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessSetFavoritePureComponents().'
      );
    }

    if (requestParameters['requestBody'] == null) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter "requestBody" was null or undefined when calling techProcessSetFavoritePureComponents().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/techprocess/favorite_pure_components`.replace(
          `{${'project_uuid'}}`,
          encodeURIComponent(String(requestParameters['projectUuid']))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['requestBody']
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResponseStatusListUUIDFromJSON(jsonValue)
    );
  }

  /**
   * Задание списка избранных компонентов
   * Set Favorite Pure Components
   */
  async techProcessSetFavoritePureComponents(
    requestParameters: TechProcessSetFavoritePureComponentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResponseStatusListUUID> {
    const response = await this.techProcessSetFavoritePureComponentsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Обновление задания на расчёт
   * Set Techprocess Calculation Task
   */
  async techProcessSetTechprocessCalculationTaskRaw(
    requestParameters: TechProcessSetTechprocessCalculationTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TechprocessCalculationTaskOut>> {
    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessSetTechprocessCalculationTask().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessSetTechprocessCalculationTask().'
      );
    }

    if (requestParameters['techprocessCalculationTaskUpdate'] == null) {
      throw new runtime.RequiredError(
        'techprocessCalculationTaskUpdate',
        'Required parameter "techprocessCalculationTaskUpdate" was null or undefined when calling techProcessSetTechprocessCalculationTask().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/techprocess/calculation_task`
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: TechprocessCalculationTaskUpdateToJSON(
          requestParameters['techprocessCalculationTaskUpdate']
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TechprocessCalculationTaskOutFromJSON(jsonValue)
    );
  }

  /**
   * Обновление задания на расчёт
   * Set Techprocess Calculation Task
   */
  async techProcessSetTechprocessCalculationTask(
    requestParameters: TechProcessSetTechprocessCalculationTaskRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TechprocessCalculationTaskOut> {
    const response = await this.techProcessSetTechprocessCalculationTaskRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Element Instance
   */
  async techProcessUpdateElementInstanceRaw(
    requestParameters: TechProcessUpdateElementInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppProjectsTechprocessSchemasUpdateOut>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessUpdateElementInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateElementInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateElementInstance().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling techProcessUpdateElementInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppProjectsTechprocessSchemasUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Element Instance
   */
  async techProcessUpdateElementInstance(
    requestParameters: TechProcessUpdateElementInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppProjectsTechprocessSchemasUpdateOut> {
    const response = await this.techProcessUpdateElementInstanceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Element Instance Position
   */
  async techProcessUpdateElementInstancePositionRaw(
    requestParameters: TechProcessUpdateElementInstancePositionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['elementUuid'] == null) {
      throw new runtime.RequiredError(
        'elementUuid',
        'Required parameter "elementUuid" was null or undefined when calling techProcessUpdateElementInstancePosition().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateElementInstancePosition().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateElementInstancePosition().'
      );
    }

    if (requestParameters['position'] == null) {
      throw new runtime.RequiredError(
        'position',
        'Required parameter "position" was null or undefined when calling techProcessUpdateElementInstancePosition().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/elements/{element_uuid}/position`
          .replace(
            `{${'element_uuid'}}`,
            encodeURIComponent(String(requestParameters['elementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PositionToJSON(requestParameters['position'])
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update Element Instance Position
   */
  async techProcessUpdateElementInstancePosition(
    requestParameters: TechProcessUpdateElementInstancePositionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.techProcessUpdateElementInstancePositionRaw(requestParameters, initOverrides);
  }

  /**
   * Update Logical Element Instance
   */
  async techProcessUpdateLogicalElementInstanceRaw(
    requestParameters: TechProcessUpdateLogicalElementInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LogicalUpdateOut>> {
    if (requestParameters['logicalElementUuid'] == null) {
      throw new runtime.RequiredError(
        'logicalElementUuid',
        'Required parameter "logicalElementUuid" was null or undefined when calling techProcessUpdateLogicalElementInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateLogicalElementInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateLogicalElementInstance().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling techProcessUpdateLogicalElementInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/logical_elements/{logical_element_uuid}`
          .replace(
            `{${'logical_element_uuid'}}`,
            encodeURIComponent(String(requestParameters['logicalElementUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogicalUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Logical Element Instance
   */
  async techProcessUpdateLogicalElementInstance(
    requestParameters: TechProcessUpdateLogicalElementInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LogicalUpdateOut> {
    const response = await this.techProcessUpdateLogicalElementInstanceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Model Instance
   */
  async techProcessUpdateModelInstanceRaw(
    requestParameters: TechProcessUpdateModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppProjectsTechprocessSchemasUpdateOut>> {
    if (requestParameters['modelInstanceUuid'] == null) {
      throw new runtime.RequiredError(
        'modelInstanceUuid',
        'Required parameter "modelInstanceUuid" was null or undefined when calling techProcessUpdateModelInstance().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling techProcessUpdateModelInstance().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling techProcessUpdateModelInstance().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling techProcessUpdateModelInstance().'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/models/{model_instance_uuid}`
          .replace(
            `{${'model_instance_uuid'}}`,
            encodeURIComponent(String(requestParameters['modelInstanceUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppProjectsTechprocessSchemasUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Model Instance
   */
  async techProcessUpdateModelInstance(
    requestParameters: TechProcessUpdateModelInstanceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppProjectsTechprocessSchemasUpdateOut> {
    const response = await this.techProcessUpdateModelInstanceRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const TechProcessGetTechprocessJournalOrderByEnum = {
  Asc: 'asc',
  Desc: 'desc',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type TechProcessGetTechprocessJournalOrderByEnum =
  (typeof TechProcessGetTechprocessJournalOrderByEnum)[keyof typeof TechProcessGetTechprocessJournalOrderByEnum];
