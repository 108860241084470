/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {Position} from './Position';
import {PositionFromJSON, PositionFromJSONTyped, PositionToJSON} from './Position';
import type {ERFElement} from './ERFElement';
import {ERFElementFromJSON, ERFElementFromJSONTyped, ERFElementToJSON} from './ERFElement';
import type {Data} from './Data';
import {DataFromJSON, DataFromJSONTyped, DataToJSON} from './Data';
import type {Id} from './Id';
import {IdFromJSON, IdFromJSONTyped, IdToJSON} from './Id';

/**
 *
 * @export
 * @interface GraphZoneElement
 */
export interface GraphZoneElement {
  /**
   *
   * @type {Id}
   * @memberof GraphZoneElement
   */
  id: Id;
  /**
   *
   * @type {ERFElement}
   * @memberof GraphZoneElement
   */
  type: ERFElement;
  /**
   *
   * @type {Position}
   * @memberof GraphZoneElement
   */
  position: Position;
  /**
   *
   * @type {string}
   * @memberof GraphZoneElement
   */
  parentId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GraphZoneElement
   */
  deletable?: boolean;
  /**
   *
   * @type {Data}
   * @memberof GraphZoneElement
   */
  data: Data;
}

/**
 * Check if a given object implements the GraphZoneElement interface.
 */
export function instanceOfGraphZoneElement(value: object): value is GraphZoneElement {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('position' in value) || value['position'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  return true;
}

export function GraphZoneElementFromJSON(json: any): GraphZoneElement {
  return GraphZoneElementFromJSONTyped(json, false);
}

export function GraphZoneElementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GraphZoneElement {
  if (json == null) {
    return json;
  }
  return {
    id: IdFromJSON(json['id']),
    type: ERFElementFromJSON(json['type']),
    position: PositionFromJSON(json['position']),
    parentId: json['parentId'] == null ? undefined : json['parentId'],
    deletable: json['deletable'] == null ? undefined : json['deletable'],
    data: DataFromJSON(json['data'])
  };
}

export function GraphZoneElementToJSON(value?: GraphZoneElement | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: IdToJSON(value['id']),
    type: ERFElementToJSON(value['type']),
    position: PositionToJSON(value['position']),
    parentId: value['parentId'],
    deletable: value['deletable'],
    data: DataToJSON(value['data'])
  };
}
