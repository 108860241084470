import {Edge, Node, Position} from '@xyflow/react';
import {EElement} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';
import {
  ERFEdge,
  ERFElement,
  RF_DEFAULT_EDGE_PROPS,
  RF_RECYCLE_EDGE_PROPS,
  TRFGraphDataConfig
} from '@progress-fe/rf-core';

const DEFAULT_X = 230;
const DEFAULT_Y = 60;
const X_STEP = 184;
const Y_STEP = 65;

export const GRAPH_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFGraphDataConfig, ERFElement>[];
  edges: Edge[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами (моковый проект)',
    nodes: [
      {
        id: '196C89E0-0000-0000-0000-000000000008', // 1
        type: ERFElement.Element,
        data: {
          isSourceHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '1',
          elementModelName: 'Пенг-Робинсон-1',
          recycleHandle: {position: Position.Left, type: 'target'}
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000001', // V-100
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.TwoPhaseSeparatorElement,
          elementName: 'V-100',
          elementModelName: 'Сеп 2-фазный-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000009', // 2
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          isSourceHandle: true,
          elementType: EElement.MaterialFlowElement,
          elementName: '1',
          elementModelName: 'Пенг-Робинсон-1',
          recycleHandle: {position: Position.Left, type: 'source'}
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 2},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000006', // Q-100
        type: ERFElement.Element,
        data: {
          isSourceHandle: true,
          elementType: EElement.EnergyFlowElement,
          elementName: 'Q-100'
        },
        position: {x: DEFAULT_X + X_STEP, y: DEFAULT_Y + Y_STEP * 2},
        deletable: false
      },
      {
        id: '196C89E0-0000-0000-0000-000000000002', // K-100
        type: ERFElement.Element,
        data: {
          isTargetHandle: true,
          elementType: EElement.CompressorElement,
          elementName: 'К-100',
          elementModelName: 'Компрессор-1'
        },
        position: {x: DEFAULT_X, y: DEFAULT_Y + Y_STEP * 3},
        deletable: false
      },
      /* RECYCLES */
      {
        id: '196C89E0-0000-0000-0000-0000000000R1', // R1
        type: ERFElement.Recycle,
        data: {
          templateCode: 'default' // or 'filled'
        },
        position: {x: 80, y: 100},
        deletable: false
      }
    ],
    edges: [
      {
        id: uuidv4(),
        type: ERFEdge.Default,
        source: '196C89E0-0000-0000-0000-000000000008', // 1
        target: '196C89E0-0000-0000-0000-000000000001', // V-100
        sourceHandle: 'source_default',
        targetHandle: 'target_default',
        deletable: false,
        ...RF_DEFAULT_EDGE_PROPS
      },
      {
        id: uuidv4(),
        type: ERFEdge.Default,
        source: '196C89E0-0000-0000-0000-000000000001', // V-100
        target: '196C89E0-0000-0000-0000-000000000009', // 2
        sourceHandle: 'source_default',
        targetHandle: 'target_default',
        deletable: false,
        ...RF_DEFAULT_EDGE_PROPS
      },
      {
        id: uuidv4(),
        type: ERFEdge.Default,
        source: '196C89E0-0000-0000-0000-000000000006', // Q-100
        target: '196C89E0-0000-0000-0000-000000000002', // K-100
        sourceHandle: 'source_default',
        targetHandle: 'target_default',
        deletable: false,
        ...RF_DEFAULT_EDGE_PROPS
      },
      {
        id: uuidv4(),
        type: ERFEdge.Default,
        source: '196C89E0-0000-0000-0000-000000000009', // 2
        target: '196C89E0-0000-0000-0000-000000000002', // K-100
        sourceHandle: 'source_default',
        targetHandle: 'target_default',
        deletable: false,
        ...RF_DEFAULT_EDGE_PROPS
      },
      /* RECYCLES */
      {
        id: uuidv4(),
        type: ERFEdge.Recycle,
        source: '196C89E0-0000-0000-0000-0000000000R1', // R1
        target: '196C89E0-0000-0000-0000-000000000008', // 1
        sourceHandle: 'source_recycle',
        targetHandle: 'target_recycle',
        deletable: false,
        ...RF_RECYCLE_EDGE_PROPS
      },
      {
        id: uuidv4(),
        type: ERFEdge.Recycle,
        source: '196C89E0-0000-0000-0000-000000000009', // 2
        target: '196C89E0-0000-0000-0000-0000000000R1', // R1
        sourceHandle: 'source_recycle',
        targetHandle: 'target_recycle',
        deletable: false,
        ...RF_RECYCLE_EDGE_PROPS
      }
    ]
  }
];
