import {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Button, Flex, Grid, GridItem, Stack, Text} from '@chakra-ui/react';
import {Input, Svg, useDebouncedCallback} from '@progress-fe/ui-kit';
import {useNavigate} from 'react-router';
import {useT} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {ProjectTypeEnum} from 'api';
import {getProjectUrl} from 'core/utils';

import {CreateProjectModal, ProjectList, ProjectMenu, UserSignOut} from './components';

export const MyProjectsSceneFC = () => {
  const navigate = useNavigate();
  const {t} = useT();

  const {authStore, myProjectsStore} = useStore();
  const {projects} = myProjectsStore;

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    myProjectsStore.loadProjects();
  }, [myProjectsStore]);

  const searchByName = useDebouncedCallback(myProjectsStore.loadProjects, 300, []);

  const handleSearch = useCallback(
    (value: string) => {
      myProjectsStore.setQuery(value);
      searchByName();
    },
    [myProjectsStore, searchByName]
  );

  const handleToggleModal = useCallback(() => {
    setModalOpen((prev) => !prev);
  }, [setModalOpen]);

  const handleCreateProject = useCallback(
    async (type: ProjectTypeEnum) => {
      const project = await myProjectsStore.createProject(type);
      setModalOpen(false);

      if (project && project.checkpoints.length > 0) {
        navigate(getProjectUrl(type, project.uuid, project.checkpoints[0].uuid));
      }
    },
    [myProjectsStore, navigate]
  );

  return (
    <>
      {isModalOpen && (
        <CreateProjectModal onCreate={handleCreateProject} onClose={handleToggleModal} />
      )}
      <Grid gridTemplateColumns={'240px 1fr'} h="100vh">
        <Grid
          bg="bg"
          p="21px"
          position="relative"
          boxShadow="shadow_3"
          templateRows="80px 70px 1fr auto"
        >
          <Svg name="Favicon" size="s48" />

          <Text fontSize="24px" fontWeight="700" color="black">
            {t('projects.my')}
          </Text>

          <ProjectMenu
            activeMenuItem={myProjectsStore.activeMenu}
            updateActiveMenuItem={myProjectsStore.setActiveType}
          />

          <UserSignOut username={authStore.user?.name || ''} onSignOut={authStore.signOut} />
        </Grid>

        <GridItem bg="darkWhite">
          <Flex p="64px 120px" h="100%" flexDirection="column">
            <Flex justify="space-between" align="center">
              <Text fontSize="24px" fontWeight="700" color="black">
                {t('projects.all')}
              </Text>
              <Flex gap="24px">
                <Box width="240px">
                  <Input
                    size="md"
                    variant="outline"
                    placeholder={t('actions.search')}
                    value={myProjectsStore.searchText}
                    leftElement={<Svg name={'Search'} />}
                    onChange={handleSearch}
                  />
                </Box>
                <Button variant="solid" size="md" onClick={handleToggleModal}>
                  {t('projects.create')}
                </Button>
              </Flex>
            </Flex>
            <Flex p="48px 0 0 0" flexGrow="1">
              <Stack spacing="12px" w="100%">
                <ProjectList
                  projects={projects}
                  isLoading={myProjectsStore.isLoading}
                  activeSort={myProjectsStore.sorts}
                  createdBy={authStore.user?.name || ''}
                  onUpdateActiveSort={myProjectsStore.setActiveSort}
                />
              </Stack>
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
};

export const MyProjectsScene = observer(MyProjectsSceneFC);

export default MyProjectsScene;
