/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {TechProcessJournalItemTypeEnum} from './TechProcessJournalItemTypeEnum';
import {
  TechProcessJournalItemTypeEnumFromJSON,
  TechProcessJournalItemTypeEnumFromJSONTyped,
  TechProcessJournalItemTypeEnumToJSON
} from './TechProcessJournalItemTypeEnum';

/**
 *
 * @export
 * @interface JournalLog
 */
export interface JournalLog {
  /**
   *
   * @type {TechProcessJournalItemTypeEnum}
   * @memberof JournalLog
   */
  type: TechProcessJournalItemTypeEnum;
  /**
   *
   * @type {string}
   * @memberof JournalLog
   */
  message: string;
  /**
   *
   * @type {Date}
   * @memberof JournalLog
   */
  createdAt?: Date;
}

/**
 * Check if a given object implements the JournalLog interface.
 */
export function instanceOfJournalLog(value: object): value is JournalLog {
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('message' in value) || value['message'] === undefined) return false;
  return true;
}

export function JournalLogFromJSON(json: any): JournalLog {
  return JournalLogFromJSONTyped(json, false);
}

export function JournalLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): JournalLog {
  if (json == null) {
    return json;
  }
  return {
    type: TechProcessJournalItemTypeEnumFromJSON(json['type']),
    message: json['message'],
    createdAt: json['created_at'] == null ? undefined : new Date(json['created_at'])
  };
}

export function JournalLogToJSON(value?: JournalLog | null): any {
  if (value == null) {
    return value;
  }
  return {
    type: TechProcessJournalItemTypeEnumToJSON(value['type']),
    message: value['message'],
    created_at: value['createdAt'] == null ? undefined : value['createdAt'].toISOString()
  };
}
