import {FC, memo} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

interface IProps {
  isSelected: boolean;
  onSelect: () => void;
}

const SettingsFC: FC<IProps> = ({isSelected, onSelect}) => {
  const {t} = useT();

  return (
    <Flex
      gap="5px"
      p="0 8px"
      align="center"
      _hover={{bg: isSelected ? 'accent' : 'accentMid', cursor: 'pointer'}}
      {...(isSelected ? {bg: 'accent', color: 'white'} : {color: 'black'})}
      onClick={() => onSelect?.()}
    >
      <Box color={isSelected ? 'white' : 'black'}>
        <Svg name="Cog" />
      </Box>
      <Text fontSize="12px" fontWeight="600" color={isSelected ? 'white' : 'black'}>
        {t('common.projectSettings')}
      </Text>
    </Flex>
  );
};

export const Settings = memo(SettingsFC);
