import {Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EntityDetails} from 'core/models';
import {EElementResultType} from 'core/enums';
import {CalculationResultItem} from 'core/models/CalculationResultItem';

const ResultDetails = types.compose(
  EntityDetails,
  types.model('ResultDetails', {
    elementUuid: types.string,
    icon: types.string, // TODO: Removal
    resultType: types.enumeration(Object.values(EElementResultType)),
    calculationData: types.maybeNull(CalculationResultItem) // TODO: Removal
  })
);

export type TResultDetailsModel = Instance<typeof ResultDetails>;
export type TResultDetailsSnapshotIn = SnapshotIn<typeof ResultDetails>;

export {ResultDetails};
