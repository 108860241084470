import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Text} from '@chakra-ui/react';
import {getDateTimeString} from '@progress-fe/core';

import {ELogItemType} from 'core/enums';
import {ILogPoint} from 'core/interfaces';

interface IProps {
  logPoints: ILogPoint[];
  activePoint: Date;
  onSelectPoint: (point: Date) => void;
}

const LogPointsFC: FC<IProps> = ({logPoints, activePoint, onSelectPoint}) => {
  const getColor = useCallback((logPoint: ILogPoint) => {
    const hasError = logPoint.items.some((i) => i.type === ELogItemType.Error);
    const hasWarning = logPoint.items.some((i) => i.type === ELogItemType.Warning);
    const hasSuccess = logPoint.items.some((i) => i.type === ELogItemType.Success);

    if (!hasError && hasWarning) return 'darkOrange';
    if (!hasError && !hasWarning && hasSuccess) return 'success';
    if (hasError || (!hasWarning && !hasSuccess)) return 'darkRed';
    return 'black';
  }, []);

  return (
    <Box>
      {logPoints.map((logPoint) => (
        <Box
          key={logPoint.calculationNumber}
          p="2px 8px"
          bg={activePoint === logPoint.runAt ? 'buttonBg' : 'transparent'}
          color={getColor(logPoint)}
          _hover={{bg: 'buttonBg', cursor: 'pointer'}}
          onClick={() => onSelectPoint(logPoint.runAt)}
        >
          <Text as="span" fontSize={'12px'} fontWeight={600} color={'inherit'}>
            №{logPoint.calculationNumber}:{' '}
          </Text>
          {getDateTimeString(logPoint.runAt)}
        </Box>
      ))}
    </Box>
  );
};

export const LogPoints = observer(LogPointsFC);
