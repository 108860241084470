export enum EProjectSortType {
  Asc = 'asc',
  Desc = 'desc'
}

export enum EProjectSortField {
  Name = 'name',
  Type = 'type',
  Deleted = 'deleted',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

// Enum equals to ComputeStatusCodeEnum
export enum EComputeStatusCode {
  Success = '0',
  PartialSuccess = '1',
  Failure = '2',
  Unknown = '11184809'
}
