/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface Position
 */
export interface Position {
  /**
   *
   * @type {number}
   * @memberof Position
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof Position
   */
  y: number;
}

/**
 * Check if a given object implements the Position interface.
 */
export function instanceOfPosition(value: object): value is Position {
  if (!('x' in value) || value['x'] === undefined) return false;
  if (!('y' in value) || value['y'] === undefined) return false;
  return true;
}

export function PositionFromJSON(json: any): Position {
  return PositionFromJSONTyped(json, false);
}

export function PositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Position {
  if (json == null) {
    return json;
  }
  return {
    x: json['x'],
    y: json['y']
  };
}

export function PositionToJSON(value?: Position | null): any {
  if (value == null) {
    return value;
  }
  return {
    x: value['x'],
    y: value['y']
  };
}
