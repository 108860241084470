/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const HandlePosition = {
  Left: 'left',
  Right: 'right',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type HandlePosition = (typeof HandlePosition)[keyof typeof HandlePosition];

export function instanceOfHandlePosition(value: any): boolean {
  for (const key in HandlePosition) {
    if (Object.prototype.hasOwnProperty.call(HandlePosition, key)) {
      if ((HandlePosition as Record<string, HandlePosition>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function HandlePositionFromJSON(json: any): HandlePosition {
  return HandlePositionFromJSONTyped(json, false);
}

export function HandlePositionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): HandlePosition {
  return json as HandlePosition;
}

export function HandlePositionToJSON(value?: HandlePosition | null): any {
  return value as any;
}
