/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {HTTPValidationError, ProfilerMode} from '../models/index';
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ProfilerModeFromJSON,
  ProfilerModeToJSON
} from '../models/index';

export interface DefaultIsAliveRequest {
  xProfilerMode?: ProfilerMode;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * Is Alive
   */
  async defaultIsAliveRaw(
    requestParameters: DefaultIsAliveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<object>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    const response = await this.request(
      {
        path: `/healthcheck`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Is Alive
   */
  async defaultIsAlive(
    requestParameters: DefaultIsAliveRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<object> {
    const response = await this.defaultIsAliveRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
