import {ISelectOption} from '@progress-fe/ui-kit';
import {i18n} from '@progress-fe/core';

// Equal to TechprocessCalculationTaskTypeEnum
export enum ETaskType {
  Base = 'base',
  Grid = 'grid',
  Optimization = 'optimization',
  Unknown = '11184809'
}

export const TaskTypeOptions: ISelectOption<ETaskType>[] = [
  {
    value: ETaskType.Base,
    label: i18n.t(`enum.taskType.${ETaskType.Base}`)
  },
  {
    value: ETaskType.Grid,
    label: i18n.t(`enum.taskType.${ETaskType.Grid}`),
    readonly: true
  },
  {
    value: ETaskType.Optimization,
    label: i18n.t(`enum.taskType.${ETaskType.Optimization}`),
    readonly: true
  }
];
