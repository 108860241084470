/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface BlendOut
 */
export interface BlendOut {
  /**
   *
   * @type {string}
   * @memberof BlendOut
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof BlendOut
   */
  name: string;
}

/**
 * Check if a given object implements the BlendOut interface.
 */
export function instanceOfBlendOut(value: object): value is BlendOut {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  return true;
}

export function BlendOutFromJSON(json: any): BlendOut {
  return BlendOutFromJSONTyped(json, false);
}

export function BlendOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlendOut {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name']
  };
}

export function BlendOutToJSON(value?: BlendOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name']
  };
}
