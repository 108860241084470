/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TechprocessCalculationTaskTypeEnum = {
  Base: 'base',
  Grid: 'grid',
  Optimization: 'optimization',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type TechprocessCalculationTaskTypeEnum =
  (typeof TechprocessCalculationTaskTypeEnum)[keyof typeof TechprocessCalculationTaskTypeEnum];

export function instanceOfTechprocessCalculationTaskTypeEnum(value: any): boolean {
  for (const key in TechprocessCalculationTaskTypeEnum) {
    if (Object.prototype.hasOwnProperty.call(TechprocessCalculationTaskTypeEnum, key)) {
      if (
        (TechprocessCalculationTaskTypeEnum as Record<string, TechprocessCalculationTaskTypeEnum>)[
          key
        ] === value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function TechprocessCalculationTaskTypeEnumFromJSON(
  json: any
): TechprocessCalculationTaskTypeEnum {
  return TechprocessCalculationTaskTypeEnumFromJSONTyped(json, false);
}

export function TechprocessCalculationTaskTypeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechprocessCalculationTaskTypeEnum {
  return json as TechprocessCalculationTaskTypeEnum;
}

export function TechprocessCalculationTaskTypeEnumToJSON(
  value?: TechprocessCalculationTaskTypeEnum | null
): any {
  return value as any;
}
