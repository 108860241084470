/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {RJSFSchemas} from './RJSFSchemas';
import {RJSFSchemasFromJSON, RJSFSchemasFromJSONTyped, RJSFSchemasToJSON} from './RJSFSchemas';

/**
 *
 * @export
 * @interface AppProjectsOilSchemasSchemasUpdateOut
 */
export interface AppProjectsOilSchemasSchemasUpdateOut {
  /**
   *
   * @type {string}
   * @memberof AppProjectsOilSchemasSchemasUpdateOut
   */
  name: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AppProjectsOilSchemasSchemasUpdateOut
   */
  dataChanged: boolean;
  /**
   *
   * @type {{ [key: string]: RJSFSchemas; }}
   * @memberof AppProjectsOilSchemasSchemasUpdateOut
   */
  schemas: {[key: string]: RJSFSchemas} | null;
}

/**
 * Check if a given object implements the AppProjectsOilSchemasSchemasUpdateOut interface.
 */
export function instanceOfAppProjectsOilSchemasSchemasUpdateOut(
  value: object
): value is AppProjectsOilSchemasSchemasUpdateOut {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('dataChanged' in value) || value['dataChanged'] === undefined) return false;
  if (!('schemas' in value) || value['schemas'] === undefined) return false;
  return true;
}

export function AppProjectsOilSchemasSchemasUpdateOutFromJSON(
  json: any
): AppProjectsOilSchemasSchemasUpdateOut {
  return AppProjectsOilSchemasSchemasUpdateOutFromJSONTyped(json, false);
}

export function AppProjectsOilSchemasSchemasUpdateOutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppProjectsOilSchemasSchemasUpdateOut {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'],
    dataChanged: json['data_changed'],
    schemas: json['schemas'] == null ? null : mapValues(json['schemas'], RJSFSchemasFromJSON)
  };
}

export function AppProjectsOilSchemasSchemasUpdateOutToJSON(
  value?: AppProjectsOilSchemasSchemasUpdateOut | null
): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    data_changed: value['dataChanged'],
    schemas: value['schemas'] == null ? null : mapValues(value['schemas'], RJSFSchemasToJSON)
  };
}
