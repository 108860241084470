/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {LogicalElementOut} from './LogicalElementOut';
import {
  LogicalElementOutFromJSON,
  LogicalElementOutFromJSONTyped,
  LogicalElementOutToJSON
} from './LogicalElementOut';

/**
 *
 * @export
 * @interface CreateLogicalElementActionResult
 */
export interface CreateLogicalElementActionResult {
  /**
   *
   * @type {boolean}
   * @memberof CreateLogicalElementActionResult
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateLogicalElementActionResult
   */
  errorMsg?: string | null;
  /**
   *
   * @type {LogicalElementOut}
   * @memberof CreateLogicalElementActionResult
   */
  data: LogicalElementOut;
}

/**
 * Check if a given object implements the CreateLogicalElementActionResult interface.
 */
export function instanceOfCreateLogicalElementActionResult(
  value: object
): value is CreateLogicalElementActionResult {
  if (!('success' in value) || value['success'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  return true;
}

export function CreateLogicalElementActionResultFromJSON(
  json: any
): CreateLogicalElementActionResult {
  return CreateLogicalElementActionResultFromJSONTyped(json, false);
}

export function CreateLogicalElementActionResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateLogicalElementActionResult {
  if (json == null) {
    return json;
  }
  return {
    success: json['success'],
    errorMsg: json['error_msg'] == null ? undefined : json['error_msg'],
    data: LogicalElementOutFromJSON(json['data'])
  };
}

export function CreateLogicalElementActionResultToJSON(
  value?: CreateLogicalElementActionResult | null
): any {
  if (value == null) {
    return value;
  }
  return {
    success: value['success'],
    error_msg: value['errorMsg'],
    data: LogicalElementOutToJSON(value['data'])
  };
}
