import {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Tab, TabList, TabPanel, TabPanels, Tabs} from '@chakra-ui/react';
import {JsFormName, ITab} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

import {ProjectComponents} from './components';

interface IProps {}

const SettingsFormsFC: FC<IProps> = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const {t} = useT();

  const tabs: ITab[] = [
    {index: 0, name: t('common.components')},
    {index: 1, name: t('common.blends'), isDisabled: true},
    {index: 2, name: t('common.settings'), isDisabled: true},
    {index: 3, name: t('common.versions'), isDisabled: true}
  ];

  return (
    <Box>
      <JsFormName name={t('common.project')} />

      <Tabs variant="soft-rounded" index={tabIndex} onChange={setTabIndex}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.index} isDisabled={tab.isDisabled}>
              {tab.name}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {/* Components */}
          <TabPanel>
            <ProjectComponents />
          </TabPanel>

          {/* Blends */}
          <TabPanel>
            <Box />
          </TabPanel>

          {/* Settings */}
          <TabPanel>
            <Box />
          </TabPanel>

          {/* Versions */}
          <TabPanel>
            <Box />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export const SettingsForms = observer(SettingsFormsFC);
