import {v4 as uuidv4} from 'uuid';

import {TJsonSchemaSnapshotIn} from 'core/models';

export const SCHEMAS: TJsonSchemaSnapshotIn[] = [
  {
    id: uuidv4(),
    name: 'Базовые',
    schema: {
      type: 'object',
      properties: {
        basic_group_divider: {
          type: 'null',
          title: 'Базовые свойства'
        },
        name: {
          type: 'string',
          title: 'Имя'
        },
        element_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'CatCrackingRegeneratorElement',
              title: 'Установка каталитического крекинга'
            }
          ],
          title: 'Тип элемента',
          readOnly: true
        },
        model_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: '605e279b-0eae-45c0-adbd-3a45b3bf9029',
              title: 'Установка каталитического крекинга'
            },
            {
              const: null
            }
          ],
          title: 'Тип модели',
          readOnly: true
        },
        model_id: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: '7d6f307e-bfd7-4d55-82df-2ca8715d9c96',
              title: 'Установка кат. крекинга-1'
            },
            {
              const: null
            }
          ],
          title: 'Модель'
        },
        configuration_group_divider: {
          type: 'null',
          title: 'Конфигурация'
        },
        reactor_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'one_elevator_reactor',
              title: 'один лифт-реактор'
            },
            {
              const: 'two_elevator_reactors',
              title: 'два лифт-реактора',
              readOnly: true
            },
            {
              const: 'fluidized_bed_reactor',
              title: 'реактор с кипящим слоем',
              readOnly: true
            }
          ],
          title: 'Тип реактора'
        },
        regenerator_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'single_stage',
              title: 'одноступенчатый'
            },
            {
              const: 'double_stage',
              title: 'двухступенчатый'
            }
          ],
          title: 'Тип регенератора'
        },
        mixed_raw: {
          type: 'boolean',
          title: 'Смесевое сырьё'
        },
        add_fractionating_column: {
          type: 'boolean',
          title: 'Фракционирующая колонна'
        },
        recycle_flow_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'petrol',
              title: 'бензин'
            },
            {
              const: 'heavy_gas_oil',
              title: 'тяжёлый газойль'
            },
            {
              const: 'light_gas_oil',
              title: 'лёгкий газойль'
            }
          ],
          title: 'Тип рециркулирующего потока'
        }
      }
    },
    uiSchema: {
      basic_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      name: {
        'ui:field': '/schemas/jsf-input'
      },
      element_type: {
        'ui:field': '/schemas/jsf-select'
      },
      model_type: {
        'ui:field': '/schemas/jsf-select'
      },
      model_id: {
        'ui:field': '/schemas/jsf-select-arrow'
      },
      configuration_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      reactor_type: {
        'ui:field': '/schemas/jsf-select'
      },
      regenerator_type: {
        'ui:field': '/schemas/jsf-select'
      },
      mixed_raw: {
        'ui:field': '/schemas/jsf-checkbox'
      },
      add_fractionating_column: {
        'ui:field': '/schemas/jsf-checkbox'
      },
      recycle_flow_type: {
        'ui:field': '/schemas/jsf-select'
      }
    },
    formData: {
      basic_group_divider: null,
      name: 'FCC-100',
      element_type: 'CatCrackingRegeneratorElement',
      model_type: '605e279b-0eae-45c0-adbd-3a45b3bf9029',
      model_id: '7d6f307e-bfd7-4d55-82df-2ca8715d9c96',
      configuration_group_divider: null,
      reactor_type: 'one_elevator_reactor',
      regenerator_type: 'single_stage',
      mixed_raw: false,
      add_fractionating_column: false,
      recycle_flow_type: 'heavy_gas_oil'
    }
  }
];
