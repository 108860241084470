/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface PureComponent
 */
export interface PureComponent {
  /**
   *
   * @type {string}
   * @memberof PureComponent
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof PureComponent
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PureComponent
   */
  formula?: string | null;
  /**
   *
   * @type {string}
   * @memberof PureComponent
   */
  uNIFACStructure?: string | null;
  /**
   *
   * @type {string}
   * @memberof PureComponent
   */
  _class?: string | null;
  /**
   *
   * @type {string}
   * @memberof PureComponent
   */
  family?: string | null;
}

/**
 * Check if a given object implements the PureComponent interface.
 */
export function instanceOfPureComponent(value: object): value is PureComponent {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  return true;
}

export function PureComponentFromJSON(json: any): PureComponent {
  return PureComponentFromJSONTyped(json, false);
}

export function PureComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PureComponent {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    formula: json['Formula'] == null ? undefined : json['Formula'],
    uNIFACStructure: json['UNIFACStructure'] == null ? undefined : json['UNIFACStructure'],
    _class: json['class_'] == null ? undefined : json['class_'],
    family: json['Family'] == null ? undefined : json['Family']
  };
}

export function PureComponentToJSON(value?: PureComponent | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    Formula: value['formula'],
    UNIFACStructure: value['uNIFACStructure'],
    class_: value['_class'],
    Family: value['family']
  };
}
