/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {CalculationStatusEnum} from './CalculationStatusEnum';
import {
  CalculationStatusEnumFromJSON,
  CalculationStatusEnumFromJSONTyped,
  CalculationStatusEnumToJSON
} from './CalculationStatusEnum';

/**
 *
 * @export
 * @interface CheckpointOut
 */
export interface CheckpointOut {
  /**
   *
   * @type {string}
   * @memberof CheckpointOut
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof CheckpointOut
   */
  name: string;
  /**
   *
   * @type {Date}
   * @memberof CheckpointOut
   */
  lastSavingDt: Date;
  /**
   *
   * @type {CalculationStatusEnum}
   * @memberof CheckpointOut
   */
  calcStatus?: CalculationStatusEnum;
}

/**
 * Check if a given object implements the CheckpointOut interface.
 */
export function instanceOfCheckpointOut(value: object): value is CheckpointOut {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('lastSavingDt' in value) || value['lastSavingDt'] === undefined) return false;
  return true;
}

export function CheckpointOutFromJSON(json: any): CheckpointOut {
  return CheckpointOutFromJSONTyped(json, false);
}

export function CheckpointOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckpointOut {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    lastSavingDt: new Date(json['last_saving_dt']),
    calcStatus:
      json['calc_status'] == null ? undefined : CalculationStatusEnumFromJSON(json['calc_status'])
  };
}

export function CheckpointOutToJSON(value?: CheckpointOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    last_saving_dt: value['lastSavingDt'].toISOString(),
    calc_status: CalculationStatusEnumToJSON(value['calcStatus'])
  };
}
