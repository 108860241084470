import {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Grid, Text} from '@chakra-ui/react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {ComponentsLists, ITabOption} from '@progress-fe/ui-kit';
import {EComponentBaseType, useT} from '@progress-fe/core';

import {useStore} from 'core/hooks';

import {SelectedComponents} from './components';

interface IProps {}

const ProjectComponentsFC: FC<IProps> = () => {
  const {catalogsStore, favoriteComponents, techProcessStore} = useStore();
  const {pureComponents} = catalogsStore;
  const {addComponentsToFavorite, removeComponentsFromFavorite} = techProcessStore;

  const {t} = useT();

  const selectedIds = useMemo(
    () => favoriteComponents.map((item) => item.uuid),
    [favoriteComponents]
  );

  const tabs: ITabOption[] = useMemo(() => {
    return [
      {
        type: EComponentBaseType.PURE,
        label: t(`enum.componentBaseType.${EComponentBaseType.PURE}`),
        components: pureComponents
      },
      {
        type: EComponentBaseType.HYPOTHETICAL,
        label: t(`enum.componentBaseType.${EComponentBaseType.HYPOTHETICAL}`),
        components: []
      },
      {
        type: EComponentBaseType.PSEUDO,
        label: t(`enum.componentBaseType.${EComponentBaseType.PSEUDO}`),
        components: []
      }
    ];
  }, [pureComponents, t]);

  return (
    <Grid gridTemplateRows="2fr 40px 1fr" h="calc(100vh - 130px)">
      {/* CHEMICAL COMPONENTS DICTIONARY */}
      <Box height="100%">
        <AutoSizer disableWidth={false}>
          {({height, width}) => (
            <Box width={width} pt="16px">
              <ComponentsLists
                title={t('common.components')}
                selectedComponentsIds={selectedIds}
                tabs={tabs}
                onAddComponent={(component) => {
                  addComponentsToFavorite([component]);
                }}
                onDeleteComponent={(component) => {
                  removeComponentsFromFavorite([component]);
                }}
                tableSx={{height: height - 106}}
              />
            </Box>
          )}
        </AutoSizer>
      </Box>

      <Text p="16px 0 8px 0" fontSize="12px" fontWeight={500}>
        Выбранные
      </Text>

      {/* SELECTED CHEMICAL COMPONENTS */}
      <Box height="100%">
        <AutoSizer disableWidth={false}>
          {({height, width}) => (
            <Box width={width}>
              <SelectedComponents components={favoriteComponents} tableSx={{height}} />
            </Box>
          )}
        </AutoSizer>
      </Box>
    </Grid>
  );
};

export const ProjectComponents = observer(ProjectComponentsFC);
