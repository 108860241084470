/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TechProcessJournalItemTypeEnum = {
  Message: 'message',
  Warning: 'warning',
  Success: 'success',
  Error: 'error',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type TechProcessJournalItemTypeEnum =
  (typeof TechProcessJournalItemTypeEnum)[keyof typeof TechProcessJournalItemTypeEnum];

export function instanceOfTechProcessJournalItemTypeEnum(value: any): boolean {
  for (const key in TechProcessJournalItemTypeEnum) {
    if (Object.prototype.hasOwnProperty.call(TechProcessJournalItemTypeEnum, key)) {
      if (
        (TechProcessJournalItemTypeEnum as Record<string, TechProcessJournalItemTypeEnum>)[key] ===
        value
      ) {
        return true;
      }
    }
  }
  return false;
}

export function TechProcessJournalItemTypeEnumFromJSON(json: any): TechProcessJournalItemTypeEnum {
  return TechProcessJournalItemTypeEnumFromJSONTyped(json, false);
}

export function TechProcessJournalItemTypeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechProcessJournalItemTypeEnum {
  return json as TechProcessJournalItemTypeEnum;
}

export function TechProcessJournalItemTypeEnumToJSON(
  value?: TechProcessJournalItemTypeEnum | null
): any {
  return value as any;
}
