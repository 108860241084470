import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Text} from '@chakra-ui/react';
import {ItemListHeader, Loader} from '@progress-fe/ui-kit';

import {StructureItem} from 'ui-kit';

interface IProps {
  isLoading: boolean;
  title: string;
  results: string[];
  selectedId: string | null;
  noResultsMsg: string;
}

// TODO: Implementation
const ResultsListFC: FC<IProps> = ({title, isLoading, results, selectedId, noResultsMsg}) => {
  return (
    <Box overflowY="hidden">
      <ItemListHeader title={title} />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center pb="40px" height="100%">
            <Loader />
          </Center>
        ) : (
          <>
            {results.map((m) => (
              <StructureItem
                key={m}
                item={{uuid: m, name: m, icon: 'Sample', isActive: selectedId === m}}
              />
            ))}

            {results.length === 0 && (
              <Center pb="40px" height="100%">
                <Text>{noResultsMsg}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const ResultsList = observer(ResultsListFC);
