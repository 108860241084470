/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ProjectTypeEnum} from './ProjectTypeEnum';
import {
  ProjectTypeEnumFromJSON,
  ProjectTypeEnumFromJSONTyped,
  ProjectTypeEnumToJSON
} from './ProjectTypeEnum';

/**
 *
 * @export
 * @interface ProjectInCreate
 */
export interface ProjectInCreate {
  /**
   *
   * @type {string}
   * @memberof ProjectInCreate
   */
  name?: string | null;
  /**
   *
   * @type {ProjectTypeEnum}
   * @memberof ProjectInCreate
   */
  type: ProjectTypeEnum;
}

/**
 * Check if a given object implements the ProjectInCreate interface.
 */
export function instanceOfProjectInCreate(value: object): value is ProjectInCreate {
  if (!('type' in value) || value['type'] === undefined) return false;
  return true;
}

export function ProjectInCreateFromJSON(json: any): ProjectInCreate {
  return ProjectInCreateFromJSONTyped(json, false);
}

export function ProjectInCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProjectInCreate {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'] == null ? undefined : json['name'],
    type: ProjectTypeEnumFromJSON(json['type'])
  };
}

export function ProjectInCreateToJSON(value?: ProjectInCreate | null): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    type: ProjectTypeEnumToJSON(value['type'])
  };
}
