import {EElement} from '@progress-fe/core';

import {TElementDetailsSnapshotIn} from 'core/models';

import * as flow_1 from './schemas/flow_1';
import * as flow_2 from './schemas/flow_2';
import * as flow_3 from './schemas/flow_3';
import * as flow_ppf from './schemas/flow_ppf';
import * as flow_bbf from './schemas/flow_bbf';
import * as flow_tech from './schemas/flow_tech';
import * as flow_surio from './schemas/flow_surio';
import * as flow_benzin from './schemas/flow_benzin';
import * as flow_dim_gas from './schemas/flow_dim_gas';
import * as flow_suh_gas from './schemas/flow_suh_gas';
import * as flow_l_gazoil from './schemas/flow_l_gazoil';
import * as flow_t_gazoil from './schemas/flow_t_gazoil';
import * as flow_reactor_prod from './schemas/flow_reactor_prod';
import * as catCracking from './schemas/catCracking';
import * as fractionator from './schemas/fractionator';

export const CAT_CRACKING_2A_ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000003',
    projectName: 'Кат. крекинг 2A (моковый проект)',
    items: [
      {
        uuid: '196C89E0-0000-0000-0000-000000000000',
        type: EElement.CatCrackingElement,
        name: 'FCC-100 2A',
        lastUpdated: new Date(),
        jsonSchemas: catCracking.SCHEMAS,
        subElements: [
          {
            uuid: '196C89E0-0000-0000-0000-00000000SUB9',
            type: EElement.CatCrackingReactorRegeneratorBlockElement,
            name: 'Реакт.-реген. блок',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '196C89E0-0000-0000-0000-00000000SUB3',
            type: EElement.FractionatorElement,
            name: 'Фракционатор',
            lastUpdated: new Date(),
            jsonSchemas: fractionator.SCHEMAS
          },
          {
            uuid: '0000-SUB-СЫРЬЁ',
            type: EElement.MaterialFlowElement,
            name: 'Сырьё',
            lastUpdated: new Date(),
            jsonSchemas: flow_surio.SCHEMAS
          },
          {
            uuid: '0000-SUB-ДЫМ-ГАЗЫ',
            type: EElement.MaterialFlowElement,
            name: 'Дымовые газы',
            lastUpdated: new Date(),
            jsonSchemas: flow_dim_gas.SCHEMAS
          },
          {
            uuid: '0000-SUB-ПРОД-РЕАКТОРА',
            type: EElement.MaterialFlowElement,
            name: 'Продукты из реактора',
            lastUpdated: new Date(),
            jsonSchemas: flow_reactor_prod.SCHEMAS
          },
          {
            uuid: '0000-SUB-СУХОЙ-ГАЗ',
            type: EElement.MaterialFlowElement,
            name: 'Сухой газ',
            lastUpdated: new Date(),
            jsonSchemas: flow_suh_gas.SCHEMAS
          },
          {
            uuid: '0000-SUB-ППФ',
            type: EElement.MaterialFlowElement,
            name: 'ППФ',
            lastUpdated: new Date(),
            jsonSchemas: flow_ppf.SCHEMAS
          },
          {
            uuid: '0000-SUB-ББФ',
            type: EElement.MaterialFlowElement,
            name: 'ББФ',
            lastUpdated: new Date(),
            jsonSchemas: flow_bbf.SCHEMAS
          },
          {
            uuid: '0000-SUB-ТЕХ-КОНФЕНСАТ',
            type: EElement.MaterialFlowElement,
            name: 'Технический конденсат',
            lastUpdated: new Date(),
            jsonSchemas: flow_tech.SCHEMAS
          },
          {
            uuid: '0000-SUB-БЕНЗИН',
            type: EElement.MaterialFlowElement,
            name: 'Бензин',
            lastUpdated: new Date(),
            jsonSchemas: flow_benzin.SCHEMAS
          },
          {
            uuid: '0000-SUB-ЛЁГКИЙ-ГАЗОЙЛЬ',
            type: EElement.MaterialFlowElement,
            name: 'Лёгкий газойль',
            lastUpdated: new Date(),
            jsonSchemas: flow_l_gazoil.SCHEMAS
          },
          {
            uuid: '0000-SUB-ТЯЖЁЛЫЙ-ГАЗОЙЛЬ',
            type: EElement.MaterialFlowElement,
            name: 'Тяжёлый газойль',
            lastUpdated: new Date(),
            jsonSchemas: flow_t_gazoil.SCHEMAS
          }
        ]
      },
      {
        uuid: '0001-M',
        type: EElement.MaterialFlowElement,
        name: '1',
        lastUpdated: new Date(),
        jsonSchemas: flow_1.SCHEMAS
      },
      {
        uuid: '0002-M',
        type: EElement.MaterialFlowElement,
        name: '2',
        lastUpdated: new Date(),
        jsonSchemas: flow_2.SCHEMAS
      },
      {
        uuid: '0003-M',
        type: EElement.MaterialFlowElement,
        name: '3',
        lastUpdated: new Date(),
        jsonSchemas: flow_3.SCHEMAS
      }
    ]
  }
];
