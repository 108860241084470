import {v4 as uuidv4} from 'uuid';

import {TJsonSchemaSnapshotIn} from 'core/models';

export const SCHEMAS: TJsonSchemaSnapshotIn[] = [
  {
    id: uuidv4(),
    name: 'Базовые',
    schema: {
      type: 'object',
      properties: {
        basic_group_divider: {
          type: 'null',
          title: 'Базовые свойства'
        },
        name: {
          type: 'string',
          title: 'Имя'
        },
        element_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'CatCrackingElement',
              title: 'Установка каталитического крекинга'
            }
          ],
          title: 'Тип элемента',
          readOnly: true
        },
        model_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: '605e279b-0eae-45c0-adbd-3a45b3bf9029',
              title: 'Установка каталитического крекинга'
            }
          ],
          title: 'Тип модели',
          readOnly: true
        }
      }
    },
    uiSchema: {
      basic_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      name: {
        'ui:field': '/schemas/jsf-input'
      },
      element_type: {
        'ui:field': '/schemas/jsf-select'
      },
      model_type: {
        'ui:field': '/schemas/jsf-select'
      }
    },
    formData: {
      basic_group_divider: null,
      name: 'Установка кат. крекинга-1',
      element_type: 'CatCrackingElement',
      model_type: '605e279b-0eae-45c0-adbd-3a45b3bf9029'
    }
  },
  {
    id: uuidv4(),
    name: 'Параметры',
    schema: {
      type: 'object',
      properties: {
        metal_desactivation_group_divider: {
          type: 'null',
          title: 'Дезактивация металлов'
        },
        vanadium_correction_factor: {
          type: 'string',
          title: 'Поправочный коэффициент по ванадию'
        },
        nickel_correction_factor: {
          type: 'string',
          title: 'Поправочный коэффициент по никелю'
        },
        sodium_correction_factor: {
          type: 'string',
          title: 'Поправочный коэффициент по натрию'
        },
        ferrum_correction_factor: {
          type: 'string',
          title: 'Поправочный коэффициент по железу'
        },
        cuprum_correction_factor: {
          type: 'string',
          title: 'Поправочный коэффициент по меди'
        },
        сatalyst_activity_group_divider: {
          type: 'null',
          title: 'Активность катализатора'
        },
        catalyst_deactivation_coefficient: {
          type: 'string',
          title: 'Коэффициент дезактивации катализатора'
        },
        desorption_zone_settings_group_divider: {
          type: 'null',
          title: 'Настройка зоны десорбции'
        },
        flow_per_mass_of_desorption_zone_catalyst: {
          type: 'string',
          title: 'Поток на массу катализатора зоны десорбции'
        },
        heat_balance_group_divider: {
          type: 'null',
          title: 'Тепловой баланс'
        },
        H_C_ratio_in_coke: {
          type: 'string',
          title: 'Соотношение Н/С в коксе'
        },
        coke_combustion_activity: {
          type: 'string',
          title: 'Активность горения кокса'
        },
        heterogeneous_combustion_activity_of_CO: {
          type: 'string',
          title: 'Гетерогенная активность горения СО'
        },
        homogeneous_combustion_activity_of_CO: {
          type: 'string',
          title: 'Гомогенная активность горения СО'
        },
        heat_of_cracking_parameter: {
          type: 'string',
          title: 'Параметр теплоты крекинга'
        },
        coefficient_of_activity_of_coke_formation_reactions: {
          type: 'string',
          title: 'Коэффициент активности реакций коксообразования'
        },
        sulfur_balance_SOx_group_divider: {
          type: 'null',
          title: 'Баланс серы SOx'
        },
        coke_sulfur_correlation: {
          type: 'string',
          title: 'Корреляция коксовой серы'
        },
        microactivity_group_divider: {
          type: 'null',
          title: 'Микроактивность'
        },
        cracking_activity_multiplier: {
          type: 'string',
          title: 'Множитель активности крекинга'
        },
        kinetic_parameters_group_divider: {
          type: 'null',
          title: 'Кинетические параметры'
        },
        c1_c2: {
          type: 'string',
          title: 'С1-С2'
        },
        c3: {
          type: 'string',
          title: 'С3'
        },
        c4: {
          type: 'string',
          title: 'С4'
        },
        paraffins_c5_c8: {
          type: 'string',
          title: 'Парафины С5-С8'
        },
        paraffins_c9_c12: {
          type: 'string',
          title: 'Парафины С9-С12'
        },
        paraffins_c13_c20: {
          type: 'string',
          title: 'Парафины С13-С20'
        },
        paraffins_c21_c35: {
          type: 'string',
          title: 'Парафины С21-С35'
        },
        paraffins_c36_plus: {
          type: 'string',
          title: 'Парафины С36+'
        },
        isoparaffins_c5_c8: {
          type: 'string',
          title: 'Изопарафины С5-С8'
        },
        isoparaffins_c9_c12: {
          type: 'string',
          title: 'Изопарафины С9-С12'
        },
        olefins_c5_c12: {
          type: 'string',
          title: 'Олефины С5-С12'
        },
        olefins_c13_c20: {
          type: 'string',
          title: 'Олефины С13-С20'
        },
        naphthenes_c5_c12: {
          type: 'string',
          title: 'Нафтены С5-С12'
        },
        naphthenes_c13_c20: {
          type: 'string',
          title: 'Нафтены С13-С20'
        },
        naphthenes_c21_c40_plus: {
          type: 'string',
          title: 'Нафтены С21-С40+'
        },
        c6h6: {
          type: 'string',
          title: 'С6Н6'
        },
        monoaromatics_c7_c12: {
          type: 'string',
          title: 'Моноароматика С7-С12'
        },
        monoaromatics_c12_c20: {
          type: 'string',
          title: 'Моноароматика С12-С20'
        },
        monoaromatics_c21_c34: {
          type: 'string',
          title: 'Моноароматика С21-С34'
        },
        polyaromatics_c12_c20: {
          type: 'string',
          title: 'Полиароматика С12-С20'
        },
        polyaromatics_c21_c38: {
          type: 'string',
          title: 'Полиароматика С21-С38'
        },
        thiophenes_c4: {
          type: 'string',
          title: 'Тиофены С4'
        },
        thiophenes_c13_plus: {
          type: 'string',
          title: 'Тиофены С13+'
        },
        benzothiophenes_c0_c2: {
          type: 'string',
          title: 'Бензотиофены С0-С2'
        },
        alkylbenzothiophenes_c3_c6: {
          type: 'string',
          title: 'Алкилбензотиофены С3-С6'
        },
        dibenzothiophenes_c0_c3: {
          type: 'string',
          title: 'Дибензотифены С0-С3'
        },
        dibenzothiophenes_c4_plus: {
          type: 'string',
          title: 'Дибензотифены/бензонафтотиофены С4+'
        },
        sulfides_c5_c12: {
          type: 'string',
          title: 'Сульфиды С5-С12'
        },
        sulfides_c13_plus: {
          type: 'string',
          title: 'Сульфиды С13+'
        },
        mercaptans: {
          type: 'string',
          title: 'Меркаптаны'
        },
        h2s: {
          type: 'string',
          title: 'H2S'
        },
        resins: {
          type: 'string',
          title: 'Смолы'
        },
        coke: {
          type: 'string',
          title: 'Кокс'
        }
      }
    },
    uiSchema: {
      metal_desactivation_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      vanadium_correction_factor: {
        'ui:field': '/schemas/jsf-float'
      },
      nickel_correction_factor: {
        'ui:field': '/schemas/jsf-float'
      },
      sodium_correction_factor: {
        'ui:field': '/schemas/jsf-float'
      },
      ferrum_correction_factor: {
        'ui:field': '/schemas/jsf-float'
      },
      cuprum_correction_factor: {
        'ui:field': '/schemas/jsf-float'
      },
      сatalyst_activity_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      catalyst_deactivation_coefficient: {
        'ui:field': '/schemas/jsf-float'
      },
      desorption_zone_settings_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      flow_per_mass_of_desorption_zone_catalyst: {
        'ui:field': '/schemas/jsf-float'
      },
      heat_balance_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      H_C_ratio_in_coke: {
        'ui:field': '/schemas/jsf-float'
      },
      coke_combustion_activity: {
        'ui:field': '/schemas/jsf-float'
      },
      heterogeneous_combustion_activity_of_CO: {
        'ui:field': '/schemas/jsf-float'
      },
      homogeneous_combustion_activity_of_CO: {
        'ui:field': '/schemas/jsf-float'
      },
      heat_of_cracking_parameter: {
        'ui:field': '/schemas/jsf-float'
      },
      coefficient_of_activity_of_coke_formation_reactions: {
        'ui:field': '/schemas/jsf-float'
      },
      sulfur_balance_SOx_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      coke_sulfur_correlation: {
        'ui:field': '/schemas/jsf-float'
      },
      microactivity_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      cracking_activity_multiplier: {
        'ui:field': '/schemas/jsf-float'
      },
      kinetic_parameters_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      c1_c2: {
        'ui:field': '/schemas/jsf-float'
      },
      c3: {
        'ui:field': '/schemas/jsf-float'
      },
      c4: {
        'ui:field': '/schemas/jsf-float'
      },
      paraffins_c5_c8: {
        'ui:field': '/schemas/jsf-float'
      },
      paraffins_c9_c12: {
        'ui:field': '/schemas/jsf-float'
      },
      paraffins_c13_c20: {
        'ui:field': '/schemas/jsf-float'
      },
      paraffins_c21_c35: {
        'ui:field': '/schemas/jsf-float'
      },
      paraffins_c36_plus: {
        'ui:field': '/schemas/jsf-float'
      },
      isoparaffins_c5_c8: {
        'ui:field': '/schemas/jsf-float'
      },
      isoparaffins_c9_c12: {
        'ui:field': '/schemas/jsf-float'
      },
      olefins_c5_c12: {
        'ui:field': '/schemas/jsf-float'
      },
      olefins_c13_c20: {
        'ui:field': '/schemas/jsf-float'
      },
      naphthenes_c5_c12: {
        'ui:field': '/schemas/jsf-float'
      },
      naphthenes_c13_c20: {
        'ui:field': '/schemas/jsf-float'
      },
      naphthenes_c21_c40_plus: {
        'ui:field': '/schemas/jsf-float'
      },
      c6h6: {
        'ui:field': '/schemas/jsf-float'
      },
      monoaromatics_c7_c12: {
        'ui:field': '/schemas/jsf-float'
      },
      monoaromatics_c12_c20: {
        'ui:field': '/schemas/jsf-float'
      },
      monoaromatics_c21_c34: {
        'ui:field': '/schemas/jsf-float'
      },
      polyaromatics_c12_c20: {
        'ui:field': '/schemas/jsf-float'
      },
      polyaromatics_c21_c38: {
        'ui:field': '/schemas/jsf-float'
      },
      thiophenes_c4: {
        'ui:field': '/schemas/jsf-float'
      },
      thiophenes_c13_plus: {
        'ui:field': '/schemas/jsf-float'
      },
      benzothiophenes_c0_c2: {
        'ui:field': '/schemas/jsf-float'
      },
      alkylbenzothiophenes_c3_c6: {
        'ui:field': '/schemas/jsf-float'
      },
      dibenzothiophenes_c0_c3: {
        'ui:field': '/schemas/jsf-float'
      },
      dibenzothiophenes_c4_plus: {
        'ui:field': '/schemas/jsf-float'
      },
      sulfides_c5_c12: {
        'ui:field': '/schemas/jsf-float'
      },
      sulfides_c13_plus: {
        'ui:field': '/schemas/jsf-float'
      },
      mercaptans: {
        'ui:field': '/schemas/jsf-float'
      },
      h2s: {
        'ui:field': '/schemas/jsf-float'
      },
      resins: {
        'ui:field': '/schemas/jsf-float'
      },
      coke: {
        'ui:field': '/schemas/jsf-float'
      }
    },
    formData: {
      metal_desactivation_group_divider: null,
      vanadium_correction_factor: null,
      nickel_correction_factor: null,
      sodium_correction_factor: null,
      ferrum_correction_factor: null,
      cuprum_correction_factor: null,
      сatalyst_activity_group_divider: null,
      catalyst_deactivation_coefficient: null,
      desorption_zone_settings_group_divider: null,
      flow_per_mass_of_desorption_zone_catalyst: null,
      heat_balance_group_divider: null,
      H_C_ratio_in_coke: null,
      coke_combustion_activity: null,
      heterogeneous_combustion_activity_of_CO: null,
      homogeneous_combustion_activity_of_CO: null,
      heat_of_cracking_parameter: null,
      coefficient_of_activity_of_coke_formation_reactions: null,
      sulfur_balance_SOx_group_divider: null,
      coke_sulfur_correlation: null,
      microactivity_group_divider: null,
      cracking_activity_multiplier: null,
      kinetic_parameters_group_divider: null,
      c1_c2: null,
      c3: null,
      c4: null,
      paraffins_c5_c8: null,
      paraffins_c9_c12: null,
      paraffins_c13_c20: null,
      paraffins_c21_c35: null,
      paraffins_c36_plus: null,
      isoparaffins_c5_c8: null,
      isoparaffins_c9_c12: null,
      olefins_c5_c12: null,
      olefins_c13_c20: null,
      naphthenes_c5_c12: null,
      naphthenes_c13_c20: null,
      naphthenes_c21_c40_plus: null,
      c6h6: null,
      monoaromatics_c7_c12: null,
      monoaromatics_c12_c20: null,
      monoaromatics_c21_c34: null,
      polyaromatics_c12_c20: null,
      polyaromatics_c21_c38: null,
      thiophenes_c4: null,
      thiophenes_c13_plus: null,
      benzothiophenes_c0_c2: null,
      alkylbenzothiophenes_c3_c6: null,
      dibenzothiophenes_c0_c3: null,
      dibenzothiophenes_c4_plus: null,
      sulfides_c5_c12: null,
      sulfides_c13_plus: null,
      mercaptans: null,
      h2s: null,
      resins: null,
      coke: null
    }
  },
  {
    id: uuidv4(),
    name: 'Реакции',
    schema: {
      type: 'object',
      properties: {
        reactions_params_group_divider: {
          type: 'null',
          title: 'Параметры реакций'
        },
        temperature_of_reactions: {
          type: 'string',
          title: 'Температура протекания реакций',
          $comment: '°С'
        }
      }
    },
    uiSchema: {
      reactions_params_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      temperature_of_reactions: {
        'ui:field': '/schemas/jsf-float'
      }
    },
    formData: {
      reactions_params_group_divider: null,
      temperature_of_reactions: null
    }
  },
  {
    id: uuidv4(),
    name: 'Параметры катализатора',
    schema: {
      type: 'object',
      properties: {
        catalyst_params_group_divider: {
          type: 'null',
          title: 'Параметры катализатора'
        },
        catalyst_type: {
          type: ['string', 'null'],
          oneOf: [
            {
              const: 'fresh',
              title: 'свежий'
            },
            {
              const: 'equilibrium',
              title: 'равновесный'
            }
          ],
          title: 'Тип катализатора'
        },
        catalyst_mass: {
          type: 'string',
          title: 'Масса катализатора',
          minimum: 0,
          $comment: 'кgram'
        },
        catalyst_bulk_density: {
          type: 'string',
          title: 'Насыпная плотность',
          minimum: 0,
          $comment: 'кг/м³'
        },
        catalyst_true_density: {
          type: 'string',
          title: 'Истинная плотность',
          minimum: 0,
          $comment: 'кг/м³'
        },
        catalyst_average_particle_size: {
          type: 'string',
          title: 'Средний размер частиц',
          minimum: 0
        },
        catalyst_specific_surface_area: {
          type: 'string',
          title: 'Удельная поверхность',
          minimum: 0
        },
        catalyst_heat_capacity: {
          type: 'string',
          title: 'Теплоемкость катализатора',
          minimum: 0
        },
        catalyst_pore_volume: {
          type: 'string',
          title: 'Объем пор',
          minimum: 0
        },
        catalyst_activity: {
          type: 'string',
          title: 'Активность',
          minimum: 0
        },
        catalyst_ree_content: {
          type: 'string',
          title: 'Содержание РЗЭ',
          minimum: 0
        },
        catalyst_sodium_content: {
          type: 'string',
          title: 'Содержание натрия',
          minimum: 0
        },
        catalyst_aluminum_oxide_content: {
          type: 'string',
          title: 'Содержание оксида алюминия',
          minimum: 0
        },
        catalyst_silicon_oxide_content: {
          type: 'string',
          title: 'Содержание оксида кремния',
          minimum: 0
        },
        catalyst_unit_cell_size: {
          type: 'string',
          title: 'Размер элементарной ячейки',
          minimum: 0
        },
        catalyst_total_number_of_acid_sites: {
          type: 'string',
          title: 'Общее число кислотных центров',
          minimum: 0
        },
        catalyst_nickel_content: {
          type: 'string',
          title: 'Содержание никеля',
          minimum: 0
        },
        catalyst_vanadium_content: {
          type: 'string',
          title: 'Содержание ванадия',
          minimum: 0
        },
        catalyst_cuprum_content: {
          type: 'string',
          title: 'Содержание меди',
          minimum: 0
        },
        catalyst_ferrum_content: {
          type: 'string',
          title: 'Содержание железа',
          minimum: 0
        },
        catalyst_zeolite_y_content: {
          type: 'string',
          title: 'Содержание цеолита Y',
          minimum: 0
        },
        catalyst_zeolite_zsm5_content: {
          type: 'string',
          title: 'Содержание цеолита ZSM-5',
          minimum: 0
        },
        catalyst_aluminosilicate_content: {
          type: 'string',
          title: 'Содержание алюмосиликата',
          minimum: 0
        },
        catalyst_clay_content: {
          type: 'string',
          title: 'Содержание глины',
          minimum: 0
        },
        grading_group_divider: {
          type: 'null',
          title: 'Гранулометрический состав'
        },
        grading_table: {
          type: 'object',
          title: 'Гранулометрический состав'
        },
        additive_group_divider: {
          type: 'null',
          title: 'Введение добавки'
        },
        olefin_increasing: {
          type: 'string',
          title: 'Олефинповышающая'
        },
        octane_booster: {
          type: 'string',
          title: 'Октанповышающая'
        },
        co_afterburner_promoter_group_divider: {
          type: 'null',
          title: 'Промотор дожига СO'
        },
        co_afterburner_promoter_mass_fraction: {
          type: 'string',
          title: 'Массовая доля'
        }
      }
    },
    uiSchema: {
      catalyst_params_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      catalyst_type: {
        'ui:field': '/schemas/jsf-select'
      },
      catalyst_mass: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_bulk_density: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_true_density: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_average_particle_size: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_specific_surface_area: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_heat_capacity: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_pore_volume: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_activity: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_ree_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_sodium_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_aluminum_oxide_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_silicon_oxide_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_unit_cell_size: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_total_number_of_acid_sites: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_nickel_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_vanadium_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_cuprum_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_ferrum_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_zeolite_y_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_zeolite_zsm5_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_aluminosilicate_content: {
        'ui:field': '/schemas/jsf-float'
      },
      catalyst_clay_content: {
        'ui:field': '/schemas/jsf-float'
      },
      grading_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      grading_table: {
        'ui:field': '/schemas/jsf-table'
      },
      additive_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      olefin_increasing: {
        'ui:field': '/schemas/jsf-float'
      },
      octane_booster: {
        'ui:field': '/schemas/jsf-float'
      },
      co_afterburner_promoter_group_divider: {
        'ui:field': '/schemas/jsf-divider'
      },
      co_afterburner_promoter_mass_fraction: {
        'ui:field': '/schemas/jsf-float'
      }
    },
    formData: {
      catalyst_params_group_divider: null,
      catalyst_type: null,
      catalyst_mass: null,
      catalyst_bulk_density: null,
      catalyst_true_density: null,
      catalyst_average_particle_size: null,
      catalyst_specific_surface_area: null,
      catalyst_heat_capacity: null,
      catalyst_pore_volume: null,
      catalyst_activity: null,
      catalyst_ree_content: null,
      catalyst_sodium_content: null,
      catalyst_aluminum_oxide_content: null,
      catalyst_silicon_oxide_content: null,
      catalyst_unit_cell_size: null,
      catalyst_total_number_of_acid_sites: null,
      catalyst_nickel_content: null,
      catalyst_vanadium_content: null,
      catalyst_cuprum_content: null,
      catalyst_ferrum_content: null,
      catalyst_zeolite_y_content: null,
      catalyst_zeolite_zsm5_content: null,
      catalyst_aluminosilicate_content: null,
      catalyst_clay_content: null,
      grading_group_divider: null,
      grading_table: {
        fieldConfig: {
          columns: [
            {
              name: 'Зерно'
            },
            {
              name: '/% фракции к объёму катализатора'
            }
          ]
        },
        fieldValues: []
      },
      additive_group_divider: null,
      olefin_increasing: null,
      octane_booster: null,
      co_afterburner_promoter_group_divider: null,
      co_afterburner_promoter_mass_fraction: null
    }
  }
];
