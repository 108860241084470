import {cast, flow, Instance, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

import {MODELS_LIST} from 'core/mocks/projects/projects.mocks';
import {ModelDetails, RequestModel, ResetModel, TModelDetailsModel} from 'core/models';
import {
  ModelsOut,
  TechProcessApi,
  DeleteModelPossibility,
  TechProcessActionResult,
  AppProjectsTechprocessSchemasUpdateOut
} from 'api';

const ProjectModels = types
  .compose(
    ResetModel,
    types.model('ProjectModels', {
      projectUuid: '',
      checkpointUuid: '',
      models: types.optional(types.array(ModelDetails), []),
      formDataRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    clearJsonSchemas() {
      self.models.forEach((model) => {
        model.clearJsonSchemas();
      });
    },
    async loadJsonSchemasByUuid(uuid: string) {
      const model = self.models.find((e) => e.uuid === uuid);
      await model?.loadJsonSchemas(self.projectUuid, self.checkpointUuid);
    }
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      self.models = cast([]);

      // TODO: Temp. Removal
      const mockModels = MODELS_LIST.find((m) => m.projectId === self.projectUuid)?.items || [];
      if (mockModels.length) {
        self.models = cast(mockModels);
        return;
      }

      const response: ModelsOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetModels.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response?.models) {
        self.models = cast(
          response.models.map((m) => ({
            uuid: m.uuid,
            name: m.name,
            lastUpdated: new Date(),
            type: m.type as EElement
          }))
        );
      }
    }),
    addModel: flow(function* (modelUuid: string) {
      const response: TechProcessActionResult = yield self.actionRequest.send(
        TechProcessApi.techProcessCreateModel.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          newModel: {
            uuid: modelUuid
          }
        }
      );

      return self.actionRequest.isDone && !!response
        ? ModelDetails.create({
            uuid: response.data.uuid,
            name: response.data.name,
            lastUpdated: new Date(),
            type: response.data.type as EElement
          })
        : null;
    }),
    removeModel: flow(function* (uuid: string) {
      yield self.actionRequest.send(
        TechProcessApi.techProcessDeleteModelInstance.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          modelInstanceUuid: uuid
        }
      );

      return self.actionRequest.isDone;
    }),
    _updateModelName(uuid: string, name: string) {
      const model = self.models.find((n) => n.uuid === uuid);
      model?.setName(name);
    }
  }))
  .actions((self) => ({
    updateModelFormData: flow(function* (uuid: string, jsonSchemaId: string, formData: unknown) {
      const model = self.models.find((el) => el.uuid === uuid);
      const jsonSchema = model?.jsonSchemas.find((js) => js.id === jsonSchemaId);

      if (!!model && !!jsonSchema) {
        jsonSchema.updateFormData(formData);

        const response: AppProjectsTechprocessSchemasUpdateOut = yield self.formDataRequest.send(
          TechProcessApi.techProcessUpdateModelInstance.bind(TechProcessApi),
          {
            modelInstanceUuid: uuid,
            body: formData as object,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid
          }
        );

        if (self.formDataRequest.isDone) {
          if (!!response.name) {
            console.info('Model name was changed.');
            self._updateModelName(uuid, response.name);
          }
          if (!!response.schemas) {
            console.info('Model schemas was changed.');
            model.setJsonSchemas(response.schemas);
          }
        }
      }
    })
  }))
  .actions((self) => ({
    getElementsInUse: flow(function* (uuid: string) {
      const response: DeleteModelPossibility = yield self.actionRequest.send(
        TechProcessApi.techProcessCheckModelInstanceDeleteable.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          modelInstanceUuid: uuid
        }
      );

      return response.elementNames;
    }),
    hasModel(modelId: string) {
      return self.models.some((m) => m.uuid === modelId);
    },
    findModel(uuid: string): TModelDetailsModel | undefined {
      return self.models.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isFormDataUpdating(): boolean {
      return self.formDataRequest.isPending;
    }
  }));

export type TProjectModelsModel = Instance<typeof ProjectModels>;

export {ProjectModels};
