import {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Grid} from '@chakra-ui/react';
import {Edge, Node, ReactFlowInstance, useNodesState, useEdgesState} from '@xyflow/react';
import {
  RFRender,
  TRFGraphDataConfig,
  TRFEdgeDataConfig,
  RF_FIT_VIEW_MAX_ZOOM
} from '@progress-fe/rf-core';

import {RFMenu} from 'ui-kit';

interface IProps {
  initialNodes: Node<TRFGraphDataConfig>[];
  initialEdges: Edge<TRFEdgeDataConfig>[];
  height: number;
  width: number;
}

const RFGraphZoneFC: FC<IProps> = ({initialNodes, initialEdges, height, width}) => {
  const [instance, setInstance] = useState<ReactFlowInstance<
    Node<TRFGraphDataConfig>,
    Edge<TRFEdgeDataConfig>
  > | null>(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useEffect(() => {
    instance?.fitView({maxZoom: RF_FIT_VIEW_MAX_ZOOM});
  }, [width, instance]);

  useEffect(() => {
    setNodes(initialNodes);
  }, [initialNodes, initialNodes.length, setNodes]);

  useEffect(() => {
    setEdges(initialEdges);
  }, [initialEdges, initialEdges.length, setEdges]);

  return (
    <Grid width="100%" gridTemplateColumns="48px 1fr" height={height}>
      <RFMenu menuItems={[]} height={height} />

      <Box width="100%" height={height}>
        <RFRender
          nodes={nodes}
          edges={edges}
          onInit={setInstance}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
        />
      </Box>
    </Grid>
  );
};

export const RFGraphZone = observer(RFGraphZoneFC);
