import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {hasValue, useT} from '@progress-fe/core';
import {Box, Flex, IconButton} from '@chakra-ui/react';
import {ISelectOption, SelectTabs, Svg} from '@progress-fe/ui-kit';

import {ELogItemType} from 'core/enums';

interface IProps {
  filterType: ELogItemType | null;
  onSelectFilterType: (filterType: ELogItemType | null) => void;
  onClose: () => void;
}

const LogTabsFC: FC<IProps> = ({filterType, onSelectFilterType, onClose}) => {
  const {t} = useT();

  const options: ISelectOption<ELogItemType | null>[] = [
    {value: null, label: t('common.all')},
    {value: ELogItemType.Warning, label: t(`enum.logItemType.${ELogItemType.Warning}`)},
    {value: ELogItemType.Error, label: t(`enum.logItemType.${ELogItemType.Error}`)}
  ];

  return (
    <Flex
      p={'4px'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderBottom={'1px solid'}
      borderColor={'border'}
    >
      <Box width="240px">
        <SelectTabs
          options={options}
          value={filterType}
          onChange={(type) => {
            onSelectFilterType(hasValue(type) ? (type as ELogItemType) : null);
          }}
        />
      </Box>

      <IconButton
        aria-label=""
        size="smSquare"
        variant="tr"
        icon={<Svg size="s12" name="Cross" />}
        onClick={onClose}
      />
    </Flex>
  );
};

export const LogTabs = observer(LogTabsFC);
