import {flow, Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EntityDetails} from 'core/models';
import {OilApi, RJSFSchemas} from 'api';

const SampleDetails = types
  .compose(EntityDetails, types.model('SampleDetails', {}))
  .actions((self) => ({
    loadJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string) {
      if (self.jsonSchemasRequest.isPending) {
        return;
      }

      const response: {[key: string]: RJSFSchemas} = yield self.jsonSchemasRequest.send(
        OilApi.oilGetSample.bind(OilApi),
        {
          sampleUuid: self.uuid,
          projectUuid: projectUuid,
          checkpointUuid: checkpointUuid
        }
      );

      self.setJsonSchemas(response);
    })
  }));

export type TSampleDetailsModel = Instance<typeof SampleDetails>;
export type TSampleDetailsSnapshotIn = SnapshotIn<typeof SampleDetails>;

export {SampleDetails};
