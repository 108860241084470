import {FC, ReactNode} from 'react';
import {observer} from 'mobx-react-lite';
import {JsFormConfigContext} from '@progress-fe/ui-kit';

import {useStore} from 'core';

const ProjectJsFormConfigContextFC: FC<{children: ReactNode}> = ({children}) => {
  const {techProcessStore, samplesAndBlendsStore, catalogsStore, favoriteComponents} = useStore();
  const {addComponentsToFavorite, removeComponentsFromFavorite} = techProcessStore;
  const {pureComponents, componentsDictionary} = catalogsStore;
  const {sampleDictionary} = samplesAndBlendsStore;
  const {uiState, runCallback} = techProcessStore;

  const {selectSubEntity} = uiState;

  return (
    <JsFormConfigContext.Provider
      value={{
        components: {
          favorites: favoriteComponents,
          pureList: pureComponents,
          dictionary: componentsDictionary
        },
        samples: sampleDictionary,
        addToFavorites: addComponentsToFavorite,
        removeFromFavorites: removeComponentsFromFavorite,
        selectSubEntity: selectSubEntity, // TODO: Removal.Use EventEmitter
        runCallback: runCallback
      }}
    >
      {children}
    </JsFormConfigContext.Provider>
  );
};

export const ProjectJsFormConfigContext = observer(ProjectJsFormConfigContextFC);
