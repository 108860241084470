import {Edge, Node} from '@xyflow/react';
import {ERFElement, TRFEdgeDataConfig, TRFWorkZoneDataConfig} from '@progress-fe/rf-core';

export const ELEMENTS_SAMPLE_WORKZONE_LIST: {
  projectId: string;
  projectName: string;
  nodes: Node<TRFWorkZoneDataConfig, ERFElement>[];
  edges: Edge<TRFEdgeDataConfig>[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000000',
    projectName: 'Элементы (моковый проект)',
    nodes: [
      {
        id: '196C89E0-1111-0000-0000-000000000006', // HEAT-100
        type: ERFElement.HeatExchanger,
        data: {
          elementName: 'HEAT-100',
          templateCode: 'default'
        },
        position: {x: 0, y: 0},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000007', // MIX-100
        type: ERFElement.Mixer,
        data: {
          elementName: 'MIX-100',
          templateCode: 'default'
        },
        position: {x: 150, y: 0},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000008', // SPR-100
        type: ERFElement.Splitter,
        data: {
          elementName: 'SPR-100',
          templateCode: 'default'
        },
        position: {x: 300, y: 0},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000009', // DET-100
        type: ERFElement.Detander,
        data: {
          elementName: 'DET-100',
          templateCode: 'default'
        },
        position: {x: 0, y: 150},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000010', // PUMP-100
        type: ERFElement.Pump,
        data: {
          elementName: 'PUMP-100',
          templateCode: 'default'
        },
        position: {x: 150, y: 150},
        connectable: true,
        deletable: true
      },
      {
        id: '196C89E0-1111-0000-0000-000000000011', // H-100
        type: ERFElement.Heater,
        data: {
          elementName: 'H-100',
          templateCode: 'default'
        },
        position: {x: 300, y: 150},
        connectable: true,
        deletable: true
      }
    ],
    edges: []
  }
];
