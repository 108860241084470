/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {GraphZoneEdgesInner} from './GraphZoneEdgesInner';
import {
  GraphZoneEdgesInnerFromJSON,
  GraphZoneEdgesInnerFromJSONTyped,
  GraphZoneEdgesInnerToJSON
} from './GraphZoneEdgesInner';
import type {GraphZoneElement} from './GraphZoneElement';
import {
  GraphZoneElementFromJSON,
  GraphZoneElementFromJSONTyped,
  GraphZoneElementToJSON
} from './GraphZoneElement';

/**
 *
 * @export
 * @interface GraphZone
 */
export interface GraphZone {
  /**
   *
   * @type {Array<GraphZoneEdgesInner>}
   * @memberof GraphZone
   */
  edges: Array<GraphZoneEdgesInner>;
  /**
   *
   * @type {Array<GraphZoneElement>}
   * @memberof GraphZone
   */
  nodes: Array<GraphZoneElement>;
}

/**
 * Check if a given object implements the GraphZone interface.
 */
export function instanceOfGraphZone(value: object): value is GraphZone {
  if (!('edges' in value) || value['edges'] === undefined) return false;
  if (!('nodes' in value) || value['nodes'] === undefined) return false;
  return true;
}

export function GraphZoneFromJSON(json: any): GraphZone {
  return GraphZoneFromJSONTyped(json, false);
}

export function GraphZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): GraphZone {
  if (json == null) {
    return json;
  }
  return {
    edges: (json['edges'] as Array<any>).map(GraphZoneEdgesInnerFromJSON),
    nodes: (json['nodes'] as Array<any>).map(GraphZoneElementFromJSON)
  };
}

export function GraphZoneToJSON(value?: GraphZone | null): any {
  if (value == null) {
    return value;
  }
  return {
    edges: (value['edges'] as Array<any>).map(GraphZoneEdgesInnerToJSON),
    nodes: (value['nodes'] as Array<any>).map(GraphZoneElementToJSON)
  };
}
