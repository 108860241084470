import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ChakraProvider} from '@chakra-ui/react';
import {createBrowserHistory} from 'history';
import * as Sentry from '@sentry/browser';
import {theme, ToastContainer} from '@progress-fe/ui-kit';

import {App} from 'scenes/App';
import {ErrorBoundary} from 'ui-kit';
import {RootStore} from 'stores/RootStore';

import {StoreProvider} from './core';

/** Enable Sentry **/
if (!!window.ProgressConfig.TRACKING_SERVER_DSN) {
  Sentry.init({
    dsn: window.ProgressConfig.TRACKING_SERVER_DSN,
    environment: window.ProgressConfig.ENVIRONMENT
  });
}

/**
 * init rootStore and pass dependencies
 * https://mobx-state-tree.js.org/concepts/dependency-injection
 */
const history = createBrowserHistory({});
const rootStore = RootStore.create({}, {history});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StoreProvider value={rootStore}>
    <ChakraProvider theme={theme} resetCSS>
      <BrowserRouter>
        <ErrorBoundary>
          <ToastContainer />
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </ChakraProvider>
  </StoreProvider>
);
