import {SnapshotIn, types} from 'mobx-state-tree';
import {EStructureItem} from '@progress-fe/core';

import {ResetModel} from 'core/models';

const UiState = types
  .compose(
    ResetModel,
    types.model('UiState', {
      tabIndex: 0,
      entityId: '',
      entityType: types.optional(
        types.enumeration(Object.values(EStructureItem)),
        EStructureItem.Settings
      ),
      subEntityId: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    select(type: EStructureItem, uuid?: string | null, subUuid?: string | null): void {
      self.entityType = type;
      self.entityId = uuid || '';
      self.subEntityId = subUuid || null;
    },
    selectSubEntity(uuid: string | null): void {
      self.subEntityId = uuid;
    }
  }))
  .actions((self) => ({
    _setTabIndex(index: number) {
      self.tabIndex = index;
    }
  }));

export type TUiStateSnapshotIn = SnapshotIn<typeof UiState>;

export {UiState};
