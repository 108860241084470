/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const CalculationStatusEnum = {
  NoCalcResult: 'NO_CALC_RESULT',
  CalcInProgress: 'CALC_IN_PROGRESS',
  CalcResultSuccess: 'CALC_RESULT_SUCCESS',
  CalcResultError: 'CALC_RESULT_ERROR',
  CalcResultPartialSuccess: 'CALC_RESULT_PARTIAL_SUCCESS',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type CalculationStatusEnum =
  (typeof CalculationStatusEnum)[keyof typeof CalculationStatusEnum];

export function instanceOfCalculationStatusEnum(value: any): boolean {
  for (const key in CalculationStatusEnum) {
    if (Object.prototype.hasOwnProperty.call(CalculationStatusEnum, key)) {
      if ((CalculationStatusEnum as Record<string, CalculationStatusEnum>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function CalculationStatusEnumFromJSON(json: any): CalculationStatusEnum {
  return CalculationStatusEnumFromJSONTyped(json, false);
}

export function CalculationStatusEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalculationStatusEnum {
  return json as CalculationStatusEnum;
}

export function CalculationStatusEnumToJSON(value?: CalculationStatusEnum | null): any {
  return value as any;
}
