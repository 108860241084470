import {EElement} from '@progress-fe/core';

import {TElementDetailsSnapshotIn} from 'core/models';

export const CAT_CRACKING_4B_ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000007',
    projectName: 'Кат. крекинг 4B (моковый проект)',
    items: [
      {
        uuid: '196C89E0-0000-0000-0000-000000000000',
        type: EElement.CatCrackingElement,
        name: 'FCC-100 4B',
        lastUpdated: new Date(),
        jsonSchemas: [],
        subElements: [
          {
            uuid: '196C89E0-0000-0000-0000-00000000SUB9',
            type: EElement.CatCrackingReactorRegeneratorBlockElement,
            name: 'Реакт.-реген. блок',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '196C89E0-0000-0000-0000-00000000SUB0',
            type: EElement.MixerElement,
            name: 'Смеситель',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '196C89E0-0000-0000-0000-00000000SUB3',
            type: EElement.FractionatorElement,
            name: 'Фракционатор',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '196C89E0-0000-0000-0000-00000000SUB4',
            type: EElement.CoolerElement,
            name: 'E-100',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '196C89E0-0000-0000-0000-00000000SUB5',
            type: EElement.SplitterElement,
            name: 'SPR-100',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-E1', // E1
            type: EElement.EnergyFlowElement,
            name: 'E1',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-СЫРЬЁ',
            type: EElement.MaterialFlowElement,
            name: 'Сырьё',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-IN',
            type: EElement.MaterialFlowElement,
            name: 'IN',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-OUT',
            type: EElement.MaterialFlowElement,
            name: 'OUT',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ДЫМ-ГАЗЫ',
            type: EElement.MaterialFlowElement,
            name: 'Дымовые газы',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ПРОД-РЕАКТОРА',
            type: EElement.MaterialFlowElement,
            name: 'Продукты из реактора',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-СУХОЙ-ГАЗ',
            type: EElement.MaterialFlowElement,
            name: 'Сухой газ',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ППФ',
            type: EElement.MaterialFlowElement,
            name: 'ППФ',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ББФ',
            type: EElement.MaterialFlowElement,
            name: 'ББФ',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ТЕХ-КОНФЕНСАТ',
            type: EElement.MaterialFlowElement,
            name: 'Технический конденсат',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-БЕНЗИН',
            type: EElement.MaterialFlowElement,
            name: 'Бензин',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ЛЁГКИЙ-ГАЗОЙЛЬ',
            type: EElement.MaterialFlowElement,
            name: 'Лёгкий газойль',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-ТЯЖЁЛЫЙ-ГАЗОЙЛЬ',
            type: EElement.MaterialFlowElement,
            name: 'Тяжёлый газойль',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-10',
            type: EElement.MaterialFlowElement,
            name: '10',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-11',
            type: EElement.MaterialFlowElement,
            name: '11',
            lastUpdated: new Date(),
            jsonSchemas: []
          },
          {
            uuid: '0000-SUB-12',
            type: EElement.MaterialFlowElement,
            name: '12',
            lastUpdated: new Date(),
            jsonSchemas: []
          }
        ]
      },
      {
        uuid: '00S1-M',
        type: EElement.MaterialFlowElement,
        name: 'S1',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '00S2-M',
        type: EElement.MaterialFlowElement,
        name: 'S2',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0002-M',
        type: EElement.MaterialFlowElement,
        name: '2',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '0003-M',
        type: EElement.MaterialFlowElement,
        name: '3',
        lastUpdated: new Date(),
        jsonSchemas: []
      }
    ]
  }
];
