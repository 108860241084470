/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {Data2} from './Data2';
import {Data2FromJSON, Data2FromJSONTyped, Data2ToJSON} from './Data2';
import type {Position} from './Position';
import {PositionFromJSON, PositionFromJSONTyped, PositionToJSON} from './Position';
import type {ERFElement} from './ERFElement';
import {ERFElementFromJSON, ERFElementFromJSONTyped, ERFElementToJSON} from './ERFElement';
import type {Id} from './Id';
import {IdFromJSON, IdFromJSONTyped, IdToJSON} from './Id';

/**
 *
 * @export
 * @interface WorkZoneElement
 */
export interface WorkZoneElement {
  /**
   *
   * @type {Id}
   * @memberof WorkZoneElement
   */
  id: Id;
  /**
   *
   * @type {ERFElement}
   * @memberof WorkZoneElement
   */
  type: ERFElement;
  /**
   *
   * @type {Position}
   * @memberof WorkZoneElement
   */
  position: Position;
  /**
   *
   * @type {string}
   * @memberof WorkZoneElement
   */
  parentId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof WorkZoneElement
   */
  deletable?: boolean;
  /**
   *
   * @type {Data2}
   * @memberof WorkZoneElement
   */
  data: Data2;
}

/**
 * Check if a given object implements the WorkZoneElement interface.
 */
export function instanceOfWorkZoneElement(value: object): value is WorkZoneElement {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('position' in value) || value['position'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  return true;
}

export function WorkZoneElementFromJSON(json: any): WorkZoneElement {
  return WorkZoneElementFromJSONTyped(json, false);
}

export function WorkZoneElementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WorkZoneElement {
  if (json == null) {
    return json;
  }
  return {
    id: IdFromJSON(json['id']),
    type: ERFElementFromJSON(json['type']),
    position: PositionFromJSON(json['position']),
    parentId: json['parentId'] == null ? undefined : json['parentId'],
    deletable: json['deletable'] == null ? undefined : json['deletable'],
    data: Data2FromJSON(json['data'])
  };
}

export function WorkZoneElementToJSON(value?: WorkZoneElement | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: IdToJSON(value['id']),
    type: ERFElementToJSON(value['type']),
    position: PositionToJSON(value['position']),
    parentId: value['parentId'],
    deletable: value['deletable'],
    data: Data2ToJSON(value['data'])
  };
}
